import React, { useState, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';
import { UserContext } from '../context/UserContext';
import { Form, Profiles } from '../components';
import { HeaderBlankContainer } from '../containers/header_blank';
import { FooterContainer } from '../containers/footer';
import { ChangePasswordContainer } from '../containers/change_password';
import * as ROUTES from '../constants/routes';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
//  import { getAuth } from "firebase/auth";


export default function PatientDetails({user}) {
  const history = useHistory();
  //  const auth = getAuth();
  //  const authUser = auth.currentUser;
  const { firebase } = useContext(FirebaseContext);
  const [editProfile, setEditProfile] = useContext(UserContext);
  const [error, setError] = useState('');
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const firestore = getFirestore();

  const editAvatar = (event) => {
    history.push(ROUTES.SELECT_AVATAR);
  }

  const handleUpdate = async (event) => { // Consider using async/await
    event.preventDefault();

    var docId = editProfile.docId;
    var name = document.getElementById('name').value;
    var surname = document.getElementById('surname').value;
    var national_id = document.getElementById('national_id').value;
    var medaid_number = document.getElementById('medaid_number').value;
    var medaid_plan = document.getElementById('medaid_plan').value;
    var medaid_name = document.getElementById('medaid_name').value;
    var mobile_number = document.getElementById('mobile_number').value;
    var email_address = document.getElementById('email_address').value;

    try {
        const db = getFirestore();
        const userRef = doc(db, "users", docId);

        // Build an update object (cleaner)
        const updateData = {
            name, 
            surname,
            national_id, 
            medaid_number,
            medaid_plan, 
            medaid_name, 
            mobile_number, 
            email_address
        }; 
         // Perform the update
         await updateDoc(userRef, updateData);

         history.push(ROUTES.HOME); // Assuming 'history' is available.
        } catch (error) {
            console.log("PATIENT DETAIL SAVE: UNSUCCESSFUL");
            console.error(error); 
        }
  };

  function handleBackClick() {  
    //history.push(ROUTES.PATIENT);
    history.goBack();
    console.log("history pushed routes.patient");
    //history.push(ROUTES.SELECT_USER);
  }

  function handlePasswordChange(){
    setShowPasswordChange(!showPasswordChange);
  }

  return (
    <>
      { (showPasswordChange === true)?
      <>
      <HeaderBlankContainer>
        <Form>
        <Form.LongLeftContainer>
          <Form.SubButton onClick = {handlePasswordChange}>Back</Form.SubButton>
        </Form.LongLeftContainer>
        <ChangePasswordContainer>
          editProfile={editProfile} 
          setEditProfile={setEditProfile} 
        </ChangePasswordContainer>
        </Form>
        </HeaderBlankContainer>
      </>
    :
      <>
      <HeaderBlankContainer>
        <Form>
        <Form.LongLeftContainer>
            <Form.SubButton onClick={() => handleBackClick()} >Back</Form.SubButton>
          {(user.uid === editProfile.user_id)?
            <Form.SubButton onClick = {handlePasswordChange}>Change Password</Form.SubButton>
            :<></>}
          </Form.LongLeftContainer>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
        <Form.Title>Edit Patient Details</Form.Title> 
        {error && <Form.Error data-testid="error">{error}</Form.Error>}
        <Form.LongLeftContainer>
          <div>
            <Profiles.OverlayPicture src={"edit_overlay"} onClick={() => editAvatar()}></Profiles.OverlayPicture> 
            <Profiles.Picture src={editProfile.photoURL}></Profiles.Picture>
          </div>
          <Form.Text>{editProfile.name} {editProfile.surname}</Form.Text>
        </Form.LongLeftContainer>
        <Form.LongContainer>  
        </Form.LongContainer>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}
          <Form.Base onSubmit={handleUpdate} method="POST">
            <Form.Input
              placeholder="Name"
              defaultValue={editProfile.name}
              id = "name"
            />
            <Form.Input
              placeholder="Surname"
              defaultValue={editProfile.surname}
              id = "surname"
            />
            <Form.Input
              placeholder="Identity Number"
              defaultValue={editProfile.national_id}
              id = "national_id"
            />
            <Form.Input
              placeholder="Mobile Number"
              defaultValue={editProfile.mobile_number}
              id = "mobile_number"
            />
            <Form.Input
              placeholder="Medical Aid"
              defaultValue={editProfile.medaid_name}
              id = "medaid_name"
            />
            <Form.Input
              placeholder="Medical Aid Number"
              defaultValue={editProfile.medaid_number}
              id = "medaid_number"
            />
            <Form.Input
              placeholder="Medical Aid Plan"
              defaultValue={editProfile.medaid_plan}
              id = "medaid_plan"
            />
            <Form.Input
              placeholder="Email address"
              defaultValue={editProfile.email_address}
              id = "email_address"
            />
            {/* <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              id = "password"
            /> */}
            <Form.Button type="submit" > {/*onclick={handleUpdate}*/}
              Update Details
            </Form.Button>
          </Form.Base>
        </Form>
      </HeaderBlankContainer>
    </>
    }
    </>
  );
}