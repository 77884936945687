import React, { useState, useEffect } from 'react';
import { Header, EditTable } from '../components';
import { useEditTests } from '../hooks';
import { selectionFilterEditTests } from '../utils';
import { writeEditedTests } from '../utils';
import { getUUID } from '../utils';

import * as ROUTES from '../constants/routes';
import logo from '../logo.png';

export function EditTestsContainer() { 

  const [refreshData, setRefreshData] = useState(false);
  const [resultsDisplay, setResultsDisplay] = useState("category");
  //const { tests, setTests } = useEditTests('tests');
  const { tests } = useEditTests('tests', refreshData);
  const [editedData, setEditedData] = useState([]);


  useEffect(() => {
    let tempData = selectionFilterEditTests({tests}, "category"); // arrange by category
    setEditedData(tempData);
  }, [tests]);

  useEffect(() => {
    let tempData = selectionFilterEditTests({tests}, resultsDisplay); // arrange by category
    setEditedData(tempData);
  }, [resultsDisplay]);


  function formatNumber(input) {
    input = input.replace('.', ',');
    // Split the input string at the comma
    let parts = input.split(',');

    // Get the integer and decimal parts
    let integerPart = parseInt(parts[0], 10);
    let decimalPart = parts[1] ? parseFloat('.' + parts[1]) : 0;

    // Format the decimal part to always have 2 decimal places
    decimalPart = decimalPart.toFixed(2).slice(2);

    // If there's no decimal part, set it to '00'
    if (decimalPart.length === 0) {
        decimalPart = '00';
    }

    // Return the formatted number
    return integerPart + ',' + decimalPart;
}

  function roundToTwo(num) {
    let roundedString = (Math.round(num * 100) / 100).toFixed(2);
    roundedString = roundedString.replace(".",",");
    return roundedString;
  }

  function handlePriceCalc() {
    const priceInput = document.getElementById("priceCalc").value;
    let percentage = parseFloat(priceInput.replace('%', ''));
  
    if (!isNaN(percentage)) {

      let localData = [...editedData]; // Your editedData array

      localData.forEach(item => {
        if (!isNaN(parseFloat(item.reference_price))) {
          const referencePrice = parseFloat(item.reference_price);
          var newPrice = 0;
          if (item.lab === "001") { //Oncolab, we will adjust the pricing down
            newPrice = referencePrice * (100 - percentage) / 100;
          }
          else {
            newPrice = referencePrice;
          }
          item.reduced_price = newPrice;
          newPrice = Math.round(newPrice);
          item.reduced_price = newPrice.toFixed(2).replace('.', ',');
          item.reference_price = formatNumber(item.reference_price);
          item.vat_amount = roundToTwo(newPrice*0.15);
        } else { item.reference_price = "";}

        if (!isNaN(parseFloat(item.price))) {
          //let price = parseFloat(item.price);
          item.price = formatNumber(item.price);
        } else { 
          item.price = ""; }

          if (!isNaN(parseFloat(item.reduced_price))) {
            //let price = parseFloat(item.price);
            item.reduced_price = formatNumber(item.reduced_price);
          } else { 
            item.price = ""; }

      });
      
      setEditedData(localData);
    } else {
      console.error("Invalid input: Please enter a numerical value for the percentage.");
    }
  }

  
  /* const formatPrices = () => {

    let localData = [...editedData];

    localData.forEach(item => {
      if (!isNaN(parseFloat(item.reference_price))) {
        let reference_price = item.reference_price;
        item.reference_price = reference_price.toFixed(2).replace('.', ',');
      }
      else{
        item.reference_price = "";
      }
      if (!isNaN(parseFloat(item.price))) {
        let price = item.price;
        item.price = price.toFixed(2).replace('.', ',');
      }
      else{
        item.price = "";
      }
      if (!isNaN(parseFloat(item.reduced_price))) {
        let reduced_price = item.reduced_price;
        reduced_price = Math.round(reduced_price);
        item.reduced_price = reduced_price.toFixed(2).replace('.', ',');
      }
      else {
        item.reduced_price = "";
      }
    });
    setEditedData(localData);
  } */

  const orderDisplay = (order) => {
    setResultsDisplay(order);
  };
  
  const handleDBFetch = () => {
    setRefreshData(!refreshData);
  };

  const handleDBWrite = () => {
    writeEditedTests(editedData);
  };

  const handleEdit = (id, field, value) => {
    const newData = editedData.map(item => {
      if (item.id === id) {
        item = { ...item, [field]: value };
        return item;
      }
      return item;
    });
    setEditedData(newData);
  };

  const handleNewEntry = (id, category) => {
    const newData = editedData.reduce((acc, item) => {
      acc.push(item);
      if (item.id === id) {
        acc.push({
          id: getUUID(),
          category: category,
          tariff_code: '',
          mnemonic: '',
          lab: '',
          long_name: '',
          short_name:'',
          reference_price:'',
          price:'',
          reduced_price:'',
          vat_amount:'',
          active:'TRUE',
          // Add more fields if needed
        });
      }
      return acc;
    }, []);
    setEditedData(newData);
  };

  const deleteEntry = (idToDelete) => {
    const newData = editedData.filter(item => item.id !== idToDelete);
    setEditedData(newData);
  };


  return (
    <>
    <Header src="joker1" dontShowOnSmallViewPort>
      <Header.Frame>
        <Header.Group>
          <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
          <Header.Button>My Details</Header.Button>
          <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test</Header.ButtonLink>
          <Header.ButtonLink to={ROUTES.LAB}>Back to Lab Home</Header.ButtonLink>
        </Header.Group>
      </Header.Frame>
      <Header.Feature>
          <Header.FeatureCallOut>Edit Tests</Header.FeatureCallOut>
          <Header.Text>
            Here you can edit the tests your lab is offering to patients and doctors.
          </Header.Text>
        </Header.Feature>
    </Header>

    <EditTable.ButtonContainer>
        <EditTable.Button onClick={handleDBWrite}>
          Save to Database
        </EditTable.Button>
        <EditTable.Button onClick={handleDBFetch}>
          Load from Database
        </EditTable.Button>
        <EditTable.ButtonSpacer>
        </EditTable.ButtonSpacer>
        <EditTable.LeftText id="priceCalc" type="text" style={{ width: '100px' }} default="adjustment %" />
        <EditTable.Button onClick={handlePriceCalc}>
          Adjust EZPath Pricing
        </EditTable.Button>
    </EditTable.ButtonContainer>

    <div>
      <table style={{ padding: '0 50px', tableLayout: 'auto', width: '100%' }}>
        <thead>
          <tr>
              <td><EditTable.ColumnLabel style={{ width: '10px', cursor:"pointer"}}>+</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("tariff_code")} style={{ width: '50px', cursor:"pointer"}}>T/C</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("mnemonic")} style={{ width: '50px', cursor:"pointer"}}>Mnem.</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("lab")} style={{ width: '25px', cursor:"pointer"}}>Lab</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("long_name")} style={{ width: '300px', cursor:"pointer" }}>Test Full Name</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("name")} style={{ width: '300px', cursor:"pointer" }}>Display Name</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("category")} style={{ width: '300px', cursor:"pointer" }}>Category</EditTable.ColumnLabel></td>
        
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("reference_price")} style={{ width: '100px', cursor:"pointer" }}>Ref. Price</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("price")} style={{ width: '100px', cursor:"pointer" }}>Base Price</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("reduced_price")} style={{ width: '100px', cursor:"pointer" }}>EZP Price</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("active")} style={{ width: '60px', cursor:"pointer" }}>Active</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel style={{ width: '10px', cursor:"pointer" }}>-</EditTable.ColumnLabel></td>
          </tr>
        </thead>
        <tbody>
          {editedData.map(item => (
            <tr>
            <td><EditTable.LeftText type="text" readOnly={true} style={{ width: '10px', cursor:"pointer" }} value="+" onClick={e => handleNewEntry(item.id, item.category)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '50px' }}/* key={key} */ value={item.tariff_code} onChange={e => handleEdit(item.id, "tariff_code", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '50px' }}/* key={key} */ value={item.mnemonic} onChange={e => handleEdit(item.id, "mnemonic", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '25px' }}/* key={key} */ value={item.lab? item.lab:''} onChange={e => handleEdit(item.id, "lab", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '300px' }}/* key={key} */ value={item.long_name} onChange={e => handleEdit(item.id, "long_name", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '300px' }}/* key={key} */ value={item.short_name} onChange={e => handleEdit(item.id, "short_name", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '300px' }}/* key={key} */ value={item.category} onChange={e => handleEdit(item.id, "category", e.target.value)}/></td>
            
            <td><EditTable.RightText type="text" style={{ width: '100px' }}/* key={key} */ value={item.reference_price} onChange={e => handleEdit(item.id, "reference_price", e.target.value)}/></td>
            <td><EditTable.RightText type="text" style={{ width: '100px' }}/* key={key} */ value={item.price} onChange={e => handleEdit(item.id, "price", e.target.value)}/></td>
            <td><EditTable.RightText type="text" style={{ width: '100px' }}/* key={key} */ value={item.reduced_price} onChange={e => handleEdit(item.id, "reduced_price", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '60px' }}/* key={key} */ value={item.active} onChange={e => handleEdit(item.id, "active", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" readOnly={true} style={{ width: '10px', cursor:"pointer" }} value="-" onClick={e => deleteEntry(item.id)}/></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
}