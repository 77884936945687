import React, { useContext, useEffect } from 'react';
import { Feature, OptForm, AccordionTests } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { OrderContext } from '../context/OrderContext';


export default function CheckoutReturn({profile}) {

  const history = useHistory();
//  const [orderData, setOrderData] = useContext(OrderContext);

  function handleReturnClick() {

    //  console.log("User Profile: ", profile);
    //  console.log("Order Data: ", orderData);

    if (profile.user_id === undefined || profile.user_id === null) {
      history.push(ROUTES.LANDING);
    }
    else {
      history.push(ROUTES.HOME);
    }
  }

//clear existing orders in the system
useEffect(() => {
  window.scrollTo(0,0);
  // Clear the order data
  localStorage.removeItem('selectedTests');
  localStorage.removeItem('orderInfo');
  localStorage.removeItem('selectedSite');
}, []);


  return (
    <>
      <HeaderContainer>
          <OptForm>
          <AccordionTests>
            <Feature.Title>Payment Success</Feature.Title>
            <OptForm.LeftText>Thank you for your payment.</OptForm.LeftText>
            <OptForm.LeftText>You will receive an invoice and further instructions by email or WhatsApp</OptForm.LeftText>
            <OptForm.LeftText>The next step is sample collection at your preferred location.</OptForm.LeftText>
            <OptForm.Break />
            <OptForm.Break />
            <OptForm.Button onClick={() => handleReturnClick()}>Return</OptForm.Button>
            <OptForm.Break />
            
            <OptForm.Text>Having trouble or have questions? Let us help you:</OptForm.Text>
            <OptForm.Text>Mobile: 073 178 2327</OptForm.Text>
            <OptForm.Text>WhatsApp: 067 231 7355</OptForm.Text>
            <OptForm.Text>Email: customercare@ezpath.co.za</OptForm.Text>
          </AccordionTests>
          </OptForm>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}