import React, { useState, useContext, useEffect } from 'react';
import {useHistory } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { UserContext } from '../context/UserContext';
import { Form, Profiles } from '../components';
import { HeaderContainer } from './header';
import { FooterContainer } from './footer';
import * as ROUTES from '../constants/routes';

export function PracticeMemberNewContainer({user}) {
  const history = useHistory();
  const auth = getAuth();
  const [userData, setUserData, editProfile, setEditProfile] = useContext(UserContext);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [feedback, setFeedback] = useState('');
  const photo_URL = (editProfile?.photoURL && editProfile.photoURL !== "") ? editProfile.photoURL 
  : Math.floor(Math.random() * 10) + 1; //random from a limited set of avatars to start with 
  const [newUser, setNewUser] = useState({});

  const isInvalid = editProfile.name === '' || password === '' || repeatPassword === '' || editProfile.email_address === '' || password !== repeatPassword;

  const editAvatar = (event) => {
    history.push(ROUTES.SELECT_AVATAR);
  }

  function getUUID() {
    // eslint gets funny about bitwise
    /* eslint-disable */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const piece = (Math.random() * 16) | 0;
        const elem = c === 'x' ? piece : (piece & 0x3) | 0x8;
        return elem.toString(16);
    });
    /* eslint-enable */
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditProfile({ ...editProfile, [id]: value });
    //setOrderInfoLocKey(prevKey => prevKey + 1);
};

  /* useEffect(() => { //the default user role for new users is "user"
    var check_box = document.getElementById("user");
    if (Object.keys(check_box).length !== 0) { //object exists
        check_box.checked = true;
    }
  }, []);
 */
  useEffect(() => {

    if (Object.keys(editProfile).length === 0 && userData.user_type === "practice") {   //we are returning to the page, no need to populate default values
  
      var editProfileLoc = ({});

      editProfileLoc.user_type = "practice";
      editProfileLoc.edit_type = "new_user";
      editProfileLoc.name = "";
      editProfileLoc.surname = "";
      // editProfileLoc.national_id = "";
      editProfileLoc.mobile_number = "";
      editProfileLoc.email_address = "";
      editProfileLoc.photoURL = photo_URL;

      setEditProfile(editProfileLoc);
    }
  }, [])



  function readUserRole() {
    var is_checked = document.getElementById('admin').checked;
    var role = '';
    if (is_checked == true) { role = 'admin';}
    is_checked = document.getElementById('doctor').checked;
    if (is_checked == true) {role = 'doctor';}
    is_checked = document.getElementById('user').checked;
    if (is_checked == true) {role = 'user';}

    return role;
  }

 
  const handleNewPracticeMember = async (event) => {
    event.preventDefault();
    handleCreateNewUser();
  
      /* setFeedback("");
  
      const role = readUserRole();
      const name = document.getElementById('name').value;
      const surname = document.getElementById('surname').value;
      const nationalId = document.getElementById('national_id').value;
      const mobileNumber = document.getElementById('mobile_number').value;
      const email = document.getElementById('email_address').value;
      const principalId = userData?.user_id || "";
  
      try {
          // 1. Create User in Firebase Authentication
          const auth = getAuth();
          const result = await createUserWithEmailAndPassword(auth, email, password);
  
          const currentUser = auth.currentUser; // Get the currently logged-in user
          if (result.user.uid === currentUser?.uid) { 
              // User IDs match, so update the profile
              await updateProfile(result.user, {
                  displayName: name,
                  photoURL: photo_URL || "", 
              });
          }
  
          // 3. Prepare User Data for Firestore
          const userDataForFirestore = {
              displayName: name,
              email: email,
              mobileNumber: mobileNumber,
              firstName: name,
              lastName: surname,
              nationalId: nationalId,
              photoURL: photo_URL || "",
              principalId: principalId,
              userId: result.user.uid,
              userType: "practice",
              userRole: role,
              practiceId: userData.practice_id, 
          };
  
          // 4. Add User to Firestore
          const db = getFirestore();
          const usersCollection = collection(db, "users");
          await addDoc(usersCollection, userDataForFirestore);
  
          setFeedback("New practice member successfully created!");
      } catch (error) {
          console.error("Error creating new practice member:", error);
          setFeedback(error.message); // Display error message to the user
      } */
  };

  async function handleCreateNewUser() {
    try {
      setFeedback("");
      const role = readUserRole(); // Assuming you have this function defined
      const name = document.getElementById('name').value;
      const surname = document.getElementById('surname').value;
      // const nationalId = document.getElementById('national_id').value;
      const mobileNumber = document.getElementById('mobile_number').value;
      const email = document.getElementById('email_address').value; 
      const principalId = userData?.user_id || ""; // Get principal ID or empty string
      const practiceId = userData?.practice_id || "";
      const userDataForFirestore = {
        password: password,
        displayName: name,
        email_address: email,              // Use standard 'email' field name
        mobile_number: mobileNumber,
        name: name,           // Split into first and last name
        surname: surname,
        // national_id: nationalId,
        photoURL: photo_URL || "", // Use placeholder if photo_URL not available
        principal_id_1: principalId,
        practice_id: practiceId,
        //userId: result.user.uid, // Generate unique ID on client-side
        user_type: "practice",
        user_role: role,
      };
  
        const response = await fetch('https://us-central1-ezpath-325415.cloudfunctions.net/createNewUser', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userDataForFirestore)
        });
  
      const data = await response.json();
      if (response.ok) { // Check if the response was successful
        setFeedback(data.message); 
      } else {
        setFeedback(`Error: ${data.error || "An error occurred."}`); // Show the error message from the server or a generic one if not provided.
      }
  
    } catch (error) {
      setFeedback(`Error creating user: ${error.message}`); 
    }
  }

  
  function handleBackClick() {
    setEditProfile({});
    history.goBack();
  }

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  return (
    <>
      <HeaderContainer>
        <Form>
        <Form.BackButton onClick={() => handleBackClick()} >Back</Form.BackButton>
        <Form.LongContainer>
          <div>
            <Profiles.OverlayPicture src={"edit_overlay"} onClick={() => editAvatar()}></Profiles.OverlayPicture> 
            <Profiles.Picture src={photo_URL}></Profiles.Picture>
          </div>
        </Form.LongContainer>
        <Form.LongContainer>
          <Form.Title>Edit Practice Member Details</Form.Title>   
        </Form.LongContainer>
          {feedback && <Form.Error data-testid="feedback">{feedback}</Form.Error>}
          <Form.Base onSubmit={handleNewPracticeMember} method="POST">
          <Form.Text>User Role:</Form.Text>
          <Form.Body><Form.RadioBox id = 'admin' name='testBox'></Form.RadioBox>Administrator</Form.Body>
          <Form.Body><Form.RadioBox id = 'doctor' name='testBox'></Form.RadioBox>{toSentenceCase(userData.user_profession)}</Form.Body>
          <Form.Body><Form.RadioBox id = 'user' name='testBox'></Form.RadioBox>User</Form.Body>
          <Form.Input
              placeholder="First name"
              /* value={firstName} */
              value = {editProfile.name}
              /* onChange={({ target }) => setFirstName(target.value)} */
              onChange={handleChange}
              id="name"
            />
            <Form.Input
              placeholder="Surname"
              defaultValue={""}
              value = {editProfile.surname}
              onChange={handleChange}
              id = "surname"
            />
            {/* <Form.Input
              placeholder="Identity Number"
              defaultValue={""}
              value = {editProfile.national_id}
              onChange={handleChange}
              id = "national_id"
            /> */}
            <Form.Input
              placeholder="Mobile Number"
              /* defaultValue={userData[0].mobile_number} */
              value = {editProfile.mobile_number}
              onChange={handleChange}
              id = "mobile_number"
            />
            <Form.Input
              placeholder="Email address"
              /* value={emailAddress} */
              value = {editProfile.email_address}
              /* onChange={({ target }) => setEmailAddress(target.value)} */
              onChange={handleChange}
              id = "email_address"
            />
            <Form.Input
              type="password"
              /* value={password} */
              autoComplete="off"
              value = {password}
              placeholder="Password"
              /* onChange={({ target }) => setPassword(target.value)} */
              onChange={({ target }) => setPassword(target.value)}
            />
              <Form.Input
              type="password"
              value={repeatPassword}
              autoComplete="off"
              placeholder="Repeat password"
              onChange={({ target }) => setRepeatPassword(target.value)}
            />
            {feedback && <Form.Error data-testid="feedback">{feedback}</Form.Error>}
            <Form.Button disabled={isInvalid} type="submit" data-testid="sign-up">
              Create New Practice Member
            </Form.Button>
          </Form.Base>
        </Form>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}