import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';
import { UserContext } from "../context/UserContext";
import { Form } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import * as ROUTES from '../constants/routes';
import { getAuth, createUserWithEmailAndPassword, updateProfile, onAuthStateChanged} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc, doc, getDoc } from 'firebase/firestore';



export default function SignUp() {
  const history = useHistory();
  const { firebase } = useContext(FirebaseContext);
  const firestore = getFirestore();
  const auth = getAuth();
  const db = getFirestore();
  const [setUserData, signupEmail] = useContext(UserContext);
  //const [setUserData] = useContext(UserContext);
  const [firstName, setFirstName] = useState('');
  const [emailAddress, setEmailAddress] = useState(Object.keys(signupEmail).length !== 0 ? signupEmail : "");
  //const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [permission, setPermission] = useState(false);
  


  const isInvalid = firstName === '' || password === '' || repeatPassword === '' || emailAddress === '' || password !== repeatPassword || permission === false;

  const handleSignup = async(event) => {
    event.preventDefault();
    const photo_URL = Math.floor(Math.random() * 10) + 1;

    const auth = getAuth(); // Get the authentication instance
    const db = getFirestore();

    try {
        // 1. Create user in Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(auth, emailAddress, password);
        const user = userCredential.user;
        
        // 2. Update user profile
        await updateProfile(user, {
            displayName: firstName,
            photoURL: photo_URL
        });

        // 3. Add user data to Firestore
        const userData = {
                  displayName: firstName,
                  email_address: emailAddress,
                  medaid_name : "",
                  medaid_number: "",
                  medaid_plan: "",
                  mobile_number: "",
                  name: firstName,
                  surname: "",
                  national_id: "",
                  photoURL: photo_URL,
                  principal_id_1: "",
                  principal_id_2: "",
                  user_id: user.uid,
                  user_type: "patient",
                  comms_permission: permission,
        };

        await addDoc(collection(db, "users"), userData); // Use addDoc

        // 4. Fetch user data after creation and update state
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            const userInfo = userDocSnap.data();
            setUserData(userInfo);
            history.push(ROUTES.HOME);
        } else {
            console.error("User document not found in Firestore after creation.");
            // Consider error handling/fallback logic here
        }

    } catch (error) {
        console.error("Signup error:", error);
        setError(error.message);

        // Clear input fields
        setFirstName('');
        setEmailAddress('');
        setPassword('');
        setRepeatPassword(''); 
    } finally {
        // setIsLoading(false);
    } 
};



  //  todo: when trying to create a new user - make sure the old User Context is cleared, old login cookies are cleared
  //  first create a new user in Firebase Auth
  //  todo: if successful, then also make an entry in the Firestore Database for this new user
 /*  const handleSignup = async(event) => {
    event.preventDefault();
    const photo_URL = Math.floor(Math.random() * 10) + 1;  //todo: put generic images in 1 to 5 (random) and gender/age/race specific images later on

    return auth
            .createUserWithEmailAndPassword(emailAddress, password)
            .then((result) =>
              result.user
                .updateProfile({
                  displayName: firstName,
                  photoURL: photo_URL,
                })
                .then(() => {
                //  add user details into the Firebase Database as well
                  firebase.firestore().collection("users").add({
                    displayName: firstName,
                    email_address: emailAddress,
                    medaid_name : "",
                    medaid_number: "",
                    medaid_plan: "",
                    mobile_number: "",
                    name: firstName,
                    surname: "",
                    national_id: "",
                    photoURL: photo_URL,
                    principal_id_1: "",
                    principal_id_2: "",
                    user_id: result.user.uid,
                    user_type: "patient",
                    comms_permission: permission,
                  })
                  .catch((error) => {
                    console.log("Create New User Error: write to Firestore - ", error);
                  });

            console.log("signin page: SUCCESSFUL FIREBASE SIGNUP AND SIGNIN");
            const authUser = auth.currentUser; 
            const uid2 = authUser.uid;
            firebase
              .firestore()
              .collection("users").where("user_id", "==", uid2)
              .get()
              .then((snapshot) => {
                const userInfo = snapshot.docs.map((resultsObj) => ({
                  ...resultsObj.data(),
                  docId: resultsObj.id,
                }));
                setUserData(userInfo[0]);
                //localStorage.setItem('authUser', JSON.stringify(authUser));
                //localStorage.setItem('userDetails', JSON.stringify(userDetails));
                //console.log("USER DETAILS updated after signin");
                history.push(ROUTES.HOME);
              })
          })
      )
      .catch((error) => {
        setFirstName('');
        setEmailAddress('');
        setPassword('');
        setRepeatPassword('');
        setError(error.message);
      });
  }; */

  const togglePermission = (event) => {
    //event.preventDefault();
    
    var is_checked = event.target.checked;

    setPermission(is_checked);
    
  }


  return (
    <>
      <HeaderContainer>
        <Form>
          <Form.Title>Sign Up</Form.Title>
          {error && <Form.Error>{error}</Form.Error>}

          <Form.Base onSubmit={handleSignup} method="POST">
            <Form.Input
              placeholder="First name"
              value={firstName}
              onChange={({ target }) => setFirstName(target.value)}
            />
            <Form.Input
              placeholder="Email address"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              onChange={({ target }) => setPassword(target.value)}
            />
              <Form.Input
              type="password"
              value={repeatPassword}
              autoComplete="off"
              placeholder=" Repeat password"
              onChange={({ target }) => setRepeatPassword(target.value)}
            />
            <Form.Body>
              <Form.LongContainer><Form.RadioBox onClick = {togglePermission} id = 'permission' name='permission'></Form.RadioBox>
              <Form.TextLeft>      </Form.TextLeft>
                <Form.Text>EZPath can use my sms, email and Whatsapp to let me know about my test results and appointment details.</Form.Text>
              </Form.LongContainer>
              </Form.Body>

            <Form.Button disabled={isInvalid} type="submit" data-testid="sign-up">
              Sign Up
            </Form.Button>
          </Form.Base>
          
          <Form.Space></Form.Space>
          <Form.Link to="/signin">
            Already a member? - Sign in now.
          </Form.Link>
          <Form.Link to={ROUTES.FOOTER_CONTACT_US}>Medical practice or laboratory? - Contact us to join our network</Form.Link>
          {/* <Form.TextSmall>
            This page is protected by Google reCAPTCHA to ensure you're not a bot. Learn more.
          </Form.TextSmall> */}
        </Form>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}