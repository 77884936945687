import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import your Firestore instance

export default function usePracticeInfo(practiceID) {
  const db = getFirestore();
  const [practiceInfo, setPracticeInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPracticeInfo = async () => {
      if (!practiceID) {
        setPracticeInfo(null);
        setIsLoading(false);
        setError(null);
        return;
      }

      setIsLoading(true);

      try {
        const practiceRef = doc(db, 'practices', practiceID); 
        const snapshot = await getDoc(practiceRef);

        if (snapshot.exists()) {
          setPracticeInfo(snapshot.data());
        } else {
          setError('Practice not found');
        }
      } catch (err) {
        setError('Error fetching practice data');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPracticeInfo();
  }, [practiceID]);

  return { practiceInfo, isLoading, error };
}
