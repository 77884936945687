import { getFirestore, doc, runTransaction } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';

export default async function writeIncompleteOrder(practiceId, selectedTests, info, selectedSite, items, id) {
  try {
    const db = getFirestore();
    let newTestCreated = false; // Flag to indicate if a new test was created

    await runTransaction(db, async (transaction) => {
      const docRef = doc(db, 'incomplete_orders', practiceId);
      const docSnap = await transaction.get(docRef);

      let existingData = docSnap.exists() ? docSnap.data().incompleteTests : [];

      // Check if an order with the same ID already exists
      const existingOrderIndex = existingData.findIndex(order => order.id === id);
      if (existingOrderIndex !== -1) {
        // Update existing test
        existingData[existingOrderIndex] = {
          ...existingData[existingOrderIndex], 
          selectedTests, 
          orderInfo: info, 
          selectedSite, 
          ...(items[0] && { testItems: items[0] }) 
        };
      } else {
        // Create new test
        newTestCreated = true;
        const newIncompleteTest = {
          id: uuidv4(), // Use info.orderId if you want to specify the ID explicitly
          selectedTests,
          orderInfo: info,
          selectedSite,
          ...(items[0] && { testItems: items[0] }),
        };
        existingData.push(newIncompleteTest);
      }

      transaction.set(docRef, { incompleteTests: existingData }, { merge: true });
    });

    console.log(newTestCreated ? 'New incomplete order added for practice:' : 'Incomplete order updated for practice:', practiceId);
    return true;
  } catch (error) {
    console.error('Error writing/updating incomplete order:', error);
    return false;
  }
}
