import React, { useEffect } from 'react';
import { Contact, OptForm, Form } from '../components';
import {useHistory} from 'react-router-dom'; 

export default function FooterContactUs({}) {

    const history = useHistory();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    
    return (
      <>
      <Contact>
      <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
      <div>
        <Form.Text>Contact Us</Form.Text>
          <Form.Text>Email: </Form.Text>
          <Form.TextLeft>customercare@ezpath.co.za</Form.TextLeft>
          <Form.Text>Phone: </Form.Text>
          <Form.TextLeft>+27 (73) 178 2327</Form.TextLeft>
          <Form.Text>Whatsapp: </Form.Text>
          <Form.TextLeft>+27 (73) 178 2327</Form.TextLeft>
        </div>
        <p> </p>
        <Form.Text>GARSFONTEIN (Pretoria, Gauteng) (COLLECTIONS NOT ACTIVE YET)</Form.Text>
        <Form.TextLeft>EZPath Office and Oncolab Sample Collection Venue</Form.TextLeft>
        <Form.TextLeft>562 Jacqueline Dr, Garsfontein, Pretoria, 0042</Form.TextLeft>
        <Contact.MapContainer>
               <Contact.ContactFrame src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14369.631943420964!2d28.2974936!3d-25.790111!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa56c0707685fc6bd%3A0x90034396aedf122a!2sOncolab%20Head%20Office%20and%20Garsfontein%20Lab!5e0!3m2!1sen!2sza!4v1706261895449!5m2!1sen!2sza"  
               width="600" 
               height="450" 
               style={{border:5}} 
               allowfullscreen="" loading="lazy" 
               referrerpolicy="no-referrer-when-downgrade">
               </Contact.ContactFrame>
        </Contact.MapContainer>
        <p> </p>
        <Form.Text>PRETORIA EAST HOSPITAL (Pretoria, Gauteng)</Form.Text>
        <Form.TextLeft>Oncolab Sample Collection Venue</Form.TextLeft>
        <Form.TextLeft>Room M34, Third Floor, Netcare Pretoria East Hospital, c/o Garsfontein and Netcare, Moreletapark, 0044</Form.TextLeft>
        <Contact.MapContainer>
               <Contact.ContactFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14366.264485378253!2d28.300268787687404!3d-25.817884404599315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e955d8ca9714be5%3A0xc85442b2d1206504!2sOncolab!5e0!3m2!1sen!2sza!4v1706166373138!5m2!1sen!2sza"    
               width="600" 
               height="450" 
               style={{border:5}} 
               allowfullscreen="" loading="lazy" 
               referrerpolicy="no-referrer-when-downgrade">
               </Contact.ContactFrame>
        </Contact.MapContainer>
        <p> </p>
        <Form.Text>MIDSTREAM HILL MEDICAL PARK (Midstream, Gauteng) (COLLECTIONS NOT ACTIVE YET)</Form.Text>
        <Form.TextLeft>Oncolab Sample Collection Venue</Form.TextLeft>
        <Form.TextLeft>Room 120, Midstream Hill Medical Park, Godley Drive, Midstream, 1692</Form.TextLeft>
        <Contact.MapContainer>
               <Contact.ContactFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9442.16282011568!2d28.17737568792391!3d-25.92672492037852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e956f7965ad1977%3A0xfe683f7571191901!2sOncolab%20Midstream%20Lab!5e0!3m2!1sen!2sza!4v1706262084461!5m2!1sen!2sza"
               width="600" 
               height="450" 
               style={{border:5}} 
               allowfullscreen="" loading="lazy" 
               referrerpolicy="no-referrer-when-downgrade">
               </Contact.ContactFrame>
        </Contact.MapContainer>
        <p> </p>
        <Form.Text>BENONI (Benoni, Gauteng) (COLLECTIONS NOT ACTIVE YET)</Form.Text>
        <Form.TextLeft>Oncolab Sample Collection Venue</Form.TextLeft>
        <Form.TextLeft>140 Princess Avenue, Benoni, 1500</Form.TextLeft>
        <Contact.MapContainer>
               <Contact.ContactFrame src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3580.0229819517535!2d28.303985075834376!3d-26.195930663637963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e953d8bc263121b%3A0xee0bfc8f08e08a76!2sOncolab%20Benoni%20Lab!5e0!3m2!1sen!2sza!4v1706176444534!5m2!1sen!2sza"
               width="600" 
               height="450" 
               style={{border:5}} 
               allowfullscreen="" loading="lazy" 
               referrerpolicy="no-referrer-when-downgrade">
               </Contact.ContactFrame>
        </Contact.MapContainer>
        <p> </p>
        <p> </p>
        <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
        <p> </p>
        <p> </p>
    </Contact>
    </>

    
  );
}