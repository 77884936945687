// Called when doctor clicks "Release to Patient".
// Adds requisition number to firebase release_queue collection for report release.
// test_results collection, released_to_patient field is updated to "pending" for all
// documents with a matching requisition number
import { setDoc, serverTimestamp } from "firebase/firestore";
import { getFirestore, collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";

export default async function writeToReleaseQueue(requisition_number) {
    try {  
      const db = getFirestore();  
      const docRef = doc(db, "release_queue", requisition_number); 
  
      await setDoc(docRef, {
        report_release_period: "0", // release on next release run
        timestamp: serverTimestamp(),
      });
  
      console.log(`Document ${requisition_number} added to instant release_queue.`);
    } catch (error) {
      console.error("Error adding document to release_queue:", error);
      throw error;
    }

    try {
      const db = getFirestore();
      // Create a query to filter documents
      const q = query(collection(db, "test_results"), where("requisition_number", "==", requisition_number));
  
      // Get matching documents
      const querySnapshot = await getDocs(q);
  
      // Update each matching document
      const updatePromises = querySnapshot.docs.map(async (docSnapshot) => {
        const docRef = doc(db, "test_results", docSnapshot.id);
        await updateDoc(docRef, {
          released_to_patient: "pending",
          released_by: "doctor",
          released_date: serverTimestamp(),
        });
        // console.log(`Updated document ${docSnapshot.id}`);
      });
  
      // Wait for all updates to complete
      await Promise.all(updatePromises);
      // console.log("All documents updated successfully");
  
    } catch (error) {
      // console.error("Error updating documents:", error);
      throw error; // Handle the error appropriately
    }
  }
