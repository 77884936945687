import React from 'react';
import { Accordion, OptForm } from '../components';
import faqsData from '../fixtures/faqs';

export function FaqsContainer() {
  return (
    <Accordion>
      <Accordion.Title>Frequently Asked Questions</Accordion.Title>
      <Accordion.Frame>
        {faqsData.map((item) => (
          <Accordion.Item key={item.id}>
            <Accordion.Header>{item.header}</Accordion.Header>
            {(item.action && item.action === "contact") ?
              <Accordion.Body>
                <OptForm.Link to="/footer_contact_us">{item.body}</OptForm.Link>
              </Accordion.Body>
              :
              <Accordion.Body>{item.body}</Accordion.Body>}
          </Accordion.Item>
        ))}
      </Accordion.Frame>

      <OptForm>
        <OptForm.Input placeholder="Email address" />
        <OptForm.Button>Try it now</OptForm.Button>
        <OptForm.Break />
        <OptForm.Text>Ready to test? Enter your email to create your membership or log in.</OptForm.Text>
      </OptForm>
    </Accordion>
  );
}