import React from 'react';
import { EditSitesContainer } from '../containers/edit_sites';



export default function EditSites({}) {

/*   let props = {
    userData: userData
  } */

  return <EditSitesContainer/*  props={props} */ />;
}