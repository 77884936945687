import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 660px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 450px;
  align: center;
  margin: auto;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin-bottom: 100px;
  margin-left: 100px;
`;

export const BackButton = styled.button`
  background: #cc6600;
  border-radius: 4px;
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  margin: 0px 10px 20px -50px;
  padding: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;
