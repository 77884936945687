import React from 'react';
import { EditTestsContainer } from '../containers/edit_tests';



export default function EditTests({}) {

/*   let props = {
    userData: userData
  } */

  return <EditTestsContainer/*  props={props} */ />;
}