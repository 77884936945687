import { useEffect, useState } from 'react';
import { getFirestore, onSnapshot, doc } from 'firebase/firestore';

export default function useIncompleteOrders(practiceId) {
  const [incompleteOrders, setIncompleteOrders] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const docRef = doc(db, "incomplete_orders", practiceId);

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        setIncompleteOrders(data.incompleteTests || []);
      } else {
        console.log("No incomplete orders found for this practice.");
        setIncompleteOrders([]); // Clear the state if the document is deleted
      }
    }, (error) => {
      console.error('Error fetching incomplete orders:', error);
    });

    return () => {
      // Clean up the listener when the component unmounts
      unsubscribe();
    };
  }, []);

  return { incompleteOrders };
}
