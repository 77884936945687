import React from 'react';
import { LabMemberNewContainer } from '../containers/lab_member_new';



export default function LabMemberNew({user}) {

  let props = {
    user: user
  }

  return <LabMemberNewContainer props={props} />;
}

