/* export default function updateOrder(order_data) {
  const updatePromise = firebase
    .firestore()
    .collection("order_items")
    .doc(order_data.docId)
    .update(order_data)
    .then(() => order_data) // Return updatedData when the update is successful
    .catch((error) => {
      console.log("Order Status Update to Dispatched Write to Firestore Error - ", error);
      return false; // Return false for a failed update
    });

  return updatePromise
    .then((result) => {
      // Check if the update was successful (result is updatedData or false)
      if (result === false) {
        return false; // Update failed
      } else {
        return result; // Update was successful, return updatedData
      }
    })
    .catch((error) => {
      console.log("Error updating the item: ", error);
      return false;
    });
} */

import { getFirestore, doc, updateDoc } from 'firebase/firestore'; 

export default async function updateOrder(order_data) {
  const db = getFirestore();
  const orderRef = doc(db, "order_items", order_data.docId);

  try {
    updateDoc(orderRef, order_data);
    return order_data; // Return updatedData on success
  } catch (error) {
    console.log("Order Status Update Write to Firestore Error - ", error);
    return false; // Return false on failure
  }
}

