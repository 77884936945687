import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as ROUTES from '../constants/routes';


export function IsUser({ user, loggedInPath, path, children, ...rest }) {

  return (
    <Route
      {...rest}
      render={() => {
        if (user && (user[0] != null)) {
          return (
            <Redirect
              to={{
                pathname: loggedInPath,
              }}
            />
          );
        }
        else {
          return children;
        }
      }}
    />
  );
}

export function Popup({ user, userData, path, children, ...rest }) {

  return (
    <Route
      {...rest}
      render={() => {
          return children;
        }
      }
    />
  );
}

export function ProtectedRoute({ user, userData, path, children, ...rest }) {

  return (
    <Route
      {...rest}
      render={({ location }) => {

        /* if ((typeof userData != "undefined") && (userData != null) && (Object.keys(userData).length !== 0) 
            && (Object.keys(user).length !== 0))  {  // a user is logged in */
        if ((typeof userData != "undefined") && (userData != null) && (user != null) && (Object.keys(user).length !== 0)) {  // a user is logged in and has data loaded

          if ((userData.user_type === 'patient') && ((path === ROUTES.LANDING)||(path === ROUTES.HOME))) {
            console.log("Redirecting: to patient home");
            return (
              <Redirect
                to={{
                  pathname: ROUTES.PATIENT,
                  state: { from: location },
                }}
              />
            );
          }
          else if ((userData.user_type === 'lab') && ((path === ROUTES.LANDING)||(path === ROUTES.HOME))) {
            console.log("Redirecting: to lab home");
            return (
              <Redirect
                to={{
                  pathname: ROUTES.LAB,
                  state: { from: location },
                }}
              />
            );
          }
          else if ((userData.user_type === 'practice') && ((path === ROUTES.LANDING)||(path === ROUTES.HOME))) {
              console.log("Redirecting: to practice home");
              return (
                <Redirect
                  to={{
                    pathname: ROUTES.PRACTICE,
                    state: { from: location },
                  }}
                />
              );
            }
          else if ((userData.user_type === 'patient') && (path === ROUTES.PATIENT)) {
            console.log("Routing: to patient home");
              return children;
          }
          else if ((userData.user_type === 'practice') && (path === ROUTES.PRACTICE)) {
            console.log("Routing: to practice home");
            return children;
          }
          else if ((userData.user_type === 'lab') && (path === ROUTES.LAB)) {
            console.log("Routing: to lab home");
            return children;
          }
  /*         else if (path === ROUTES.LANDING) {
            console.log("Routing: to landing B?");
            return children;
          } */
        }
        else if (path === ROUTES.LANDING) {   //  not logged in, go to landing page
          console.log("Routing: to landing");
          return children;
        }
        else {  //  not logged in, trying to get to protected page, send to landing page
          console.log("Redirecting: to landing");
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }
        return;
      }}
    />
  );
}