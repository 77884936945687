import { firebase } from '../lib/firebase.prod';
import { getFirestore, collection, query, where, getDocs, batch, setDoc, deleteDoc } from 'firebase/firestore';

export default async function movePloToDiscarded(payment_id, name, surname, role) {
  //const db = getFirestore(); 
  try {
    const discarded_date = new Date();
    const db = getFirestore();

    // Get documents based on m_payment_id
    const orderItemsRef = collection(db, 'order_pay_later_items');
    const paymentQuery = query(orderItemsRef, where('m_payment_id', '==', payment_id)); 
    const querySnapshot = await getDocs(paymentQuery);

    // Create a Firestore batch
    const batch = db.batch(); 

    querySnapshot.forEach(doc => {
        const data = doc.data();
        const discarded_role = role;
        const discarded_type = "order"; 
        const discarded_by = (name || 'unknown') + ' ' + (surname || 'unknown'); 
        const discardedData = { ...data, discarded_by, discarded_date, discarded_type, discarded_role };

        // Add operations to the batch
        const discardRef = doc(db, 'discarded_plo_items', doc.id); 
        batch.set(discardRef, discardedData);
        batch.delete(doc.ref); 
    });

    // Commit the batch operation
    await batch.commit(); 

    console.log('Pay Later Order ' + payment_id +': Documents moved to discarded_plo_items and deleted successfully.'); 
  } catch (error) { 
      console.error('Error moving and deleting documents:', error);
  }
}
  /* try {
    const discarded_date = new Date();

    // Get documents from order_pay_later_items collection based on m_payment_id
    const querySnapshot = await firebase.firestore().collection('order_pay_later_items')
      .where('m_payment_id', '==', payment_id)
      .get();

    // Iterate through the documents and move them to discarded_plo_items
    const batch = firebase.firestore().batch();
    querySnapshot.forEach(doc => {
      const data = doc.data();
      const discarded_role = role;
      const discarded_type = "order"; // whole order as opposed to single test
      const discarded_by = (name || 'unknown') + ' ' + (surname || 'unknown'); // Handling blank or null values
      const discardedData = { ...data, discarded_by, discarded_date, discarded_type, discarded_role };
      batch.set(firebase.firestore().collection('discarded_plo_items').doc(doc.id), discardedData);
      batch.delete(doc.ref); // Delete the document from order_pay_later_items
    });

    // Commit the batch operation
    await batch.commit();

    console.log('Pay Later Order ' + {payment_id} +': Documents moved to discarded_plo_items and deleted successfully.');
  } catch (error) {
    console.error('Error:', error);
  } */


