export default function selectionFilterLabOrders(labOrders, orderby, condensed) {
  let labOrdersArr = [];
  let index = 0;
  let UniqueOrderedArray = [];
  let UniqueTitleArray = [];
  let filteredData = [];

  if (orderby === 'category') {
    // Sorting by test_category, secondary sorting by ordered_date timestamp
    UniqueTitleArray = [...new Set(labOrders.map(data => data.test_category))];
    for (index = 0; index < UniqueTitleArray.length; index++) {
      filteredData = labOrders?.filter((item) => item.test_category === UniqueTitleArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      labOrdersArr.push({ title: UniqueTitleArray[index], data: filteredData });
    }
  }
  else if (orderby === 'status') {
    // Sorting by status, secondary sorting by ordered_date timestamp
    UniqueOrderedArray = ["ordered","dispatched","sampled","received","testing","preliminary","final","released","completed","cancelled"];
    UniqueTitleArray = ["Ordered - Order submitted by patient or doctor",
      "Dispatched - A phlebotomist has been assigned to collect the sample",
      "Sampled - Sample has been collected by the phlebotomist",
      "Received - Sample has been received at the laboratory",
      "Testing - Sample is being tested",
      "Preliminary Results - Preliminary test results are available",
      "Final Results - Final results are available",
      "Released - Test results have been released to the doctor",
      "Completed - Test process is complete and the patient has the results",
      "Cancelled - Test order was cancelled"];
    for (index = 0; index < UniqueOrderedArray.length; index++) {
      filteredData = labOrders?.filter((item) => item.status === UniqueOrderedArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      if (filteredData.length > 0) {
      labOrdersArr.push({ title: UniqueTitleArray[index], data: filteredData });
      }
    }
  }
  else if (orderby === 'ordered_date') {

    // Sorting labOrders by ordered_date
    labOrders.sort((a, b) => a.ordered_date.seconds - b.ordered_date.seconds);
    UniqueTitleArray = [... new Set(labOrders.map(data => (new Date(data.ordered_date.seconds*1000).toDateString())))];
    //uniqueOrderedDatesArr.sort((a, b) => a - b); // Sort dates in ascending order

    for (index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = labOrders?.filter((item) => new Date(item.ordered_date.seconds*1000).toDateString() === UniqueTitleArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      labOrdersArr.push({
        title: UniqueTitleArray[index],
        data: filteredData });
    }
  }
  else if (orderby === 'doctor_surname') {
    // Sorting by doctor_surname, secondary sorting by ordered_date timestamp
    UniqueTitleArray = [... new Set(labOrders.map(data => data.doctors_surname || "No Doctor Assigned"))]; // Update here
    const updatedLabOrders = labOrders.map(order => ({
      ...order,
      doctors_surname: order.doctors_surname ? order.doctors_surname : "No Doctor Assigned"
    }));
    for (index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = updatedLabOrders?.filter((item) => item.doctors_surname === UniqueTitleArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      labOrdersArr.push({
        title: UniqueTitleArray[index],
        data: filteredData });
    }
  }
  else if (orderby === 'requisition_number') {
    // Sorting by requisition_number, secondary sorting by ordered_date timestamp
    UniqueOrderedArray = [...new Set(labOrders.map(data => data.requisition_number))];
    UniqueTitleArray = UniqueOrderedArray.map(value => (value === "" || value === undefined) ? "No Requisition" : value);
  
    for (let index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = labOrders?.filter((item) => {
        // Check for both empty string and undefined
        return item.requisition_number === UniqueTitleArray[index] || (item.requisition_number === undefined && UniqueTitleArray[index] === "No Requisition");
      });
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      labOrdersArr.push({
        title: UniqueTitleArray[index],
        data: filteredData
      });
    }
  }
  
  if (condensed === true) {
      for (const group of labOrdersArr) {
        const paymentIdGroups = group.data.reduce((groups, order) => {
          const paymentId = order.m_payment_id;
          groups[paymentId] = groups[paymentId] || [];
          groups[paymentId].push(order);  
          return groups;
        }, {}); 
      
        // Transform the grouped data
        group.data = Object.values(paymentIdGroups);
      }
  }
  
  return { labOrders: labOrdersArr };
}

