import React from 'react';
import { PatientContainer } from '../containers/patient_home';



export default function Patient({userData}) {

  let props = {
    userData: userData
  }

  return <PatientContainer props={props} />;
}