import React, { useState, useEffect, useContext } from 'react';
import { Header, Profiles, Loading } from '../components';
//import { useDependants } from '../hooks';
//import { UserContext } from '../context/UserContext';
//import { UserContext } from '../context/UserContext'; //smsmA
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';
import { useHistory } from 'react-router-dom';
//import { UserContext } from '../context/UserContext';

export function SelectProfileContainer({ editProfile, setEditProfile, user, setProfile, dependants, editMode, setEditMode, practiceMembers }) { 
//xport function SelectProfileContainer({user, setProfile, dependants, editMode, setEditMode, practiceMembers }) { 
  
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  //const [editProfile, setEditProfile] = useContext(UserContext); //smsmA
  
  
/*   useEffect (() => {
    if (dependants) {
      console.log(dependants);
    }

  }, [dependants]); */

  const handleDone = () => {
    setEditMode(false);
  } 

  function  handleProfileClick(item) {
    if (editMode === true) { 
      setEditProfile(item);
    }
    else if (editMode === false){
      setProfile(item);
    }    
  }  

  function  handleNewUserClick() {
    
    setEditProfile({}); //clear it for a new profile

    if (user.user_type == "lab") {
      setEditProfile({});
      history.push(ROUTES.LAB_MEMBER_NEW);
    }
    else if (user.user_type == "practice") {
      history.push(ROUTES.PRACTICE_MEMBER_NEW);
    }
    else {
      history.push(ROUTES.PATIENT_NEW);
    }
  }  

  return (
    <>
    {loading ? <Loading src={'/images/misc/loading.gif'}/> : <Loading.ReleaseBody />}

      <Header bg={false}>
        <Header.Frame>
          <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
        </Header.Frame>
      </Header>

      <Profiles>
      {editMode ?      
        <>  
        <Profiles.Title>Manage Profiles:</Profiles.Title>
        <Profiles.SubTitle>Select a profile to edit:</Profiles.SubTitle>
        </> 
      :<Profiles.Title>Who's Results?</Profiles.Title>}
        <Profiles.List>
          { (dependants === undefined || dependants === null)? <></>:
            <>
            <Profiles.User
              onClick={() => handleProfileClick(user)}
              data-testid="user-profile">
              {editMode ? <Profiles.OverlayPicture src={"edit_overlay"}></Profiles.OverlayPicture> : <></>}
              <Profiles.Picture src={user.photoURL} />
              <Profiles.Name>{user.name}</Profiles.Name>
            </Profiles.User>
            { Object.keys(dependants).length === 0 ?  <></> :  dependants.includes("empty") ? <></> :
              <>
              {dependants.map((item) => (
                <Profiles.User onClick={() => handleProfileClick(item)} key={item.user_id}
                  data-testid="user-profile">
                  {editMode ? <Profiles.OverlayPicture src={"edit_overlay"}></Profiles.OverlayPicture> : <></>}
                  <Profiles.Picture src={item.photoURL} />
                  <Profiles.Name>{item.name}</Profiles.Name>
                </Profiles.User>
                ))}
                </>
            } 
            </>
          }
          { (practiceMembers === undefined || practiceMembers === null)? <></>:
            <>
            { Object.keys(practiceMembers).length === 0 ?  <></> :  practiceMembers.includes("empty") ? <></> :
              <>
              {practiceMembers.map((item) => (
                <Profiles.User onClick={() => handleProfileClick(item)} key={item.user_id}
                  data-testid="user-profile">
                  {editMode ? <Profiles.OverlayPicture src={"edit_overlay"}></Profiles.OverlayPicture> : <></>}
                  <Profiles.Picture src={item.photoURL} />
                  <Profiles.Name>{item.name}</Profiles.Name>
                </Profiles.User>
                ))}
                </>
              }
            </>
          }
          {editMode ? 
              <Profiles.User onClick={() => handleNewUserClick()} key={"new_user"}
                data-testid="user-profile">
                {editMode ? <Profiles.OverlayPicture src={"edit_overlay"}></Profiles.OverlayPicture> : <></>}
                <Profiles.Picture src={"new_user"} />
                {user.user_type == "practice"? <Profiles.Name>New Practice Member...</Profiles.Name>:user.user_type == "lab"? <Profiles.Name>New Lab Member...</Profiles.Name>:<Profiles.Name>New Patient...</Profiles.Name>}
              </Profiles.User>
              :<></>
          }
          </Profiles.List>
      </Profiles>

      {editMode ? 
        <Profiles>
          <Profiles.Space></Profiles.Space>
          <Profiles.Space></Profiles.Space>
          <Profiles.Button onClick={() => handleDone()}>Done</Profiles.Button>
        </Profiles> 
        : <></>
      }

    </>
  );
}