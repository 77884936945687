export default function selectionFilterEditSites({ editedData }, orderby) {
  const sitesArr = [];
  
  try {
    // Create an array of unique values for the orderby field
    const uniqueValuesArr = [...new Set(editedData.map(data => data[orderby]))];

    // Sort unique values alphabetically
    uniqueValuesArr.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

    // Loop through unique values and push matching items to testsArr
    uniqueValuesArr.forEach(value => {
      const filteredItems = editedData.filter(item => item[orderby] === value);
      filteredItems.sort((a, b) => a.id.localeCompare(b.id, 'en', { sensitivity: 'base' }));
      sitesArr.push(...filteredItems);
    });

    return sitesArr;
  } catch(error) {
    return null;
  }
}