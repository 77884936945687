import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { collection, query, where, onSnapshot, getFirestore } from 'firebase/firestore';

export default function useDPatientPayLaterOrders(id) {

  const [patientPayLaterOrders, setPatientPayLaterOrders] = useState([]);
  const { firebase } = useContext(FirebaseContext);

  useEffect(() => {
    let unsubscribe = () => {}; 

    if (id !== undefined) {
        const db = getFirestore();
        const orderItemsRef = collection(db, "order_pay_later_items");
        const ordersQuery = query(orderItemsRef, where("user_id", "==", id));

        unsubscribe = onSnapshot(ordersQuery, (snapshot) => {
            let allOrders = snapshot.docs.map((ordersObj) => ({
                ...ordersObj.data(),
                docId: ordersObj.id,
            }));

            if (Object.keys(allOrders).length === 0) { 
                allOrders.push("empty");
            }
            setPatientPayLaterOrders(allOrders);  
        }, (error) => {
            console.log(error.message);
        });
    }

    return unsubscribe; // Cleanup function is the return 
}, [id]);

    /* useEffect(() => {
      let unsubscribe = () => {}; // Initialize an unsubscribe function with let

      if (id !== undefined) {
        
        const query = firebase
        .firestore()
        .collection("order_pay_later_items")
        .where("user_id", "==", id);

        unsubscribe = query.onSnapshot((snapshot) => {
          const allOrders = snapshot.docs.map((ordersObj) => ({
            ...ordersObj.data(),
            docId: ordersObj.id,
          }));
          
          if (Object.keys(allOrders).length === 0) {  //there were no orders, set the value accordingly
            allOrders.push("empty");
          }
          setPatientPayLaterOrders(allOrders);  //update content state of this component and re-render it, in this case we pass an array we received from the firebase snapshot
          }, (error) => {
            console.log(error.message);
          }
        );
      }
      return () => {
        // Clean up the listener when the component unmounts
        unsubscribe();
      };

    }, [id]); */

  return { patientPayLaterOrders };

}