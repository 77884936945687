import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function usePhlebotomists() {
  const [phlebotomists, setPhlebotomists] = useState([]); 
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    const phlebotomistsRef = collection(db, "users");
    const phlebotomistsQuery = query(phlebotomistsRef, where("user_role", "==", "phlebotomist"));

    getDocs(phlebotomistsQuery)
      .then((snapshot) => {
        const allPhlebotomists = snapshot.docs.map((phlebotomistObj) => ({
          ...phlebotomistObj.data(),
          docId: phlebotomistObj.id,
        }));

        // Conditionally add 'empty' if needed
        if (allPhlebotomists.length === 0) { 
          allPhlebotomists.push("empty"); 
        } 

        setPhlebotomists(allPhlebotomists);  
      })
      .catch((error) => {
        console.error(error.message); 
      });
  }, []); 

  return { phlebotomists }; 
}


/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

export default function usePhlebotomists() {
  const [phlebotomists, setPhlebotomists] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
      firebase
      .firestore()
      .collection("users").where("user_role", "==", 'phlebotomist')
      .get()
      .then((snapshot) => {
        const allPhlebotomists = snapshot.docs.map((dependantsObj) => ({
          ...dependantsObj.data(),
          docId: dependantsObj.id,
        }));
        
        if (Object.keys(allPhlebotomists).length === 0) {  //there were no dependants, set the value accordingly
          allPhlebotomists[0] = "empty";
        }
        setPhlebotomists(allPhlebotomists);  //update content state of this component and re-render it, in this case we pass an array we received from the firebase snapshot
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, []);

  return { phlebotomists };

} */