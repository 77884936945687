import React, { useEffect } from 'react';
import { Accordion, OptForm } from '../components';
import termsData from '../fixtures/footer_terms_of_use';
import {useHistory} from 'react-router-dom'; 

import { SinglePage, AllPages } from '../components';

export default function FooterPAIA({}) {

    const history = useHistory();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    
    return (
      <>
      <div className="ViewReport">
      {/*<h4>Single Page</h4>*/}
      {/* <AllPages pdf={samplePDF} /> */}
      <AllPages pdf={"https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/PAIA%20Manual%20EZPath%20R0.1.pdf?alt=media&token=3578bddf-17f3-4ed3-a1f8-52d5d42cc792"} />
      <hr />
      {/*<h4>All Pages</h4>
          <div className="all-page-container">
          <AllPages pdf={samplePDF} />
          </div>
      <hr />*/}
  </div>
  </>
      );
}