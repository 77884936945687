import React, { useState, useContext, createContext } from 'react';
//import { Link as ReachRouterLink } from 'react-router-dom';

import {
  ActionText,
  ButtonSpacer,
  CheckBox,
  Container,
  DividingLine,
  Group,
  Title,
  SubTitle,
  CloseTitle,
  Text,
  TestLine,
  Feature,
  FeatureTitle,
  FeatureText,
  FeatureClose,
  Maturity,
  Content,
  Meta,
  Entities,
  Heading,
  Input,
  Select,
  Item,
  Image,
  ButtonLink,
  Button,
  ActionItem,
  CompactActionItem,
  TextLink,
  LongContainer,
  ButtonContainer,
  PlainContainer,
  ButtonDownload,
  SmallInput,
} from './styles/results';

export const FeatureContext = createContext();

export default function Results({ children, ...restProps }) {
  const [showFeature, setShowFeature] = useState(false);
  const [itemFeature, setItemFeature] = useState({});

  return (
    <FeatureContext.Provider value={{ showFeature, setShowFeature, itemFeature, setItemFeature }}>
      <Container {...restProps}>{children}</Container>
    </FeatureContext.Provider>
  );
}

Results.Group = function ResultsGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};

Results.LongContainer = function ResultsLongContainer({ children, ...restProps }) {
  return <LongContainer {...restProps}>{children}</LongContainer>;
};

Results.PlainContainer = function ResultsPlainContainer({ children, ...restProps }) {
  return <PlainContainer {...restProps}>{children}</PlainContainer>;
};

Results.ButtonContainer = function ResultsButtonContainer({ children, ...restProps }) {
  return <ButtonContainer {...restProps}>{children}</ButtonContainer>;
};

Results.Title = function ResultsTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Results.SubTitle = function ResultsSubTitle({ children, ...restProps }) {
  return <SubTitle {...restProps}>{children}</SubTitle>;
};

Results.CloseTitle = function ResultsCloseTitle({ children, ...restProps }) {
  return <CloseTitle {...restProps}>{children}</CloseTitle>;
};

Results.Text = function ResultsText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Results.ActionText = function ResultsActionText({ children, ...restProps }) {
  return <ActionText {...restProps}>{children}</ActionText>;
};

Results.ButtonDownload = function ResultsButtonDownload({ children, ...restProps }) {
  return <ButtonDownload {...restProps}>{children}</ButtonDownload>;
};

Results.Button = function ResultsButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Results.TestLine = function ResultsTestLine({ children, ...restProps }) {
  return <TestLine {...restProps}>{children}</TestLine>;
};

Results.ButtonSpacer = function ResultsButtonSpacer({ children, ...restProps }) {
  return <ButtonSpacer {...restProps}>{children}</ButtonSpacer>;
};

Results.ActionItem = function ResultsActionItem({ children, ...restProps }) {
  return <ActionItem {...restProps}>{children}</ActionItem>;
};

Results.CompactActionItem = function ResultsCompactActionItem({ children, ...restProps }) {
  return <CompactActionItem {...restProps}>{children}</CompactActionItem>;
};

Results.Input = function ResultsInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>;
};

Results.SmallInput = function ResultsSmallInput({ children, ...restProps }) {
  return <SmallInput {...restProps}>{children}</SmallInput>;
};

/* Results.CheckBox = function ResultsCheckBox({ children, ...restProps }) {
  return <CheckBox {...restProps}>{children}</CheckBox>;
};
 */
Results.CheckBox = function ResultsCheckBox({ children, checked, onChange, ...restProps }) {
  return <CheckBox {...restProps}
            checked = {checked}
            onChange = {onChange}
          >{children}</CheckBox>;
};


Results.Select = function ResultsSelect({ children, ...restProps }) {
  return <Select {...restProps}>{children}</Select>;
};

Results.Entities = function ResultsEntities({ children, ...restProps }) {
  return <Entities {...restProps}>{children}</Entities>;
};

Results.Meta = function ResultsMeta({ children, ...restProps }) {
  return <Meta {...restProps}>{children}</Meta>;
};

Results.Heading = function ResultsHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Results.Item = function ResultsItem({ item, children, gradient, ...restProps }) {
  const { setShowFeature, setItemFeature } = useContext(FeatureContext);

  gradient = gradient % 6;

  /* const backgroundValue = [
    '#104632',
    '#0D6951',
    '#B33420',
    '#952027',
    '#69172F',
    '#255D6E']; */

   const backgroundValue = [
    'rgba(16, 70, 50, 0.8)',    // Equivalent to #104632 1
    'rgba(13, 105, 81, 0.8)',   // Equivalent to #0D6951 2
    'rgba(179, 52, 32, 0.8)',   // Equivalent to #B33420 3
    'rgba(149, 32, 39, 0.8)',   // Equivalent to #952027 4
    'rgba(105, 23, 47, 0.8)',   // Equivalent to #69172F 5
    'rgba(37, 93, 110, 0.8)'    // Equivalent to #255D6E 6
];

    // Define inline styles for background color and text color
    const itemStyle = {
      backgroundColor: backgroundValue[gradient],
      //color: textValue[gradient]
    };

  return (
    <Item
      onClick={() => {
        setItemFeature(item);
        setShowFeature(true);
      }}
      style={itemStyle}

      {...restProps}
    >
      {children}
    </Item>
  );
};

Results.ButtonLink = function ResultsButtonLink({ children, ...restProps }) {
  return <ButtonLink {...restProps}>{children}</ButtonLink>;
};

Results.TextLink = function ResultsTextLink({ children, ...restProps }) {
  return <TextLink {...restProps}>{children}</TextLink>;
};

Results.DividingLine = function ResultsDividingLine({ children, ...restProps }) {
  return <DividingLine {...restProps}>{children}</DividingLine>;
};

Results.Image = function ResultsImage({ ...restProps }) {
  return <Image {...restProps} />;
};

Results.Feature = function ResultsFeature({ children, category, ...restProps }) {
  const { showFeature, itemFeature, setShowFeature } = useContext(FeatureContext);

  return showFeature ? (
    <Feature {...restProps} src={`/images/${category}/${itemFeature.genre}/${itemFeature.slug}/large.jpg`}>
      <Content>
        <FeatureTitle>{itemFeature.title}</FeatureTitle>
        <FeatureText>{itemFeature.description}</FeatureText>
        <FeatureClose onClick={() => setShowFeature(false)}>
          <img src="/images/icons/close.png" alt="Close" />
        </FeatureClose>

        <Group margin="30px 0" flexDirection="row" alignItems="center">
          <Maturity rating={itemFeature.maturity}>{itemFeature.maturity < 12 ? 'PG' : itemFeature.maturity}</Maturity>
          <FeatureText fontWeight="bold">
            {itemFeature.genre.charAt(0).toUpperCase() + itemFeature.genre.slice(1)}
          </FeatureText>
        </Group>

        {children}
      </Content>
    </Feature>
  ) : null;
};