import React, { useEffect } from 'react';
import {useHistory} from 'react-router-dom'; 

import {AllPages } from '../components';

export default function FooterPrivacy({}) {

    const history = useHistory();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    
    return (
      <>
      <div className="ViewReport">
      {/*<h4>Single Page</h4>*/}
      {/* <AllPages pdf={samplePDF} /> */}
      <AllPages pdf={"https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Privacy%20Policy%20R1.0.pdf?alt=media&token=0c4fef55-1c56-4b0c-b311-e5d1516ff39a"} />
      <hr />
      {/*<h4>All Pages</h4>
          <div className="all-page-container">
          <AllPages pdf={samplePDF} />
          </div>
      <hr />*/}
  </div>
  </>
      );
}