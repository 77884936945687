import React, { useState, useContext, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { Form, Profiles } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import * as ROUTES from '../constants/routes';
import { getFirestore, collection, addDoc } from 'firebase/firestore';


export function PatientNewContainer({user}) {
  const history = useHistory();
  const [userData, setUserData, editProfile, setEditProfile] = useContext(UserContext);
  const [error, setError] = useState('');
  const photo_URL = (editProfile?.photoURL && editProfile.photoURL !== "") ? editProfile.photoURL 
  : Math.floor(Math.random() * 10) + 1; //random from a limited set of avatars to start with 


  const editAvatar = (event) => {
    //newUser.photoURL = photo_URL;
    //newUser.user_type = "patient";
    //newUser.edit_type = "new_user";
    //setEditProfile(newUser);
    history.push(ROUTES.SELECT_AVATAR);
  }

  useEffect(() => {

    if (editProfile && userData.user_type === "patient") {   //we are returning to the page, no need to populate default values
  
      var editProfileLoc = ({});

      editProfileLoc.user_type = "patient";
      editProfileLoc.edit_type = "new_user";
      editProfileLoc.name = "";
      editProfileLoc.surname = "";
      editProfileLoc.national_id = "";
      editProfileLoc.mobile_number = userData.mobile_number;
      editProfileLoc.medaid_name = userData.medaid_name;
      editProfileLoc.medaid_number = userData.medaid_number;
      editProfileLoc.medaid_plan = userData.medaid_plan;
      editProfileLoc.email_address = userData.email_address;
      editProfileLoc.photoURL = photo_URL;

      setEditProfile(editProfileLoc);
    }    
  }, [])

  function getUUID() {
    // eslint gets funny about bitwise
    /* eslint-disable */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const piece = (Math.random() * 16) | 0;
        const elem = c === 'x' ? piece : (piece & 0x3) | 0x8;
        return elem.toString(16);
    });
    /* eslint-enable */
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEditProfile({ ...editProfile, [id]: value });
    //setOrderInfoLocKey(prevKey => prevKey + 1);
};

const handleNewPatient = async (event) => { // Mark the function as async
  event.preventDefault();

    //var doc_id = userData.docId;
    var name = document.getElementById('name').value;
    var surname = document.getElementById('surname').value;
    var national_id = document.getElementById('national_id').value;
    var medaid_number = document.getElementById('medaid_number').value;
    var medaid_plan = document.getElementById('medaid_plan').value;
    var medaid_name = document.getElementById('medaid_name').value;
    var mobile_number = document.getElementById('mobile_number').value;
    var email_address = document.getElementById('email_address').value;
    var principal_id_1 = userData.user_id;

    
    try {
      const db = getFirestore();
      const usersCollectionRef = collection(db, "users");

      const userData = {
          displayName: name,
          email_address: email_address,
          medaid_name: medaid_name,
          medaid_number: medaid_number,
          medaid_plan: medaid_plan,
          mobile_number: mobile_number,
          name: name,
          surname: surname,
          national_id: national_id,
          photoURL: photo_URL, 
          principal_id_1: principal_id_1,
          principal_id_2: "",
          user_id: getUUID(), 
          user_type: "patient"
      }
      
      const docRef = await addDoc(usersCollectionRef, userData);
      console.log("Document written with ID: ", docRef.id);

      } catch (error) {
          console.error("Create New Patient Error: write to Firestore - ", error);
          //todo: display error message to end user here
      }
      history.goBack();
  }


  const handleBack = (event) => {
    event.preventDefault();

    setEditProfile({});
    history.goBack();
  }


  return (
    <>
      <HeaderContainer>
        <Form>
        <Form.BackButton onClick={() => handleBack()} >Back</Form.BackButton>
        <Form.LongContainer>
          <Form.Title>New User Details</Form.Title>
          <Profiles.User>
            <Profiles.OverlayPicture src={"edit_overlay"} onClick={() => editAvatar()}></Profiles.OverlayPicture> 
            <Profiles.Picture src={photo_URL}></Profiles.Picture>
          </Profiles.User>
        </Form.LongContainer>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}
          <Form.Base onSubmit={handleNewPatient} method="POST">
            <Form.Input
              placeholder="Name"
              /* defaultValue={""} */
              value = {editProfile.name}
              id = "name"
              onChange={handleChange}
            />
            <Form.Input
              placeholder="Surname"
              /* defaultValue={""} */
              value = {editProfile.surname}
              id = "surname"
              onChange={handleChange}
            />
            <Form.Input
              placeholder="Identity Number"
              /* defaultValue={""} */
              value = {editProfile.national_id}
              id = "national_id"
              onChange={handleChange}
            />
            <Form.Input
              placeholder="Mobile Number"
              /* defaultValue={userData?.mobile_number} */
              value = {editProfile.mobile_number}
              id = "mobile_number"
              onChange={handleChange}
            />
            {userData.user_type !== "lab"?
              <>
                <Form.Input
                  placeholder="Medical Aid"
                  /* defaultValue={userData?.medaid_name} */
                  value = {editProfile.medaid_name}
                  id = "medaid_name"
                  onChange={handleChange}
                />
              </>
              :
              <></>}
            {userData.user_type !== "lab"?
            <>
              <Form.Input
                placeholder="Medical Aid Number"
                /* defaultValue={userData?.medaid_number} */
                value = {editProfile.medaid_number}
                id = "medaid_number"
                onChange={handleChange}
              />
            </>
            :
              <></>
            }
            {userData.user_type !== "lab"?
            <>
              <Form.Input
                placeholder="Medical Aid Plan"
                /* defaultValue={userData?.medaid_plan} */
                value = {editProfile.medaid_plan}
                id = "medaid_plan"
                onChange={handleChange}
              />
              </>
              :
                <></>
            }
            <Form.Input
              placeholder="Email address"
              /* defaultValue={userData?.email_address} */
              value = {editProfile.email_address}
              id = "email_address"
              onChange={handleChange}
            />
            {/* <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              id = "password"
            /> */}
            <Form.Button type="submit" > {/*onclick={handleUpdate}*/}
              Create New Patient
            </Form.Button>
          </Form.Base>
        </Form>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}