import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from "../context/UserContext";
import { FirebaseContext } from '../context/firebase';

import { Form } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import * as ROUTES from '../constants/routes';

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, where, query, getDocs } from "firebase/firestore";

export default function SignIn() {
  const history = useHistory();
  const { firebase } = useContext(FirebaseContext);
  const auth = getAuth();
  const firestore = getFirestore();
  //const [userData, setUserData, signinEmail] = useContext(UserContext);
  const [userData, setUserData] = useContext(UserContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const isInvalid = password === '' || emailAddress === '';
  
  useEffect(() => {
/*     if (Object.keys(signinEmail).length !== 0) {
      setEmailAddress(signinEmail);
    } */
    
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    if (success === "successful") {
      history.push(ROUTES.HOME);
    }
  }, [success]);

  const handleSignin = (event) => {
    event.preventDefault();
    
    
     return signInWithEmailAndPassword(auth, emailAddress, password)
              .then(() => {
                console.log("signin page: SUCCESSFUL FIREBASE SIGNIN");
                const authUser = auth.currentUser; 
                const uid2 = authUser.uid;
                const usersCollectionRef = collection(firestore, 'users');
                const conditionalQuery = query(usersCollectionRef, where("user_id", "==", uid2));
                getDocs(conditionalQuery)
                  /* .collection("users").where("user_id", "==", uid2)
                  .get() */
                  .then((snapshot) => {
                    const userInfo = snapshot.docs.map((resultsObj) => ({
                      ...resultsObj.data(),
                      docId: resultsObj.id,
                    }));
                    setUserData(userInfo);
                    setSuccess("successful")
                    //localStorage.setItem('authUser', JSON.stringify(authUser));
                    //localStorage.setItem('userDetails', JSON.stringify(userDetails));
                    //console.log("USER DETAILS updated after signin");
                  })
                  .catch((error) => {
                    console.log(error.message);
                  })
              })
              .catch((error) => {
                setEmailAddress('');
                setPassword('');
                setError(error.message);
              }) 
    }

  return (
    <>
      <HeaderContainer>
        <Form>
          <Form.BackButton onClick={() => history.goBack()}>
            Back
          </Form.BackButton>
          <Form.Title>Sign In</Form.Title>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}

          <Form.Base onSubmit={handleSignin} method="POST">
            <Form.Input
              placeholder="Email address"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              onChange={({ target }) => setPassword(target.value)}
            />
            <Form.Button disabled={isInvalid} type="submit" data-testid="sign-in">
              Sign In
            </Form.Button>
          </Form.Base>
          <Form.TextRight>
          <Form.Link to="/signin_help">Need Help?</Form.Link>
          </Form.TextRight>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
            <Form.Link to="/signup">New to EZPath? Sign up now.</Form.Link>
        </Form>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}