import styled from 'styled-components/macro';

export const ContactContainer = styled.div`
    display: flex;
    padding: 70px 45px;
    flex-direction: column;
    max-width: 815px;
    margin: auto;
    height: 100%;
    
    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
    }    
`;
  
export const MapContainer = styled.div`
    display: flex;
    margin-right: 5vw;
    margin-left: 5vw;
    `;
  
export const ContactInfo = styled.div`
    display: flex;
`;

export const ContactFrame = styled.iframe`
  width: 80vw; 
  height: 60vh; 
  border: none; 

@media (max-width: 600px) {
    width: 90vw; 
    height: 40vh; 
  }
`;
