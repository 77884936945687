import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';

import { Form } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import * as ROUTES from '../constants/routes';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function ValidateEmail(mail) 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (false)
  }
    //alert("You have entered an invalid email address!")
    return (true)
}

export default function SignInHelp() {
  const history = useHistory();
  const { firebase } = useContext(FirebaseContext);
  const [emailAddress, setEmailAddress] = useState('');
  const [message, setMessage] = useState('');
  const isInvalid = ValidateEmail(emailAddress);
  const auth = getAuth();
  
  /* const handlePasswordReset = (event) => {
    event.preventDefault();

    return auth
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                console.log("PASSWORD RESET EMAIL SENT");
                setMessage("An email has been sent with instructions to reset your password.")
                setEmailAddress("");
            })
            .catch((error) => {
                console.log(error.message);
                setMessage(error.message);
            });
    }
 */

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    try {
      await sendPasswordResetEmail(auth, emailAddress);
      console.log("PASSWORD RESET EMAIL SENT");
      setMessage("An email has been sent with instructions to reset your password.");
      setEmailAddress(""); // Clear the email field
    } catch (error) {
      console.error(error.message);
      setMessage(error.message);
    }
  };


  return (
    <>
      <HeaderContainer>
        <Form>
          <Form.BackButton onClick={() => history.goBack()}>
            Back
          </Form.BackButton>
          <Form.Title>Forgot Email or Password</Form.Title>
          <Form.Text>
            Reset your password by entering your email address below:
          </Form.Text>
          {message && <Form.Error data-testid="message">{message}</Form.Error>}
          <Form.Base onSubmit={handlePasswordReset} method="POST">
            <Form.Input
              placeholder="Email address"
              value={emailAddress}
              onChange={({ target }) => setEmailAddress(target.value)}
            />
            <Form.Button disabled={isInvalid} type="submit" data-testid="email-me">
              Email Me
            </Form.Button>
          </Form.Base>
          <Form.Space></Form.Space>
          <Form.Link to="/footer_contact_us">I can't remember my signin email address.</Form.Link>
        </Form>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}