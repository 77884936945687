import { useEffect, useState, useContext } from 'react';
// import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function usePractices() {
  const [practices, setPractices] = useState([]); 
 //  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    const practicesRef = collection(db, "users");
    const practicesQuery = query(practicesRef, where("user_role", "==", "practice"));

    getDocs(practicesQuery)
      .then((snapshot) => {
        const allPractices = snapshot.docs.map((practiceObj) => ({
          ...practiceObj.data(),
          docId: practiceObj.id,
        }));

        // Conditionally add 'empty' if needed
        if (allPractices.length === 0) { 
          allPractices.push("empty"); 
        } 

        setPractices(allPractices);  
      })
      .catch((error) => {
        console.error(error.message); 
      });
  }, []);

  return { practices };
}


/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

export default function usePractices() {
  const [practices, setPractices] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
      firebase
      .firestore()
      .collection("users").where("user_role", "==", 'practice')
      .get()
      .then((snapshot) => {
        const allPractices = snapshot.docs.map((practiceObj) => ({
          ...practiceObj.data(),
          docId: practiceObj.id,
        }));
        
        if (Object.keys(allPractices).length === 0) {  //there were no practice, set the value accordingly
          allPractices[0] = "empty";
        }
        setPractices(allPractices);  //update content state of this component and re-render it, in this case we pass an array we received from the firebase snapshot
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, []);

  return { practices };

} */