import { firebase } from '../lib/firebase.prod';
import { getFirestore } from "firebase/firestore";

export default async function movePloItemDiscarded(doc_id) {
  const firestore = getFirestore(); 
  try {
    // Reference the document directly by its ID (which is the doc_id)
    const documentRef = firebase.firestore().collection('order_pay_later_items').doc(doc_id);
    
    // Get the document based on its ID
    const docSnapshot = await documentRef.get();

    // Check if the document exists
    if (!docSnapshot.exists) {
      console.log('Document not found based on the provided document ID.');
      return;
    }

    // Move the document to discarded_items
    const batch = firebase.firestore().batch();
    const data = docSnapshot.data();
    batch.set(firebase.firestore().collection('discarded_items').doc(doc_id), data);
    batch.delete(documentRef); // Delete the document from order_pay_later_items

    // Commit the batch operation
    await batch.commit();

    console.log('Document moved and deleted successfully.');
  } catch (error) {
    console.error('Error:', error);
  }
}
