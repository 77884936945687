import React from 'react';
import { Background, Container, FittedContainer, CloseButton, Button, LongContainer, LeftText, Heading, Space} from './styles/printable';


  export default function Printable({ children, ...restProps }) {
    return restProps.trigger ? (
      restProps.fullScreen ? (
        <Background {...restProps}>{children}</Background>
      ) : (
        <Background>
          <FittedContainer {...restProps}>{children}</FittedContainer>
        </Background>
      )
    ) : (
      <></>
    );
  }

  Printable.Space = function PrintableSpace({ children, ...restProps }) {
    return <Space {...restProps}>{children}</Space>;
  };

  Printable.Button = function PrintableButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
  };

  Printable.LongContainer = function PrintableLongContainer({ children, ...restProps }) {
    return <LongContainer {...restProps}>{children}</LongContainer>;
  };

Printable.Background = function PrintableBackground({ children, ...restProps }) {
  return <Background {...restProps}>{children}</Background>;
};

Printable.Container = function PrintableContainer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
};

Printable.LeftText = function PrintableLeftText({ children, ...restProps }) {
  return <LeftText {...restProps}>{children}</LeftText>;
};

Printable.Heading = function PrintableHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};


Printable.CloseButton = function PrintableCloseButton({ children, ...restProps }) {
    return (
      <CloseButton onClick={() => {
        restProps.setTrigger(false);
      }}
      {...restProps}>{children}
      </CloseButton>
    );
  };
  
