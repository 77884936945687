import React, { useState, useContext, createContext } from 'react';
import { Button, ButtonContainer, ButtonSpacer, Container, RightText, ColumnLabel, LeftText} from './styles/edit-table';

const ToggleContext = createContext();

export default function EditTable({ children, ...restProps }) {
  return (
    <Container {...restProps}>{children}</Container>
    //<Container {...restProps}>
    //  <Inner>{children}</Inner>
    //</Container>
  );
}

EditTable.RightText = function EditTableRightText({ children, ...restProps }) {
  return <RightText {...restProps}>{children}</RightText>;
};

EditTable.LeftText = function EditTableLeftText({ children, ...restProps }) {
  return <LeftText {...restProps}>{children}</LeftText>;
};

EditTable.ColumnLabel = function EditTableColumnLabel({ children, width, ...restProps }) {
  // Define inline styles for background color and text color
  const itemStyle = {
    width: [width],
  };
  
  return <ColumnLabel 
            style={itemStyle}
            {...restProps}>
            {children}
          </ColumnLabel>;
};

EditTable.Button = function EditTableButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

EditTable.ButtonContainer = function EditTableButtonContainer({ children, ...restProps }) {
  return <ButtonContainer {...restProps}>{children}</ButtonContainer>;
};

EditTable.ButtonSpacer = function EditTableButtonSpacer({ children, ...restProps }) {
  return <ButtonSpacer {...restProps}>{children}</ButtonSpacer>;
};

