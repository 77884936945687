import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

export default function useEditTests(target, refresh) {
  const [tests, setTests] = useState([]);
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    const testsRef = collection(db, target);

    getDocs(testsRef)
      .then((snapshot) => {
        const allTests = snapshot.docs.map((testsObj) => ({
          ...testsObj.data(),
          docId: testsObj.id
        }));
        setTests(allTests);
      })
      .catch((error) => {
        console.error(error.message);  
      });
  }, [refresh]); 

  return { [target]: tests }; 
}


/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context';

export default function useEditTests(target, refresh) {
  const [tests, setTests] = useState([]);
  const { firebase } = useContext(FirebaseContext);
  
  useEffect(() => {
    firebase
      .firestore()
      .collection(target)
      .get()
      .then((snapshot) => {
        const allTests = snapshot.docs.map((testsObj) => ({
          ...testsObj.data(),
          docId: testsObj.id,
        }));

        setTests(allTests);
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, [refresh]);
  
    //  console.log('use-tests from firestore', tests);
    return { [target]: tests };
  } */