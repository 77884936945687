import React from 'react';
import { Header } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';

export function HeaderContainer({ children }) {
  return (
    <Header>
      <Header.Frame>
        <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
        <Header.Group>
          {/* <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test</Header.ButtonLink> */}
          <Header.ButtonLink to={ROUTES.SIGN_IN}>Sign In</Header.ButtonLink>
        </Header.Group>
      </Header.Frame>
      {children}
    </Header>
  );
}
