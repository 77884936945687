export default function selectionFilterTests({ tests } = []) {
  // Extract unique category names from tests data and sort alphabetically
  const uniqueCategories = [...new Set(tests?.map((item) => item.category))];
  uniqueCategories.sort();

  // Create filtered data for each category and sort data alphabetically within each category
  const filteredData = uniqueCategories.map((category) => ({
      category,
      data: tests?.filter((item) => item.category === category).sort((a, b) => {
          const nameA = a.long_name.toUpperCase();
          const nameB = b.long_name.toUpperCase();
          if (nameA < nameB) {
              return -1;
          }
          if (nameA > nameB) {
              return 1;
          }
          return 0;
      }),
  }));

  return { filteredData };
}
