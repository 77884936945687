
import { useEffect, useState, useContext } from 'react';
import { getFirestore } from "firebase/firestore";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export default function useLabMembers(id) {
  const [labMembers, setLabMembers] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    const usersRef = collection(firestore, "users");
    const labMembersQuery = query(usersRef, where("user_type", "==", "lab"));

    const unsubscribe = onSnapshot(labMembersQuery, (snapshot) => { 
    const allLabMembers = snapshot.docs.map(labMembersDoc => ({
        ...labMembersDoc.data(),
        docId: labMembersDoc.id
    }));

    // remove the current user from the members list
    for (const key of Object.keys(allLabMembers)) {
      if (allLabMembers[key].user_id === id) {
        delete allLabMembers[key];
        break;
      }
    }
    setLabMembers(allLabMembers);
    }, (error) => { 
      console.log(error.message);
    });

    return unsubscribe; // Cleanup function is the return 
  }, [firestore]); 
  
  return { labMembers };
}
