import { useEffect, useState, useContext } from 'react';
// import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function usePracticeMembers(practice_id) {
  const [practiceMembers, setPracticeMembers] = useState([]); 
  /// const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    const membersRef = collection(db, "users");
    const membersQuery = query(membersRef, where("practice_id", "==", practice_id));

    getDocs(membersQuery)
      .then((snapshot) => {
        const allPracticeMembers = snapshot.docs.map((practiceMembersObj) => ({
          ...practiceMembersObj.data(),
          docId: practiceMembersObj.id,
        }));

        // Conditionally add 'empty' if needed
        if (allPracticeMembers.length === 0) { 
          allPracticeMembers.push("empty"); 
        } 

        setPracticeMembers(allPracticeMembers);  
      })
      .catch((error) => {
        console.error(error.message); 
      });
  }, [practice_id]); // Add practice_id to the dependency array 

  return { practiceMembers }; 
}

/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { UserContext } from '../context/UserContext'; // Assuming this is your PracticeContext


export default function usePracticeMembers(practice_id) {
  const [practiceMembers, setPracticeMembers] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);
  //const { setPracticeMembers } = useContext(UserContext);

    useEffect(() => {
      firebase
      .firestore()
      .collection("users").where("practice_id", "==", practice_id)
      .get()
      .then((snapshot) => {
        const allPracticeMembers = snapshot.docs.map((practiceMembersObj) => ({
          ...practiceMembersObj.data(),
          docId: practiceMembersObj.id,
        }));
        
        if (Object.keys(allPracticeMembers).length === 0) {  //there were no practice members, set the value accordingly
          allPracticeMembers[0] = "empty";
        }
        setPracticeMembers(allPracticeMembers);  
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, []);

  return { practiceMembers };
  //return{};

} */