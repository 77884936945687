import { firebase } from '../lib/firebase.prod';
import { getFirestore } from "firebase/firestore";

export default function writeStatusUpdate(source, doc_id, new_status, user_id, user_name, user_surname, data1, data2, data3, m_payment_id) {
    const firestore = getFirestore(); 

    if (new_status === "dispatched") {
        firebase.firestore().collection(source)
            .where('m_payment_id', '==', m_payment_id)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                        status: new_status,
                        dispatched_by_id: user_id,
                        dispatched_by_name: user_name,
                        dispatched_by_surname: user_surname,
                        dispatched_time: new Date(),
                        phlebotomist_name: data2,
                        phlebotomist_id: data1,
                    });
                });
            })
            .catch((error) => {
                console.log("Order Status Update to Dispatched Write to Firestore Error - ", error);
            });
    }
    if (new_status === "sampled") {
        firebase.firestore().collection(source)
            .where('m_payment_id', '==', m_payment_id)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.update({
                        status: new_status,
                        sampled_by_id: user_id,
                        sampled_by_name: user_name,
                        sampled_by_surname: user_surname,
                        sampled_time: new Date(),
                        requisition_number: data1,
                        yoco_id: data2,
                        yoco_amount: data3,
                        yoco_date: new Date(),
                    });
                });
            })
            .catch((error) => {
                console.log("Order Status Update to Sampled Write to Firestore Error - ", error);
            });
    }    
    if (new_status === "received") {
        if (source === "order_pay_later_items") {
            firebase.firestore().collection("order_pay_later_items")
                .where('m_payment_id', '==', m_payment_id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const sourceData = doc.data();
                        const transferDocRef = firebase.firestore().collection("order_items").doc();
    
                        // Combine both sourceData and additional fields in a single set call
                        transferDocRef.set({
                            ...sourceData,
                            status: new_status,
                            received_by_id: user_id,
                            received_by_name: user_name,
                            received_by_surname: user_surname,
                            received_time: new Date(),
                            requisition_number: data1,
                            yoco_id: data2,
                            yoco_amount: data3,
                            yoco_date: new Date(),  // write the current date when doing a quick update, at least it gives us an approximate range to work with
                        });
    
                        // Delete the original document
                        firebase.firestore().collection("order_pay_later_items").doc(doc.id).delete()
                            .then(() => {
                                console.log("Document copied and deleted successfully!");
                            })
                            .catch((error) => {
                                console.error("Error deleting original document: ", error);
                            });
                    });
                })
                .catch((error) => {
                    console.error("Error copying or deleting document: ", error);
                });
        }
        else
        {
            firebase.firestore().collection("order_pay_later_items")
                .where('m_payment_id', '==', m_payment_id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.update({
                            status: new_status,
                            received_by_id: user_id,
                            received_by_name: user_name,
                            received_by_surname: user_surname,
                            received_time: new Date(),
                            requisition_number: data1,
                            yoco_id: data2,
                            yoco_amount: data3,
                            yoco_date: new Date(),
                        });
                    });
                })
                .catch((error) => {
                    console.log("Order Status Update to Received Write to Firestore Error - ", error);
                });
        }
    }
}