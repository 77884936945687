import { PDFDocument } from 'pdf-lib';

// Format components directly using toLocaleString
const dateOptions = {
  timeZone: "Africa/Johannesburg",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
};

export async function createOrderSummaryForm(info, selectedTests, formattedSelectSubTotal, formattedSelectVatAmount, formattedSelectTotal, siteId) {
  var downloadUrl = "https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Order%20Summary%2Forder_summary_gerdner_0_v1.pdf?alt=media&token=fd4a7d4e-5cbe-41e9-a897-076f718badff";   
  if (siteId === "3") {
    downloadUrl = "https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Order%20Summary%2Forder_summary_gerdner_3_v1.pdf?alt=media&token=41e75e04-2e36-4bf6-816e-6abf9c5f7bf0";
    }
  else if (siteId === "7") {
    downloadUrl = "https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Order%20Summary%2Forder_summary_gerdner_7_v1.pdf?alt=media&token=9c5d1881-06e4-4db0-96b0-4ad4d2d2d84b";
  }
    
    let response = {};
    try {
      response = await fetch(downloadUrl);
      if (!response.ok) {
          throw new Error(`Network error: ${response.status}`);
      }
      // ... rest of your code
   } catch(error) {
      console.error("Error fetching PDF:", error);
   }
    const pdfBytes = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);
    let form = pdfDoc.getForm();

    // Patient Details
    
    await fillPdfTextField(form, "ezDate", new Date().toLocaleString("en-ZA", dateOptions));

    if (info.name) {
      await fillPdfTextField(form, "ezPatientName", info.name);
    }
    if (info.surname) {
      await fillPdfTextField(form, "ezPatientSurname", info.surname);
    }
    if (info.national_id) {
      await fillPdfTextField(form, "ezPatientId", info.national_id);
    }
    let comms_preference = "";
    if (info.notify_patient_sms  === "true") {
      comms_preference = comms_preference + "SMS   ";
    }
    if (info.notify_patient_whatsapp === "true") {
      comms_preference = comms_preference + "WhatsApp   ";
    }
    if (info.notify_patient_email  === "true") {
      comms_preference = comms_preference + "Email";
    }
    await fillPdfTextField(form, "ezPatientComms", comms_preference);
    if (info.email_address) {
      await fillPdfTextField(form, "ezPatientEmail", info.email_address);
    }
    if (info.mobile_number) {
      await fillPdfTextField(form, "ezPatientMobile", info.mobile_number);
    }
    if (info.icd_10_code) {
      await fillPdfTextField(form, "ezIcd10", info.icd_10_code);
    }

    //Doctor Details
    if (info.doctors_first_name) {
      await fillPdfTextField(form, "ezDoctorName", info.doctors_first_name);
    }
    if (info.doctors_surname) {
      await fillPdfTextField(form, "ezDoctorSurname", info.doctors_surname);
    }
    if (info.doctors_practice_name) {
      await fillPdfTextField(form, "ezPracticeName", info.doctors_practice_name);
    }
    if (info.doctors_practice_number) {
      await fillPdfTextField(form, "ezPracticeNumber", info.doctors_practice_number);
    }
    if (info.doctors_email_address) {
      await fillPdfTextField(form, "ezDoctorEmail", info.doctors_email_address);
    }
    if (info.doctors_telephone) {
      await fillPdfTextField(form, "ezDoctorTel", info.doctors_telephone);
    }
    for (var testIndex = 1; testIndex <= selectedTests.length; testIndex++) {
      await fillPdfTextField(form, `ezTariff${testIndex}`, selectedTests[testIndex-1].tariff_code);
      await fillPdfTextField(form, `ezTestName${testIndex}`, selectedTests[testIndex-1].long_name);
      await fillPdfTextField(form, `ezCategory${testIndex}`, selectedTests[testIndex-1].category);
      await fillPdfTextField(form, `ezAmount${testIndex}`, selectedTests[testIndex-1].reduced_price);
    }
    await fillPdfTextField(form, `ezTotal1`, "Subtotal: " + formattedSelectSubTotal + " ");
    await fillPdfTextField(form, `ezTotal2`, "VAT:  " + formattedSelectVatAmount + " ");
    await fillPdfTextField(form, `ezTotal3`, "Total: " + formattedSelectTotal + " ");
    form.flatten();
  
    // Trigger download
    downloadAndOpenPDF(pdfDoc, "filled_form.pdf");
  }

  async function downloadAndOpenPDF(pdfDoc) {
    // 1. Save PDF bytes from pdfDoc
    const pdfBytes = await pdfDoc.save();
  
    // 2. Create a Blob
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
  
    // 3. Open in a new tab
    window.open(blobURL, '_blank');
  
    // 4. (Optional) Revoke the object URL
    setTimeout(() => URL.revokeObjectURL(blobURL), 5000); 
  }


function download(data, filename, type) {
    const file = new Blob([data], { type: type });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href); // Clean up
  }

  // Function to find and fill text field in PDF
async function fillPdfTextField(form, fieldName, value) {
  if (value === null || value === undefined) {
    console.log(`Value for text field "${fieldName}"
      is null or undefined. Skipping this field.`);
    return;
  }

  const field = form.getTextField(fieldName);
  if (!field) {
    // throw new Error(`Text field "${fieldName}" not found`);
    console.log(`Text field "${fieldName}" not found`);
  }
  field.setText(value);
  // return pdfDoc;
  return;
}


export async function createLabRecordForm(orderInfo, testSet) {
  const downloadUrl = "https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Lab%20Record%2Forder_labrecord_gerdner_v1.01.pdf?alt=media&token=beb38994-343a-4d6f-9f6d-71689f0f0759";
  let response = {};
  try {
      response = await fetch(downloadUrl);
      if (!response.ok) {
          throw new Error(`Network error: ${response.status}`);
      }
      // ... rest of your code
  } catch (error) {
      console.error("Error fetching PDF:", error);
  }
  const pdfBytes = await response.arrayBuffer();
  const pdfDoc = await PDFDocument.load(pdfBytes);
  let newPdfDoc = await PDFDocument.create();
  let form = pdfDoc.getForm();

  const testsPerPage = 30; // Maximum tests per page

  // Patient Details
  // await fillPdfTextField(form, "ezDate", new Date().toLocaleString("en-ZA", dateOptions));
  if (orderInfo.sample_time) {
    await fillPdfTextField(form, "ezDate", orderInfo.sample_time.toDate().toLocaleString("en-ZA", dateOptions));
  }

  if (orderInfo.phlebotomy_notes) {
    let notes = "Notes: " + orderInfo.phlebotomy_notes;
    await fillPdfTextField(form, "ezNotes", notes);
  }

  if (orderInfo.requisition_number) {
    await fillPdfTextField(form, "ezRequisitionTop", orderInfo.requisition_number);
    await fillPdfTextField(form, "ezRequisition", orderInfo.requisition_number);
  }
  else {
    await fillPdfTextField(form, "ezRequisitionTop", "Requisition missing!");
    await fillPdfTextField(form, "ezRequisition", "Requisition missing!");
  }
  if (orderInfo.phlebotomist_name) {
    await fillPdfTextField(form, "ezPhlebotomist", orderInfo.phlebotomist_name);
  }
  if (orderInfo.m_payment_id) {
    await fillPdfTextField(form, "ezPaymentRef", orderInfo.m_payment_id);
  }
  if (orderInfo.icd_10_code) {
    await fillPdfTextField(form, "ezICD10", orderInfo.icd_10_code);
  }
  if (orderInfo.patient_name) {
    await fillPdfTextField(form, "ezPatientName", orderInfo.patient_name);
  }
  if (orderInfo.patient_surname) {
    await fillPdfTextField(form, "ezPatientSurname", orderInfo.patient_surname);
  }
  if (orderInfo.patient_national_id) {
    await fillPdfTextField(form, "ezPatientId", orderInfo.patient_national_id);
  }
  let comms_preference = "";
  if (orderInfo.notify_patient_sms  === "true") {
    comms_preference = comms_preference + "SMS   ";
  }
  if (orderInfo.notify_patient_whatsapp === "true") {
    comms_preference = comms_preference + "WhatsApp   ";
  }
  if (orderInfo.notify_patient_email  === "true") {
    comms_preference = comms_preference + "Email";
  }
  await fillPdfTextField(form, "ezPatientComms", comms_preference);
  if (orderInfo.email_address) {
    await fillPdfTextField(form, "ezPatientEmail", orderInfo.email_address);
  }
  if (orderInfo.mobile_number) {
    await fillPdfTextField(form, "ezPatientMobile", orderInfo.mobile_number);
  }

  //Doctor Details
  if (orderInfo.doctors_first_name) {
    await fillPdfTextField(form, "ezDoctorName", orderInfo.doctors_first_name);
  }
  if (orderInfo.doctors_surname) {
    await fillPdfTextField(form, "ezDoctorSurname", orderInfo.doctors_surname);
  }
  if (orderInfo.doctors_practice_name) {
    await fillPdfTextField(form, "ezPracticeName", orderInfo.doctors_practice_name);
  }
  if (orderInfo.doctors_practice_number) {
    await fillPdfTextField(form, "ezPracticeNumber", orderInfo.doctors_practice_number);
  }
  if (orderInfo.doctors_email_address) {
    await fillPdfTextField(form, "ezDoctorEmail", orderInfo.doctors_email_address);
  }
  if (orderInfo.doctors_telephone) {
    await fillPdfTextField(form, "ezDoctorTel", orderInfo.doctors_telephone);
  }

  const totalPages = Math.ceil(testSet.length / testsPerPage); 
    let currentPage = 1;

    for (let i = 0; i < testSet.length; i++) {
        const testIndexOnPage = i % testsPerPage + 1; // Calculate position on the page

        await fillPdfTextField(form, `ezTariff${testIndexOnPage}`, testSet[i].tariff_code);
        await fillPdfTextField(form, `ezTestName${testIndexOnPage}`, testSet[i].test_name);
        await fillPdfTextField(form, `ezCategory${testIndexOnPage}`, testSet[i].test_category);

        if (testIndexOnPage === testsPerPage || i === testSet.length - 1) {
            // End of page or last test
            await fillPdfTextField(form, "ezPageNumber", `Page ${currentPage} of ${totalPages}`);
            form.flatten();
            const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
            newPdfDoc.addPage(copiedPage);

            currentPage++;

            // If there are more tests, load a new page
            if (i < testSet.length - 1) {
                form = pdfDoc.getForm(); // Reset form to the original template
            }
        }
    }

  // Trigger download
  //download(pdfBytes, "filled_form.pdf", "application/pdf"); 
  downloadAndOpenPDF(pdfDoc, "filled_form.pdf");
}