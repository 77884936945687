import React, {useState} from 'react';
import { ActionButton, Background, Button, CardText, Caption, CaptionLeft, CheckBox, CloseButton, ConfirmButton, Container, DividingLine, FittedBackground, 
  FittedContainer, Group, Input, InputContainer, Item, Label, LongContainer, OptionButton, RightText, Select, Loading, 
  SelectBox, SelectText, Text, Title, Link } from './styles/popup';


  export default function Popup({ children, ...restProps }) {

    return restProps.trigger ? (
      restProps.fullScreen ? (
        <Background>
          <Container {...restProps}>{children}</Container>
        </Background>
      ) : (
        <Background>
          <FittedContainer {...restProps}>{children}</FittedContainer>
        </Background>
      )
    ) : (
      <></>
    );
  }


Popup.Background = function PopupBackground({ children, ...restProps }) {
  return <Background {...restProps}>{children}</Background>;
};

Popup.Loading = function PopupBackLoading({ children, ...restProps }) {
  return <Loading {...restProps}>{children}</Loading>;
};

Popup.FittedBackground = function PopupFittedBackground({ children, ...restProps }) {
  return <FittedBackground {...restProps}>{children}</FittedBackground>;
};

Popup.DividingLine = function PopupDividingLine({ children, ...restProps }) {
  return <DividingLine {...restProps}>{children}</DividingLine>;
};

Popup.SelectText = function PopupSelectText({ children, ...restProps }) {
  return <SelectText {...restProps}>{children}</SelectText>;
};

Popup.Link = function PopupLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};

Popup.RightText = function PopupRightText({ children, ...restProps }) {
  return <RightText {...restProps}>{children}</RightText>;
};

Popup.Select = function PopupSelect({ children, ...restProps }) {
  return <Select {...restProps}>{children}</Select>;
};

Popup.LongContainer = function PopupLongContainer({ children, ...restProps }) {
  return <LongContainer {...restProps}>{children}</LongContainer>;
};

Popup.CardText = function PopCardText({ children, ...restProps }) {
  return <CardText {...restProps}>{children}</CardText>;
};

Popup.CheckBox = function PopupCheckBox({ children, ...restProps }) {
  return <CheckBox {...restProps}>{children}</CheckBox>;
};

Popup.SelectBox = function PopupSelectBox({ children, ...restProps }) {
  return <SelectBox {...restProps}>{children}</SelectBox>;
};

Popup.Button = function PopupButton({ children, ...restProps }) {
  return <Button {...restProps}>{children}</Button>;
};

Popup.ActionButton = function PopupActionButton({ children, ...restProps }) {
  return <ActionButton  {...restProps}>{children}</ActionButton>;
};

Popup.OptionButton = function PopupOptionButton({ children, ...restProps }) {
  return <OptionButton {...restProps}>{children}</OptionButton>
};

Popup.CloseButton = function PopupCloseButton({ children, ...restProps }) {
  return (
    <CloseButton onClick={() => {
      restProps.setTrigger(false);
    }}
    {...restProps}>{children}
    </CloseButton>
  );
};

Popup.ConfirmButton = function PopupConfirmButton({ children, setMessageCode, messageCode, ...restProps }) {
  return (
    <ConfirmButton onClick={() => {
      restProps.setTrigger(false);
      setMessageCode(messageCode);
    }}
      {...restProps}>{children}
    </ConfirmButton>
  );
};

Popup.Caption = function PopupCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Popup.CaptionLeft = function PopupCaptionLeft({ children, ...restProps }) {
  return <CaptionLeft {...restProps}>{children}</CaptionLeft>;
};

Popup.Title = function PopupTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Popup.Input = function PopupInput({ children, ...restProps }) {
  return <Input {...restProps}>{children}</Input>;
};

Popup.InputContainer = function PopupInputContainer({ children, ...restProps }) {
  return <InputContainer {...restProps}>{children}</InputContainer>;
};

Popup.Group = function PopupGroup({ children, ...restProps }) {
  return <Group {...restProps}>{children}</Group>;
};

Popup.Label = function PopupLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Popup.Item = function PopupItem({ children, ...restProps }) {
  return <Item {...restProps}>{children}</Item>;
};

Popup.Text = function PopupText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

