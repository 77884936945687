
import React, { useState, useEffect } from 'react';
import { ConsentText, ConsentPane, ConsentButton, ConsentContainer} from './styles/cookie-consent';

const CookieConsent = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem('cookieConsent');
    setShowPopup(!hasConsented);
  }, []);

  const handleConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowPopup(false);
  };

  return (
    showPopup && (
      <ConsentContainer>
      <ConsentPane>
        <ConsentText>
          This web application uses cookies to function properly and ensure you get the best experience
        </ConsentText>
        <ConsentButton onClick={handleConsent}>I Consent</ConsentButton>
        </ConsentPane>
      </ConsentContainer>
    )
  );
};

export default CookieConsent;