import { PDFDocument } from 'pdf-lib';
import { firebase } from '../lib/firebase.prod';


async function createSiteDirections(info) {
    // todo: change site directions based on site selected
    const downloadUrl = "https://firebasestorage.googleapis.com/v0/b/ezpath-325415.appspot.com/o/Order%20Summary%2Fezpath_directions_pretoria_east_v1.pdf?alt=media&token=9ff2159d-3f99-4a0d-a91f-b9d715e13a33"; 
    let response = {};
    try {
      response = await fetch(downloadUrl);
      if (!response.ok) {
          throw new Error(`Network error: ${response.status}`);
      }
   } catch(error) {
      console.error("Error fetching PDF:", error);
   }
    const pdfBytes = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfBytes);
  
    // Trigger download
    //download(pdfBytes, "filled_form.pdf", "application/pdf"); 
    downloadAndOpenPDF(pdfDoc, "Directions to Oncolab Pretoria East.pdf");
  }

  async function downloadAndOpenPDF(pdfDoc) {
    // 1. Save PDF bytes from pdfDoc
    const pdfBytes = await pdfDoc.save();
  
    // 2. Create a Blob
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);
  
    // 3. Open in a new tab
    window.open(blobURL, '_blank');
  
    // 4. (Optional) Revoke the object URL
    setTimeout(() => URL.revokeObjectURL(blobURL), 5000); 
  }


function download(data, filename, type) {
    const file = new Blob([data], { type: type });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = filename;
    link.click();
    URL.revokeObjectURL(link.href); // Clean up
  }

  // Function to find and fill text field in PDF
async function fillPdfTextField(form, fieldName, value) {
  if (value === null || value === undefined) {
    console.log(`Value for text field "${fieldName}"
      is null or undefined. Skipping this field.`);
    return;
  }

  const field = form.getTextField(fieldName);
  if (!field) {
    // throw new Error(`Text field "${fieldName}" not found`);
    console.log(`Text field "${fieldName}" not found`);
  }
  field.setText(value);
  // return pdfDoc;
  return;
}

function getCurrentDateString() {
  const currentDate = new Date();

  // Get day, month, and year components
  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1)
      .padStart(2, "0"); // Note: January is 0
  const year = currentDate.getFullYear();

  // Concatenate components with underscores
  const dateString = `${day}_${month}_${year}`;

  return dateString;
}

    export default createSiteDirections;