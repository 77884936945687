import { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function useTests() {
  const [tests, setTests] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchActiveTests = async () => { 
      try {
        const testsRef = collection(db, "tests");
        const activeTestsQuery = query(testsRef, where("active", "==", "TRUE")); // Use boolean true

        const snapshot = await getDocs(activeTestsQuery);
        const allTests = snapshot.docs.map((doc) => ({
          ...doc.data(),
          docId: doc.id
        }));

        setTests(allTests);

      } catch (error) {
        console.error("Error fetching active tests:", error.message);
      }
    };

    fetchActiveTests(); // Call the async function
  }, []); // Add db to the dependency array

  return { tests }; 
}