import React, { useState, useContext } from 'react';
import {useHistory} from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';
import { UserContext } from '../context/UserContext';
import { Form, Profiles } from '../components';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth, updateProfile } from 'firebase/auth';

var patientAvatars1 = [1,2,3,4,5];
var patientAvatars2 = [6,7,8,9,10];
var practiceAvatars1 = [1,2,3,4,5];
var practiceAvatars2 = [6,7,8,9,10];
var practiceAvatars3 = ["P1","P2","P3","P4","P5"];
var practiceAvatars4 = ["P6","P7","P8","P9","P10"];
var practiceAvatars5 = ["P11","P12","P13","P14","P15"];
var labAvatars1 = [1,2,3,4,5];
var labAvatars2 = [6,7,8,9,10];
var labAvatars3 = ["L1","L2","L3","L4","L5"];
var labAvatars4 = ["L6","L7","L8","L9","L10"];
var labAvatars5 = ["L11","L12","L13","L14","L15"];


export default function SelectAvatar({}) {
  const history = useHistory();
  const db = getFirestore();
  const [editProfile, setEditProfile] = useContext(UserContext);
  const [avatar, setAvatar] = useState(editProfile.photoURL);
  const auth = getAuth();

  const user = auth.currentUser;
  //console.log(user);
  
  const handleUpdate = () => {

    if (editProfile.edit_type == "new_user") {
      editProfile.photoURL = avatar;
      history.goBack();
      return;
    }

    setEditProfile({
      ...editProfile,
      photoURL: avatar,
    });

    const docId = editProfile.docId; 
    const userRef = doc(db, "users", docId);

    // Update Firestore document
    updateDoc(userRef, { photoURL: avatar})
    .then(() => {
      editProfile.photoURL = avatar; 
      console.log("PATIENT AVATAR SAVE: SUCCESSFUL");

      const auth = getAuth();
      const currentUser = auth.currentUser; 

      // Update Current User (if applicable)
      if (currentUser && currentUser.uid === editProfile.user_id) { // Check if it's the current user
          updateProfile(currentUser, {
              photoURL: avatar
          })
          .catch((error) => { 
              console.error("Error updating current user's profile:", error);
          });
      }
      history.goBack();
    })
    .catch((error) => {
      console.error("Error updating Firestore document:", error);
    });

    /* var doc_id = editProfile.docId;

    var db = firebase.firestore();
    db.collection("users").doc(doc_id).update({
      photoURL: avatar,
    })
      .then(() => {
        // Data saved successfully!
        editProfile.photoURL = avatar;
        console.log("PATIENT AVATAR SAVE: SUCCESFUL");
        //if (user[0].user_id === editProfile.user_id) { //the profile belongs to the main user
        //  user[0].photoURL = avatar;
        auth
          .currentUser
          .updateProfile({
            photoURL: avatar
          })   
        history.goBack();
      })
      .catch((error) => {
        // The write failed...
        console.log("PATIENT AVATAR SAVE: UNSUCCESFUL");
        console.log(error);
      }); */
  };

  return (
      <>
      <Profiles>
        <Profiles.Title>Current profile image</Profiles.Title>
        <Profiles.List>
        <Profiles>
          <Profiles.Button onClick={() => history.goBack()}>Cancel</Profiles.Button>
          <Profiles.Button onClick={() => handleUpdate()}>Save</Profiles.Button>
        </Profiles>
          <Profiles.User>
            <Profiles.Picture src={avatar} />
            <Profiles.Name>{editProfile.name}</Profiles.Name>
          </Profiles.User>
        </Profiles.List>
         
          <Profiles.Title>Select a new profile image</Profiles.Title>
          {editProfile.user_type == "practice" ? 
          <>
            <Profiles.List>
              {practiceAvatars1.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {practiceAvatars2.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {practiceAvatars3.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {practiceAvatars4.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {practiceAvatars5.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
          </>:
            <>
            </>
          }
          {editProfile.user_type == "lab" ? 
          <>
            <Profiles.List>
              {labAvatars1.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {labAvatars2.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {labAvatars3.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {labAvatars4.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
            <Profiles.List>
              {labAvatars5.map((image) => (
                <Profiles.User key={image}
                  onClick={() => setAvatar(image)}>
                  <Profiles.Picture src={image} />
                </Profiles.User>
                ))}
            </Profiles.List>
          </>:
          <></>
              }

        {editProfile.user_type == "patient" ? 
            <>
            <Profiles.List>
            {patientAvatars1.map((image) => (
              <Profiles.User key={image}
                onClick={() => setAvatar(image)}>
                <Profiles.Picture src={image} />
              </Profiles.User>
              ))}
            </Profiles.List>
            <Profiles.List>
                {patientAvatars2.map((image) => (
                  <Profiles.User key={image}
                    onClick={() => setAvatar(image)}>
                    <Profiles.Picture src={image} />
                  </Profiles.User>
                  ))}
            </Profiles.List>
            </>:
          <></>
          }


      </Profiles>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
      <Profiles.Space> </Profiles.Space>
    </>
  );
}