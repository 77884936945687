import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function usePatientSearch(patientSearch, array) {
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore(); // Get Firestore instance
  const [patients, setPatients] = useState([]);

  useEffect(() => {
    const searchQueries = []; // Array to hold query objects

    if (patientSearch !== 0) {
      if (array[2] && array[2] !== "") { 
        const mobileQuery = query(collection(db, "users"),
                                  where("mobile_number", "==", array[2]),
                                  where("user_type", "==", "patient"));
        searchQueries.push(mobileQuery);
      }

      if (array[0] && array[0] !== "") {
        const nationalIdQuery = query(collection(db, "users"),
                                      where("national_id", "==", array[0]),
                                      where("user_type", "==", "patient"));
        searchQueries.push(nationalIdQuery);
      }

      if (array[1] && array[1] !== "") {
        const emailQuery = query(collection(db, "users"),
                                 where("email_address", "==", array[1]),
                                 where("user_type", "==", "patient"));
        searchQueries.push(emailQuery);
      }

      // Perform all queries
      Promise.all(searchQueries.map(q => getDocs(q)))
        .then(querySnapshots => {
          const uniqueUsers = {};
          querySnapshots.forEach(snapshot => {
            snapshot.forEach(doc => {
              uniqueUsers[doc.id] = doc.data();
            });
          });
          setPatients(uniqueUsers); // Set the consolidated data
        })
        .catch(error => {
          console.error("Error getting documents:", error);
        });
    }
  }, [patientSearch]);

  return { patients };
}

/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore } from "firebase/firestore";

export default function usePatientSearch(patientSearch,array) {

  //const [patients, setPatients] = useState([]);
  const { firebase } = useContext(FirebaseContext);
  const firestore = getFirestore();
  var uniqueUsers = {}; // Object to store unique user data
  const [patients, setPatients] = useState([]);
  let promises = [];

   useEffect(() => {
    var db = firebase.firestore();
    var uniqueUsers = {}; // Initialize the object to store unique users
    
    if (patientSearch != 0) {
      // Query for mobile_number
      if (array[2] && array[2] != "") {
        const promise = db.collection("users")
          .where("mobile_number", "==", array[2])
          .where("user_type", "==", "patient")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              uniqueUsers[doc.id] = doc.data();
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
          promises.push(promise);
      }
    
      // Query for national_id
      if (array[0] && array[0] != "") {
        const promise = db.collection("users")
          .where("national_id", "==", array[0])
          .where("user_type", "==", "patient")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              uniqueUsers[doc.id] = doc.data();
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
          promises.push(promise);
      }
    
      // Query for email address
      if (array[1] && array[1] != "") {
        const promise = db.collection("users")
          .where("email_address", "==", array[1])
          .where("user_type", "==", "patient")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              uniqueUsers[doc.id] = doc.data();
            });
          })
          .catch((error) => {
            console.log("Error getting documents: ", error);
          });
          promises.push(promise);
      }
    
      Promise.all(promises).then((values) => {
        console.log(values);
        // Print the consolidated unique user data
        console.log("Consolidated unique user data:", uniqueUsers);
        setPatients(uniqueUsers);
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    }
    
  
  }, [patientSearch]);

  return { patients };
}
 */