import React, { useContext, useState} from 'react';
import { Feature, OptForm, CookieConsent } from '../components';
import { HeaderContainer } from '../containers/header';
import { JumbotronContainer } from '../containers/jumbotron';
import { FaqsContainer } from '../containers/faqs';
import { FooterContainer } from '../containers/footer';
import { FirebaseContext } from '../context';
import { UserContext } from '../context/UserContext';
import * as ROUTES from '../constants/routes';
import { useHistory } from 'react-router-dom';
import { getAuth, fetchSignInMethodsForEmail} from "firebase/auth";

export default function Landing({user}, {userData}) {

  let props = {
    user: user,
    userData: userData
  }

  const history = useHistory();
  const { firebase } = useContext(FirebaseContext);
  const auth = getAuth();
  const [signupEmail, setSignupEmail] = useContext(UserContext);
  const [emailAddress, setEmailAddress] = useState('');

  const handleEmail = (event) => {
    setEmailAddress(event.target.value);
  };

  //  todo: move this to a different file for helper funcitons
  function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    {
      setSignupEmail(mail);
      return (true);
    }
      //alert("You have entered an invalid email address!");
      return (false);
  }

  function orderClick() {
    history.push(ROUTES.ORDER_TESTS);
  }
  
  //  todo: validate if it is a valid email
  //        check if this email is already used
  //        if not proceed to sign in page and automatically fill in email field
  //        if it is used, proceed to sign-in page and fill in email field

  function checkEmail() {
    if (ValidateEmail(emailAddress)) {
      /* auth.fetchSignInMethodsForEmail(emailAddress)
        .then((signInMethods) => {
          //setEmailAddress(emailAddress);
          if (signInMethods.length) {
            console.log("SIGN IN METHODS SUCCEEDED, Email Address already in Use");
            history.push(ROUTES.SIGN_IN);
          } else {
            console.log("SIGN IN METHODS FAILED, Email Address is New");
            history.push(ROUTES.SIGN_UP);
            // User does not exist. Ask user to sign up.

          }
        })
        .catch((error) => { 
          console.log("SIGN IN METHODS FAILED: ", error);
          // Some error occurred.
        }); */
        fetchSignInMethodsForEmail(auth, emailAddress)
      .then((signInMethods) => {
        if (signInMethods.length) {
          console.log("SIGN IN METHODS SUCCEEDED, Email Address already in Use");
          history.push(ROUTES.SIGN_IN);
        } else {
          console.log("SIGN IN METHODS FAILED, Email Address is New");
          history.push(ROUTES.SIGN_UP);
        }
      })
      .catch((error) => {
        console.log("SIGN IN METHODS FAILED: ", error);
        // Some error occurred.
      });
      }
      else {
            var emailInput = document.getElementById("emailInput");
            emailInput.value = "";
            emailInput.placeholder = "Please enter a valid email address";
      }
  }

  return (
    <>
      <HeaderContainer>
        <Feature>
        {/* <Feature.SubTitle>Please note this site is not active yet. Orders placed will have no effect.</Feature.SubTitle> */}
          <Feature.Title>Convenient, affordable medical testing</Feature.Title>
          <Feature.SubTitle>Available in Pretoria East, Wilgers and surrounds. Join us now</Feature.SubTitle>
          <OptForm>
          <CookieConsent />
            <OptForm.Input placeholder="Email address" 
            id = "emailInput"
            value = {emailAddress}
            //ref={emailRef}
            onChange={handleEmail}
            />
            <OptForm.Button onClick={() => checkEmail()} >Try it now</OptForm.Button>
            <OptForm.Break />
            <div><OptForm.Text>Need to get tested? Enter your email to create your profile.</OptForm.Text></div>
            <OptForm.Break />
            <div><OptForm.Link to="/signin">Already a member? Sign in here.</OptForm.Link></div>
            <OptForm.Break />
            {/* <OptForm.Text>OR</OptForm.Text> */}
            <OptForm.Break />
            {/* <OptForm.Button  onClick={() => orderClick()} Order a Test>Order a Test</OptForm.Button> */}
          </OptForm>
        </Feature>
      </HeaderContainer>
      <JumbotronContainer />
      <FaqsContainer />
      <FooterContainer />
    </>
  );
}