import React from 'react';
import { Container, Input, Break, Button, Text, Link, LeftText, BackButton, RadioButton, LongContainer, ModestButton } from './styles/opt-form';

export default function OptForm({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

OptForm.Input = function OptFormInput({ ...restProps }) {
  return <Input {...restProps} />;
};

OptForm.Button = function OptFormButton({ children, ...restProps }) {
  return (
    <Button {...restProps}>
      {children} <img src="/images/icons/chevron-right.png" alt="Try Now" />
    </Button>
  );
};

OptForm.Link = function OptFormLink({ children, ...restProps }) {
  return <Link {...restProps}>{children}</Link>;
};

OptForm.RadioButton = function OptFormRadioButton({ children, ...restProps }) {
  return <RadioButton {...restProps}>{children}</RadioButton>;
};

OptForm.BackButton = function OptFormBackButton({ children, ...restProps }) {
  return <BackButton {...restProps}>{children}</BackButton>;
};

OptForm.Text = function OptFormText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

OptForm.ModestButton = function OptFormModestButton({ children, ...restProps }) {
  return <ModestButton {...restProps}>{children}</ModestButton>;
};

OptForm.LeftText = function OptFormLeftText({ children, ...restProps }) {
  return <LeftText {...restProps}>{children}</LeftText>;
};

OptForm.Break = function OptFormBreak({ ...restProps }) {
  return <Break {...restProps} />;
};

OptForm.LongContainer = function OptFormLongContainer({ ...restProps }) {
  return <LongContainer {...restProps} />;
};