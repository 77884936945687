/* import { firebase } from '../lib/firebase.prod';

export default async function writeIntendedOrder(order_data) {
  try {
    // Get the m_payment_id from the first item
    const m_payment_id = order_data.items[0].m_payment_id;

    // Write the m_payment_id to the "invoice_number_reference" collection
    await firebase.firestore().collection('invoice_number_reference').doc(m_payment_id).set({
      timestamp: new Date()
    });

    // Write other data to "order_attempted_items" collection
    for (let index = 0; index < order_data.items.length; index++) {
      await firebase.firestore().collection('order_attempted_items').add(order_data.items[index]);
    }

    return true; // Return true if the write operations succeed
  } catch (error) {
    console.error('Error writing order data:', error.message);
    return false; // Return false if there's an error
  }
}
 */
import { firebase } from '../lib/firebase.prod';
import { getFirestore } from "firebase/firestore";

export default function writeIntendedOrder(order_data) {
  const firestore = getFirestore(); 

  var index = 0;

  try {
    for (index = 0; index < order_data.items.length; index++) {
      firebase.firestore().collection('order_attempted_items').add(order_data.items[index])
        .catch((error) => {
        console.log(error.message); 
        return false;
        }
      );
    }
  } catch {
    return false;
  }
  return true;
} 