import { getFirestore, collection, addDoc } from 'firebase/firestore';

export default async function writePayLaterOrder(order_data) {
  const firestore = getFirestore();
  const orderItemsCollectionRef = collection(firestore, 'order_pay_later_items'); 

  try {
    const promises = order_data.items.map(item => {
      // Add the "order_type" field to each item
      const modifiedItem = {
        ...item,
        order_type: "pay_later"
      };
      return addDoc(orderItemsCollectionRef, modifiedItem);
    });

    await Promise.all(promises);
    return true; 
  } catch (error) {
    console.error("Error adding order items:", error);
    return false; 
  }
}


/* import { firebase } from '../lib/firebase.prod';
import { getFirestore } from "firebase/firestore";

export default function writePayLaterOrder(order_data) {
  const firestore = getFirestore(); 

  var index = 0;

  try {
    for (index = 0; index < order_data.items.length; index++) {
      firebase.firestore().collection('order_pay_later_items').add(order_data.items[index])
        .catch((error) => {
        console.log(error.message); 
        return false;
        }
      );
    }
  } catch {
    return false;
  }
  return true;
} */