import styled from 'styled-components/macro';
import { Link as ReachRouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 660px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: auto;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin-bottom: 100px;
`;

export const PaddedContainer = styled.div`
    display: flex;
    padding: 70px 45px;
    flex-direction: column;
    max-width: 815px;
    margin: auto;
    height: 100%;
    
    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
    }    
`;

export const LongContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 100%;
`;

export const LongLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin: left;
  max-width: 100%;
`;

export const Error = styled.div`
  background: rgba(200,100,20,0.50);
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 16px;
  color: white;
  padding: 15px 20px;
`;

export const Space = styled.div`
  margin-top: 20px;
`;

export const DividingLine = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 3px;
  background-color: #333;
`;

export const Base = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  width: 100%;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 28px;
`;

export const Heading1 = styled.h1`
  color: #fff;
  font-size: 22px;
  font-weight: bold;
  margin 15px 10px;
`;

export const Text = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 500;
  z-index: 1;
  pointer-events: none;
`;

export const TextRight = styled.p`
  color: #737373;
  font-size: 16px;
  font-weight: 500;
  text-align: end;
`;

export const TextLeft = styled.p`
  color: #737373;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const TextSmall = styled.p`
  margin-top: 10px;
  font-size: 13px;
  line-height: normal;
  color: #737373;
`;

export const Link = styled(ReachRouterLink)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;


export const Input = styled.input`
  background: #333;
  border-radius: 4px;
  border 0;
  color: #fff;
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  /* &:last-of-type {
    margin-bottom: 20px;
  } */
`;

export const InputNarrow = styled.input`
  background: #333;
  border-radius: 4px;
  width: 50px;
  color: #fff;
  height: 30px;
  // line-height: 40px;
  padding: 5px 10px;
  margin: 5px 10px;
  border: none; 
  /* &:last-of-type {
    margin-bottom: 20px;
  } */
`;


export const SubButton = styled.button`
  background-color: #cc6600;
  border-radius: 4px;
  min-width: 100px;
  width: fit-content;
  font-size: 16px;
  //font-weight: bold;
  margin: 5px 10px 5px 10px;
  padding: 10px 20px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #ff9900;
  } 
`;

export const Button = styled.button`
  background: #cc6600;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 24px 0 12px;
  padding: 16px;
  border: 0;
  color: white;
  cursor: pointer;
  z-index: 2;
  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      background: #cc6600;
    }
  }
  &:hover {
    background: #ff9900;
  }
`;

export const OptionButton = styled.button`
  background: #333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 24px 0 12px;
  padding: 16px;
  border: 0;
  color: white;
  cursor: pointer;
  z-index: 2;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #49b9ec;
  }
`;

export const OptionText = styled.div`
  background: #333;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 5px;
  padding: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  z-index: 2;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #49b9ec;
  }
`;


export const BackButton = styled.button`
  background: #cc6600;
  border-radius: 4px;
  width: 100px;
  font-size: 16px;
  font-weight: bold;
  margin: -50px 10px 20px -50px;
  padding: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: inline-block;
  accent-color: #cc6600;
  width: 40px;
  height: 20px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const RadioBox = styled.input.attrs({ type: 'radio' })`
  display: inline-block;
  accent-color: #cc6600;
  width: 40px;
  height: 20px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 10px;
  margin-bottom: 10px;
`;


export const Body = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: white;
  line-height: normal;
  white-space: pre-wrap;
  border-radius: 4px;
  user-select: none;
  overflow: hidden;
  span {
    display: block;
    //padding: 0.8em 2.2em 0.8em 1.2em;
  }
`;