import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { initializeApp } from "firebase/app";
//import { getAuth, EmailAuthProvider } from "firebase/auth";

//import { getAnalytics } from "firebase/analytics";
//import { seedDatabase } from '../seed';
// 1) when seeding the database you'll have to uncomment this!
//import { seedDatabase } from '../seed';

const firebaseConfig = {
apiKey: "AIzaSyCrLsBbmtPE55HBKjzZFJZA0dlzdI7KDMY",
authDomain: "ezpath-325415.firebaseapp.com",
projectId: "ezpath-325415",
storageBucket: "ezpath-325415.appspot.com",
messagingSenderId: "874053438258",
appId: "1:874053438258:web:91d6aa6f426acb72d1252b",
measurementId: "G-E2J48YKXHL"
};



// Initialize Firebase 
const firebase = initializeApp(firebaseConfig);
// 2) when seeding the database you'll have to uncomment this!
//seedDatabase(firebase);
// 3) once you have populated the database (only run once!), re-comment this so you don't get duplicate data

//export { firebase, EmailAuthProvider, auth };
export { firebase };
