import React, { useState, useEffect } from 'react';
import { Header, EditTable } from '../components';
import { useEditSites } from '../hooks';
import { selectionFilterEditSites } from '../utils';
import { writeEditedSites } from '../utils';
import { getUUID } from '../utils';

import * as ROUTES from '../constants/routes';
import logo from '../logo.png';

export function EditSitesContainer() { 

  const [refreshData, setRefreshData] = useState(false);
  const [resultsDisplay, setResultsDisplay] = useState("category");
  const { sites } = useEditSites('sites', refreshData);
  const [editedData, setEditedData] = useState([]);


  useEffect(() => {
    // let tempData = selectionFilterEditSites({sites}, "site_id"); // arrange by id
    // if (tempData) {
    if (sites) {
    setEditedData(sites);
    }
  }, [sites]);

  useEffect(() => {
    let tempData = selectionFilterEditSites({editedData}, resultsDisplay); // arrange by category
    if (tempData) {
    setEditedData(tempData);
    }
  }, [resultsDisplay]);


/*   function formatNumber(input) {
    input = input.replace('.', ',');
    // Split the input string at the comma
    let parts = input.split(',');

    // Get the integer and decimal parts
    let integerPart = parseInt(parts[0], 10);
    let decimalPart = parts[1] ? parseFloat('.' + parts[1]) : 0;

    // Format the decimal part to always have 2 decimal places
    decimalPart = decimalPart.toFixed(2).slice(2);

    // If there's no decimal part, set it to '00'
    if (decimalPart.length === 0) {
        decimalPart = '00';
    }

    // Return the formatted number
    return integerPart + ',' + decimalPart;
}

  function roundToTwo(num) {
    let roundedString = (Math.round(num * 100) / 100).toFixed(2);
    roundedString = roundedString.replace(".",",");
    return roundedString;
  } */

/*   function handlePriceCalc() {
    const priceInput = document.getElementById("priceCalc").value;
    let percentage = parseFloat(priceInput.replace('%', ''));
  
    if (!isNaN(percentage)) {

      let localData = [...editedData]; // Your editedData array

      localData.forEach(item => {
        if (!isNaN(parseFloat(item.reference_price))) {
          const referencePrice = parseFloat(item.reference_price);
          let newPrice = referencePrice * (100 - percentage) / 100;
          item.reduced_price = newPrice;
          newPrice = Math.round(newPrice);
          item.reduced_price = newPrice.toFixed(2).replace('.', ',');
          item.reference_price = formatNumber(item.reference_price);
          item.vat_amount = roundToTwo(newPrice*0.15);
        } else { item.reference_price = "";}

        if (!isNaN(parseFloat(item.price))) {
          //let price = parseFloat(item.price);
          item.price = formatNumber(item.price);
        } else { 
          item.price = ""; }

          if (!isNaN(parseFloat(item.reduced_price))) {
            //let price = parseFloat(item.price);
            item.reduced_price = formatNumber(item.reduced_price);
          } else { 
            item.price = ""; }

      });
      
      setEditedData(localData);
    } else {
      console.error("Invalid input: Please enter a numerical value for the percentage.");
    }
  } */

  
  /* const formatPrices = () => {

    let localData = [...editedData];

    localData.forEach(item => {
      if (!isNaN(parseFloat(item.reference_price))) {
        let reference_price = item.reference_price;
        item.reference_price = reference_price.toFixed(2).replace('.', ',');
      }
      else{
        item.reference_price = "";
      }
      if (!isNaN(parseFloat(item.price))) {
        let price = item.price;
        item.price = price.toFixed(2).replace('.', ',');
      }
      else{
        item.price = "";
      }
      if (!isNaN(parseFloat(item.reduced_price))) {
        let reduced_price = item.reduced_price;
        reduced_price = Math.round(reduced_price);
        item.reduced_price = reduced_price.toFixed(2).replace('.', ',');
      }
      else {
        item.reduced_price = "";
      }
    });
    setEditedData(localData);
  } */

  const orderDisplay = (order) => {
    setResultsDisplay(order);
  };
  
  const handleDBFetch = () => {
    setRefreshData(!refreshData);
  };

  const handleDBWrite = () => {
    writeEditedSites(editedData);
  };

  const handleEdit = (id, field, value) => {
    const newData = editedData.map(item => {
      if (item.docId === id) {
        item = { ...item, [field]: value };
        return item;
      }
      return item;
    });
    setEditedData(newData);
  };

  const handleNewEntry = (id) => {
    const newData = editedData.reduce((acc, item) => {
      acc.push(item);
      if (item.docId === id) {
        acc.push({
          docId: getUUID(),
          site_id: '',
          site_latitude: '',
          site_longitude: '',
          site_maps_name: '',
          site_friendly_name: '',
          site_road_directions:'',
          site_facility_directions:'',
          site_short_link:'',
          site_practice_code:'',
          active:'true',
          // Add more fields if needed
        });
      }
      return acc;
    }, []);
    setEditedData(newData);
  };

  const deleteEntry = (idToDelete) => {
    const newData = editedData.filter(item => item.docId !== idToDelete);
    setEditedData(newData);
  };


  return (
    <>
    <Header src="joker1" dontShowOnSmallViewPort>
      <Header.Frame>
        <Header.Group>
          <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
          <Header.Button>My Details</Header.Button>
          <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test</Header.ButtonLink>
          <Header.ButtonLink to={ROUTES.LAB}>Back to Lab Home</Header.ButtonLink>
        </Header.Group>
      </Header.Frame>
      <Header.Feature>
          <Header.FeatureCallOut>Edit Sites</Header.FeatureCallOut>
          <Header.Text>
            Here you can edit the sites your lab has available for pateints.
          </Header.Text>
        </Header.Feature>
    </Header>

    <EditTable.ButtonContainer>
        <EditTable.Button onClick={handleDBWrite}>
          Save to Database
        </EditTable.Button>
        <EditTable.Button onClick={handleDBFetch}>
          Load from Database
        </EditTable.Button>
        <EditTable.ButtonSpacer>
        </EditTable.ButtonSpacer>
        {/* <EditTable.LeftText id="priceCalc" type="text" style={{ width: '100px' }} default="adjustment %" />
        <EditTable.Button onClick={handlePriceCalc}>
          Adjust EZPath Pricing
        </EditTable.Button> */}
    </EditTable.ButtonContainer>

    <div>
      <table style={{ padding: '0 50px', tableLayout: 'auto', width: '100%' }}>
        <thead>
          <tr>
              <td><EditTable.ColumnLabel style={{ width: '10px', cursor:"pointer"}}>+</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_id")} style={{ width: '50px', cursor:"pointer"}}>ID</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_friendly_name")} style={{ width: '200px', cursor:"pointer"}}>Friendly Name.</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_latitude")} style={{ width: '200px', cursor:"pointer" }}>Latitude</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_longitude")} style={{ width: '200px', cursor:"pointer" }}>Longitude</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_maps_name")} style={{ width: '200px', cursor:"pointer" }}>Maps Name</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_name")} style={{ width: '200px', cursor:"pointer" }}>Old Name</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_road_directions")} style={{ width: '200px', cursor:"pointer" }}>Road Directions</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_facility_directions")} style={{ width: '200px', cursor:"pointer" }}>Facility Directions</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_short_link")} style={{ width: '200px', cursor:"pointer" }}>Short Link</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_active")} style={{ width: '200px', cursor:"pointer" }}>Active</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_country")} style={{ width: '200px', cursor:"pointer" }}>Country</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_province")} style={{ width: '200px', cursor:"pointer" }}>Province</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_city")} style={{ width: '200px', cursor:"pointer" }}>City</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel onClick={e => orderDisplay("site_suburb")} style={{ width: '200px', cursor:"pointer" }}>Suburb</EditTable.ColumnLabel></td>
              <td><EditTable.ColumnLabel style={{ width: '10px', cursor:"pointer" }}>-</EditTable.ColumnLabel></td>
          </tr>
        </thead>
        <tbody>
          {editedData.map(item => (
            <tr>
            <td><EditTable.LeftText type="text" readOnly={true} style={{ width: '10px', cursor:"pointer" }} value="+" onClick={e => handleNewEntry(item.docId)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '50px' }}/* key={key} */ value={item.site_id} onChange={e => handleEdit(item.docId, "site_id", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_friendly_name} onChange={e => handleEdit(item.docId, "site_friendly_name", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_latitude} onChange={e => handleEdit(item.docId, "site_latitude", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_longitude} onChange={e => handleEdit(item.docId, "site_longitude", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_maps_name} onChange={e => handleEdit(item.docId, "site_maps_name", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_name} onChange={e => handleEdit(item.docId, "site_name", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_road_directions} onChange={e => handleEdit(item.docId, "site_road_directions", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_facility_directions} onChange={e => handleEdit(item.docId, "site_facility_directions", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_short_link} onChange={e => handleEdit(item.docId, "site_short_link", e.target.value)}/></td>
            <td><EditTable.RightText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_active} onChange={e => handleEdit(item.docId, "site_active", e.target.value)}/></td>
            <td><EditTable.RightText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_country} onChange={e => handleEdit(item.docId, "site_country", e.target.value)}/></td>
            <td><EditTable.RightText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_province} onChange={e => handleEdit(item.docId, "site_province", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_city} onChange={e => handleEdit(item.docId, "site_city", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" style={{ width: '200px' }}/* key={key} */ value={item.site_suburb} onChange={e => handleEdit(item.docId, "site_suburb", e.target.value)}/></td>
            <td><EditTable.LeftText type="text" readOnly={true} style={{ width: '10px', cursor:"pointer" }} value="-" onClick={e => deleteEntry(item.docId)}/></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
}