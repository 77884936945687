import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 660px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: auto;
  padding: 10px 10px 10px 10px;
  margin-bottom: 50px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  display: flex;
  align-items: left;
  justify-content: left;
  min-height: 660px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: auto;
  padding: 10px 10px 10px 10px;
  margin-bottom: 50px;
`;

export const Link = styled.a`
background: #cc6600;
border-radius: 4px;
min-width: 100px;
width: fit-content;
font-size: 16px;
font-weight: bold;
text-decoration: none;
margin: 10px 10px 10px 10px;
padding: 5px;
border: 0;
color: white;
cursor: pointer;
&:disabled {
  opacity: 0.5;
}
`;


export const BackButton = styled.button`
  background: #cc6600;
  border-radius: 4px;
  min-width: 100px;
  width: fit-content;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 10px 10px 10px;
  padding: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;
