import { useState, useEffect, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getAuth, EmailAuthProvider } from "firebase/auth";
const auth = getAuth();


export default function useAuthListener() {

  const [user, setUser] = useState(
      JSON.parse(localStorage.getItem('authUser')));  ////if someone is still logged in, get their details from storage

  const { firebase }  = useContext(FirebaseContext);
  const auth = getAuth();

  useEffect(() => {
    const listener = auth.onAuthStateChanged((authUser) => {
      if (authUser) {//if the logged in user has changed, set the new user details 
        localStorage.setItem('authUser', JSON.stringify(authUser));
        setUser(authUser);
        
       /*  let userDetails = [];
        
        firebase 
        .firestore()
        .collection("users").where("user_id", "==", authUser.uid)
        .get()
        .then((snapshot) => {
          userDetails = snapshot.docs.map((resultsObj) => ({
            ...resultsObj.data(),
            docId: resultsObj.id,
          }));

          let userData1 = Object.assign(authUser, userDetails);
          localStorage.setItem('authUser', JSON.stringify(userData1));
          setUser(userData1);  //only confirm our login once we find user data for this user
          console.log("Authuser AuthStateChanged Successful: ", userDetails[0].user_type);
          //setUserData (userData1); //smsm1
        })
        .catch((error) => {
          console.log("useauthlistener error: ", error.message);
        }); */
      } 
      else // no authorised user
      {
        localStorage.removeItem('authUser');
        setUser({});
        console.log("Authuser Log Out");
      }
    });

    return () => listener();
  }, []);

  return {
    user
  };
}