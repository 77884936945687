import React from 'react';

import { ContactContainer, MapContainer, ContactInfo, ContactFrame} from './styles/contact';

export default function Contact({ children, ...restProps }) {
  return (
      <ContactContainer {...restProps}>{children}</ContactContainer>
  );
}

Contact.MapContainer = function ContactMapContainer({ children, ...restProps }) {
    return <MapContainer {...restProps}>{children}</MapContainer>;
  };

  Contact.ContactInfo = function ContactContactInfo({ children, ...restProps }) {
    return <ContactInfo {...restProps}>{children}</ContactInfo>;
  };

  Contact.ContactFrame = function ContactContactFrame({ children, ...restProps }) {
    return <ContactFrame {...restProps}>{children}</ContactFrame>;
  };