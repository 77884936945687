import styled from 'styled-components/macro';

//export const Container = styled.div`
//  display: flex;
//  border-bottom: 8px solid #222;
//`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 660px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  width: 100%;
  margin: auto;
  max-width: 450px;
  padding: 60px 68px 40px;
  margin-bottom: 100px;
`;

export const ButtonSpacer = styled.p`
  display: block;
  background-color: rgba(0,0,0,0);
  height: fit-content;
  text-align: center;
  margin: 5px 10px 10px 10px;
  color: rgba(0,0,0,0);
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
`;

export const RightText = styled.input`
font-size: 15px;
text-align: right; 
background: #333;
border-radius: 4px;
border 0;
color: #fff;
height: 20px;
line-height: 20px;
//width: fit-content;
padding: 5px 5px;
margin-bottom: 1px;
margin-top: 1px;
//display: inline-block;
`;

export const Button = styled.p`
//font-size: 15px;
//height: 20px;
//line-height: 20px;

  padding: 10px 10px;
  display: block;
  background-color: rgba(200,100,200,0.50);
  height: fit-content;
  text-align: center;
  margin: 1px 10px 1px 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(200,100,200,0.90);
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: bottom;
  padding: 0px 50px 10px 50px;
  }
`;

export const LeftText = styled.input`
font-size: 15px;
text-align: left; 
//width:fit-content;
background: #333;
border-radius: 4px;
border 0;
color: #fff;
height: 20px;
line-height: 20px;
padding: 5px 5px;
margin-bottom: 1px;
margin-top: 1px;
//display: inline-block;
`;

export const ColumnLabel = styled.div`
font-size: 18px;
text-align: center; 
background: rgba(178, 178, 102, 0.9);
border-radius: 4px;
border 0;
// font-weight: bold;
color: #fff;
height: 20px;
line-height: 20px;
padding: 5px 5px;
margin-bottom: 1px;
margin-top: 1px;
//display: inline-block;
`;
