import styled from 'styled-components/macro';


export const Background = styled.div`
display: flex;
flex-direction: column;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
overflow: auto;
margin: 0px, 0px, 50px, 50px;
background: rgba(0,0,0,0.8);
z-index: 101;
`;

export const Link = styled.a`
//background: #cc6600;
//border-radius: 4px;
display: flex;
opacity: 0.7;
min-width: 100px;
width: fit-content;
font-size: 14px;
font-weight: normal;
text-decoration: none;
margin: 10px 0px 0px 10px;
padding: 5px;
color: white;
cursor: pointer;
&:disabled {
  opacity: 0.5;
}
`;

export const Container = styled.div`
  margin: 5% 10% 5% 10%; 
  padding: 0% 0% 5% 0%;
  //border: 50px dotted #348036;
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center; 
  justify-content: center; 
  margin-bottom: 500px;
  width: 80%;
  min-height: 80%;
  border-radius: 12px;
  background: rgba(50, 50, 50, 0.90);
  z-index: 102;
  //height: ;
  //overflow: auto;
`;

export const Loading = styled.div`
  cursor: wait;
`;

export const FittedBackground = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 101;
  margin: 30px; /* To create the 30px spacing around the content */
  border-radius: 15px; /* Half of the 30px to create a rounded border */
`;


export const FittedContainer = styled.div`
margin: 0% 0% 0% 0%; /* Center horizontally */
display: flex;
padding: 15px 15px;
flex-direction: column;
position: absolute;
align-items: center; /* Center vertically */
justify-content: center; /* Center vertically */
border-radius: 15px;
background: rgba(50, 50, 50, 0.90);
z-index: 102;

top: 50%;
left: 50%;
transform: translate(-50%, -50%);


`;


export const DividingLine = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 3px;
  background-color: #333;
`;

export const ActionButton = styled.button`
  background: #cc6600;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin: 24px 0 12px;
  width: 100%;
  padding: 16px;
  border: 0;
  color: white;
  cursor: pointer;
  z-index: 104;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #ff9900;
  }
`;

export const OptionButton = styled.button`
  border-radius: 4px;
  align: centre; 
  background-color: rgba(250,250,250,0.2);
  font-size: 16px;
  width: 100%;
  padding: 10px;
  border: 0;
  color: white;
  margin: 24px 0 0px;
  cursor: pointer;
  z-index: 103;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background: #49b9ec;
  }
`;

export const Button = styled.button`
  display: block;
  background-color: rgba(200,100,20,0.50);
  height: fit-content;
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const LongContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-bottom: 15px;
margin-top: 15px;
`;

export const RightText = styled.div`
margin-left: auto;
font-size: 15px;
`;

export const CardText = styled.div`
margin-left: 5px;
text-align: left;
font-size: 13px;
z-index: 102;
`;

export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  display: inline-block;
  accent-color: #49b9ec;
  margin-top: 10px;
  width: 40px;
  height: 20px;
  background: #303030
  border-radius: 3px;
  transition: all 150ms;
`;

export const SelectBox = styled.input.attrs({ type: 'radio' })`
  display: inline-block;
  accent-color: #49b9ec;
  width: 40px;
  height: 20px;
  background: #303030
  border-radius: 3px;
  margin-bottom: 20px;
  transition: all 150ms;
`;

export const SelectText = styled.div`
font-size: 15px;
color: white;
margin: auto;
margin-bottom: 20px;
// max-width: 450px;
width: 100%;
`;

export const CloseButton = styled.button`
  display: block;
  background-color: rgba(200,100,20,0.50);
  height: fit-content;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const ConfirmButton = styled.button`
  display: block;
  background-color: rgba(200,100,20,0.50);
  height: fit-content;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;


export const Group = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: baseline; 
  margin-bottom: 5px;
  &:first-of-type {
    margin-top: 10px;
  }
`;

export const Label = styled.label`
  flex: 0 0 auto;
  width: 150px;
`;

export const Input = styled.input`
  flex: 1;
  background: #333;
  border-radius: 4px;
  border 0;
  color: #fff;
  padding: 5px 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 10px;
  // max-width: 50%;
`;

export const Select = styled.select`
  display: block;
  background: #333;
  border-radius: 4px;
  border 0;
  color: #fff;
  height: 50px;
  line-height: 50px;
  padding: 5px 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
`;


export const Item = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 40%;
  padding: 10px;
  position: relative;
  cursor: pointer; 
  border-radius: 12px;
  background-color: rgba(51, 223, 255, 0.4);
  // width: fit-content; 
`;

export const Text = styled.p`
  color: white;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 2px;
  font-weight: 500;
  pointer-events: none;
`;

export const Title = styled.h1`
  width: 100%;
  color: #fff;
  font-size: 48px;
  text-align: center;
  font-weight: 500;
`;

export const Caption = styled.h1`
  width: 100%;
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  text-align: center;
  font-weight: 500;
`;

export const CaptionLeft = styled.h1`
  width: 100%;
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  text-align: left;
  font-weight: 500;
`;
