import React from 'react';
import { TestsContainer } from '../containers/order_tests';
import { useTests } from '../hooks';
import selectionFilterTests from '../utils/selection-filter-tests';
import { HeaderBlankContainer } from '../containers/header_blank';
import { FooterContainer } from '../containers/footer';

export default function OrderTests({profile, orderId}) {

  const { tests } = useTests();
  const filteredTests = selectionFilterTests({tests});

  let props = { profile: profile, filteredTests: filteredTests, orderId: orderId, }
  
  return (
    <>
      <HeaderBlankContainer>
      {tests.length > 0 ? ( // Only render if tests data is available
          <TestsContainer props={props} />
        ) : (
          <div>Loading...</div> // Or a more elaborate loading state
        )}
      </HeaderBlankContainer>
      <FooterContainer />
    </>
  );
}
