// import { firebase } from '../lib/firebase.prod';

import { getFirestore, collection, where, query, getDocs } from 'firebase/firestore';

// Assuming you've initialized your Firebase app elsewhere
const db = getFirestore();

export default async function getSiteData(site_id) {
  try {
    const sitesRef = collection(db, "sites");
    const siteQuery = query(sitesRef, where("site_id", "==", site_id));
    const snapshot = await getDocs(siteQuery);

    const siteResult = snapshot.docs.map((resultsObj) => ({
      ...resultsObj.data(),
      docId: resultsObj.id,
    }));

    return siteResult; // Return directly from async function
  } catch (error) {
    throw new Error(error.message); // Rethrow for better handling upstream
  }
}