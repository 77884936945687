import React, { useState, useEffect, useContext } from 'react';
import { Results, Header, Loading, Player, AllPages } from '../components';
import * as ROUTES from '../constants/routes';
import logo from '../logo.png';
import { UserContext } from '../context/UserContext';
import { FirebaseContext } from '../context';
import { SelectProfileContainer } from './profiles';
import { selectionFilterResults } from '../utils';
import { FooterContainer } from './footer';
import { useResults } from '../hooks';
import { useDependants } from '../hooks';
import { useHistory } from 'react-router-dom';
import { getAuth, signOut } from "firebase/auth";

import { selectionFilterPayLaterOrders } from '../utils';
import { selectionFilterLabOrders } from '../utils';
import { movePloToDiscarded} from '../utils';
import { moveOrderToDiscarded} from '../utils';


import { usePatientPayLaterOrders } from '../hooks';
import { usePatientOrders } from '../hooks';

import { getStorage, ref, getDownloadURL } from 'firebase/storage';


export function PatientContainer({props}) {
  const [reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, userData, setUserData] = useContext(UserContext);
  const user = props.userData;
  const [profile, setProfile] = useState({});
  const [change, setChange] = useState(0);
  const [editProfileLoc, setEditProfileLoc] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [resultsDisplay, setResultsDisplay] = useState('category');
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [resultRows, setResultRows] = useState({});
  const history = useHistory();
  const { results } = useResults(displayProfile); //smsm2
  const { dependants } = useDependants(user.user_id, change);
  const { firebase } = useContext(FirebaseContext);
  const auth = getAuth();

  const { patientPayLaterOrders } = usePatientPayLaterOrders(displayProfile.user_id);
  const [payLaterOrderRows, setPayLaterOrderRows] = useState({});
  const { patientOrders } = usePatientOrders(displayProfile.user_id);
  const [patientOrderRows, setPatientOrderRows] = useState({});


  var prevPaymentId = 0;
  var gradient = 1;
  var displayInvoiceLink = false;

  useEffect(() => {
    //if (Object.keys(dependants).length !== 0) {
      //console.log("Dependants Changed", dependants);
      if (Object.keys(user).length !== 0 && dependants.includes("empty")) {
        setDisplayProfile(user);
      }
      else {
        if (dependants.length > 0) {
          const foundDependant = dependants.find(dependant => dependant.user_id === displayProfile.user_id);
          if (foundDependant) {
            setDisplayProfile(foundDependant);
          }
        }
      }
    //}
  }, [dependants]);

  useEffect(() => {
    if (Object.keys(editProfileLoc).length !== 0) {
      setEditProfile(editProfileLoc);
       history.push(ROUTES.PATIENT_DETAILS);
    }
  }, [editProfileLoc]);

  useEffect(() => {
    //todo - check if this is even neccesary
    if (change !== null) {
    setChange(change + 1);
    }
  }, [editProfile.photoUrl]);

  useEffect(() => {

    setResultRows([]);

    if (Object.keys(results).length !== 0 && results[0] !== "empty") {
      
      const result_slides = selectionFilterResults({results}, resultsDisplay);
      setResultRows(result_slides);
    }

  }, [results, resultsDisplay]);

  useEffect(() => {
    console.log("profile changed", profile);
    if (Object.keys(profile).length !== 0) {
      setDisplayProfile(profile);
    }
  }, [profile]);


    // set the display profile right at the start if there ar eno dependants, skip the profile selection stage
  // some GUI elements are hidden based on the user role
  useEffect(() => {
 /*    if (Object.keys(user).length !== 0 && (dependants.includes("empty") || Object.keys(dependants).length === 0)) {
      setDisplayProfile(user); 
    }*/
  }, []);


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, [profile.displayName]);

  
  useEffect(() => {
    
    setPayLaterOrderRows([]);

    if (Object.keys(patientPayLaterOrders).length !== 0 && patientPayLaterOrders[0] !== "empty") {
        const pay_later_order_slides = selectionFilterPayLaterOrders(patientPayLaterOrders, "ordered_date");  //default to sorting by date
        setPayLaterOrderRows(pay_later_order_slides);
      }
  }, [patientPayLaterOrders]);

  
  useEffect(() => {

    setPatientOrderRows([]);

    if (Object.keys(patientOrders).length !== 0 && patientOrders[0] !== "empty") {
        const order_slides = selectionFilterLabOrders(patientOrders, "ordered_date");  //default to sorting by date
        setPatientOrderRows(order_slides);
      }
  }, [patientOrders]);


  const discardPloOrderClick = async (event) => {
    
    event.preventDefault();

    const m_payment_id = event.target.id;
    
    var result = window.confirm("You are going to discard all tests in this order! Proceed?");
      if (!result) {
        return;
      }
      else{
        movePloToDiscarded(m_payment_id, user.name, user.surname, "patient");
        // Programmatically trigger a click on the button
        const closeButton = document.getElementById('closeButton');
        closeButton.click();
      }
  }

  const discardOrderClick = async (event) => {
    
    event.preventDefault();

    const m_payment_id = event.target.id;
    
    var result = window.confirm("You are going to discard all tests in this order! Proceed? \nIf you proceed, EZPath will contact you shortly to arrange a refund.");
      if (!result) {
        return;
      }
      else{
        moveOrderToDiscarded(m_payment_id, user.name, user.surname, "patient");
        // Programmatically trigger a click on the button
        const closeButton = document.getElementById('closeButton');
        closeButton.click();
      }
  }

  function getUrlfromPath(path) {
    const storage = getStorage();
    const fileRef = ref(storage, path); 

    getDownloadURL(fileRef)
      .then((url) => {
        return url;
      })
      .catch((error) => {
        // Handle errors, potentially authorization issues
        console.error("Error getting download URL:", error);
      });
  }

  function cloudFileClick(path, action) {
    const storage = getStorage();
    const fileRef = ref(storage, path); 
  
    getDownloadURL(fileRef)
      .then((url) => {
        if (action === "view") {
          setReportPath(url);
          history.push(ROUTES.VIEW_REPORT);
        } else if (action === "download") {
          // Create a hidden anchor element
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', ''); // Optional: Set filename if desired 
          document.body.appendChild(link);
  
          // Trigger the download
          link.click();
  
          // Cleanup the temporary element
          link.parentNode.removeChild(link); 
        }
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  }


  async function signOut() {
    try {
      // Sign out the currently authenticated user
      auth.signOut().then(() => {
        console.log('User signed out successfully');
      });
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function editProfilesClick() {
    setEditProfileLoc(user);
  }

  return displayProfile.displayName ? (
    <>
      {loading  ? <Loading src={`/images/users/${displayProfile.photoURL}.png`} /> : <Loading.ReleaseBody />}
      {editMode ? 
          <SelectProfileContainer setEditProfile={setEditProfileLoc}  user={user} setProfile={setProfile} dependants={dependants} editMode={editMode} setEditMode={setEditMode}/>
          :
      <>
      <Header src="joker1" dontShowOnSmallViewPort>
        <Header.Frame>
          <Header.Group>
            <Header.Logo to={ROUTES.LANDING} src={logo} alt="EZPath" />
            <Header.Button onClick={() => editProfilesClick()}>{user.name}'s Details</Header.Button>
            <Header.Button onClick={() => setEditMode(true)}>My Family</Header.Button>
            <Header.ButtonLink to={ROUTES.ORDER_TESTS}>Order a Test for {displayProfile.name}</Header.ButtonLink>
          </Header.Group>
          <Header.Group>
            <Header.Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Header.Profile>
              <Header.Picture src={displayProfile.photoURL} onClick={() => editProfilesClick()}/>
              <Header.TextLink onClick={() => editProfilesClick()}>{displayProfile.displayName}</Header.TextLink>

              <Header.Dropdown>
                <Header.Group>
                  <Header.Button onClick={() => signOut()}>Sign Out</Header.Button>
                </Header.Group>

                {(dependants.includes("empty") || Object.keys(dependants).length === 0) ? null : 
                  (<><Header.Text>Change View To:</Header.Text></>)
                }
                {user.user_id === displayProfile.user_id ? null : (
                  <Header.Group onClick={() => setProfile(user)}>
                    <Header.Picture src={user.photoURL} />
                    <Header.TextLink>{user.displayName}</Header.TextLink>
                  </Header.Group>
                )}

    {/* <Header.Text>{Object.keys(dependants).length}</Header.Text> */}
    {(dependants.includes("empty") || Object.keys(dependants).length === 0) ? null : (
    <>
      {/* <Header.Text>Change View To:</Header.Text> */}
      {dependants.map((item) => (
        item.user_id === displayProfile.user_id ? null : (
          <Header.Group key={item.user_id} onClick={() => setProfile(item)}>
            <Header.Picture src={item.photoURL} data-testid="user-profile" />
            <Header.TextLink>{item.displayName}</Header.TextLink>
          </Header.Group>
        )
      ))}
    </>
  )}
</Header.Dropdown>

            </Header.Profile>
          </Header.Group>
        </Header.Frame>

        <Header.Feature>
          <Header.FeatureCallOut>{displayProfile.displayName}'s Tests</Header.FeatureCallOut>
          {(dependants.includes("empty") || Object.keys(dependants).length === 0)? null: 
          <>
            <Header.Text>Plus {Object.keys(dependants).length} family members. </Header.Text>
          </>}
          <Header.Text>
            Welcome to EZPath where all your health test results are easy to find.
            Join us on a journey to know your health to take the required action for optimum health.
          </Header.Text>
        </Header.Feature>
      </Header>




      <Results.Group>
        {<><Results.ButtonContainer>
          <Results.Heading>Pending Orders</Results.Heading> 
        </Results.ButtonContainer>
        </>}
        { Object.keys(payLaterOrderRows).length === 0 ? 
        <Results>
          <Results.Entities>
          <Results.Item gradient = {gradient}>
          <Results.SubTitle>No Pending Orders for {displayProfile.name}</Results.SubTitle> 
          </Results.Item>
          </Results.Entities>
        </Results> :  
        <>
        <Results.SubTitle>The next step is to have your sample collected.</Results.SubTitle> 
          {payLaterOrderRows.payLaterOrders.map((slideItem) => (
            <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
               {slideItem.data.length === 0? null : (
                <>
                <Results.Title>{slideItem.title}</Results.Title>
                <Results.Entities>
                {slideItem.data.map((item) => {
                    if (item.m_payment_id !== prevPaymentId) {
                      prevPaymentId = item.m_payment_id;
                      gradient = gradient + 1;
                      displayInvoiceLink = true;
                    }             
                    else
                    {displayInvoiceLink = false;}
                  return (
                    <Results.Item id={item.docId} key={item.docId} gradient = {gradient}/* onClick={(event) => handlePayLaterItemClick(event, item)} */>  {/*item={item} href={item.test_report_path}*/}
                    <Results.SubTitle>{item.test_name}</Results.SubTitle>
    
                  <Results.SubTitle>{item.patient_name} {item.patient_surname}</Results.SubTitle>
                    <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                    <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                    <Results.Text>Where: {item.phlebotomy_site_name ? item.phlebotomy_site_name : "not specified"}</Results.Text>
                    {item.requisition_number? <Results.Text>Requisition: {item.requisition_number}</Results.Text>:null}
                    <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text>
                    <Results.Text>Doctor:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                    {(item.ordered_by_name &&  item.ordered_by_surname )?
                      <Results.Text>Ordered By:  {item.ordered_by_name + " " + item.ordered_by_surname}</Results.Text>:
                    <Results.Text>Ordered By: unknown</Results.Text>}
                    <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds*1000).toDateString()}
                    </Results.Text>
                    {(displayInvoiceLink === true && item.invoice_path !== "") ?
                        <Results.Button onClick={() => cloudFileClick(item.invoice_path, "view")}>View Invoice</Results.Button>
                        :
                        null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site !== "4" && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath has assigned a phlebotomist to assist you.</Results.Text>
                        <Results.Text>Please visit your selected venue for sample collection.</Results.Text>
                      </>: null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site === "4" && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath has assigned a mobile phlebotomist to collect your sample.</Results.Text>
                        <Results.Text>They will contact you shortly to arrange.</Results.Text>
                      </> : null
                    }
                    { (item.status=='sampled' && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Your sample has been collected.</Results.Text>
                        <Results.Text>It is on its way to the lab.</Results.Text>
                    </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site !== "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Please visit your selected venue for sample collection.</Results.Text>
                        <Results.Text>Please remember a card for payment.</Results.Text>
                        <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText>
                        </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site === "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath will assign a mobile phlebotomist to collect your sample.</Results.Text>
                        <Results.Text>They will contact you shortly to arrange.</Results.Text>
                        <Results.ActionText id = {item.m_payment_id} onClick={discardPloOrderClick}>...cancel this order</Results.ActionText></>:<>
                      </>
                    }
                  </Results.Item>
                  );
                })}
                </Results.Entities>
                </>
               )}
               </Results>
          ))}
        </>
        }
      </Results.Group>



      <Results.Group>
        {<><Results.ButtonContainer>
          <Results.Heading>Orders in Process</Results.Heading> 
        </Results.ButtonContainer>
        </>}
        { Object.keys(patientOrderRows).length === 0 ? 
        <Results>
          <Results.Entities>
          <Results.Item gradient = {gradient}>
          <Results.SubTitle>No Orders for {displayProfile.displayName}</Results.SubTitle>
          </Results.Item>
          </Results.Entities>
        </Results> :  
        <>
          {patientOrderRows.labOrders.map((slideItem) => (
            <Results id={displayProfile.user_id} key={`${displayProfile.user_id}-${slideItem.title}`}>
               {slideItem.data.length === 0? null : (
                <>
                <Results.Title>{slideItem.title}</Results.Title>
                <Results.Entities>
                {slideItem.data.map((item) => {
                    if (item.m_payment_id !== prevPaymentId) {
                      prevPaymentId = item.m_payment_id;
                      gradient = gradient + 1;
                      displayInvoiceLink = true;
                    }             
                    else
                    {displayInvoiceLink = false;}            
                  return (
                    <Results.Item id={item.docId} key={item.docId} gradient = {gradient}/* onClick={(event) => handlePayLaterItemClick(event, item)} */>  {/*item={item} href={item.test_report_path}*/}
                    <Results.SubTitle>{item.test_name}</Results.SubTitle>
    
                  <Results.SubTitle>{item.patient_name} {item.patient_surname}</Results.SubTitle>
                    <Results.SubTitle>STATUS: {item.status}</Results.SubTitle>
                    <Results.SubTitle>Category: {item.test_category}</Results.SubTitle>
                    <Results.Text>Where: {item.phlebotomy_site_name ? item.phlebotomy_site_name : "not specified"}</Results.Text>
                    {item.requisition_number? <Results.Text>Requisition: {item.requisition_number}</Results.Text>:null}
                    <Results.Text>Practice:  {item.doctors_practice_name}</Results.Text>
                    <Results.Text>Doctor:  {item.doctors_first_name} {item.doctors_surname}</Results.Text>
                    {item.ordered_by ? 
                      <Results.Text>Ordered By:  {item.ordered_by}</Results.Text>:
                    <Results.Text>Ordered By: unknown</Results.Text>}
                    <Results.Text> Ordered Date: {new Date(item.ordered_date.seconds*1000).toDateString()}
                    </Results.Text>
                    { (displayInvoiceLink === true && item.invoice_path !== "") ?
                        <Results.Button onClick={() => cloudFileClick(item.invoice_path, "view")}>View Invoice</Results.Button>
                        :
                        null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site !== "4" && displayInvoiceLink === true)?
                      <>
                      <Results.DividingLine></Results.DividingLine>
                      <Results.Text>EZPath has assigned a phlebotomist to collect your sample.</Results.Text>
                      </> : null
                    }
                    { (item.status=='dispatched' && item.phlebotomy_site === "4" && displayInvoiceLink === true)?
                      <><Results.Text>EZPath has assigned a mobile phlebotomist to collect your sample. They will contact you shortly to arrange.</Results.Text> </>:<></>
                    }
                    { (item.status=='ordered' && item.phlebotomy_site !== "4" && displayInvoiceLink === true) ?
                    <>
                      <Results.DividingLine></Results.DividingLine>
                      <Results.Text>Please visit your selected venue for sample collection.</Results.Text>
                      <Results.ActionText id = {item.m_payment_id} onClick={discardOrderClick}>...cancel this order</Results.ActionText>
                      </> : null
                    }
                    { (item.status=='ordered' && item.phlebotomy_site === "4" && displayInvoiceLink === true) ?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>EZPath will assign a mobile phlebotomist to collect your sample.</Results.Text>
                        <Results.Text>They will contact you shortly to arrange.</Results.Text>
                        <Results.ActionText id = {item.m_payment_id} onClick={discardOrderClick}>...cancel this order</Results.ActionText>
                        </> : null
                    }
                    { (item.status=='sampled' && displayInvoiceLink === true)?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Your sample has been collected.</Results.Text>
                        <Results.Text>It is on its way to the lab.</Results.Text>
                    </> : null
                    }
                    { item.status=='received' && displayInvoiceLink === true?
                      <>
                        <Results.DividingLine></Results.DividingLine>
                        <Results.Text>Your sample is safely at the lab.</Results.Text>
                        <Results.Text>We will let you know when your results are ready.</Results.Text>
                      </> : null
                    }
                  </Results.Item>
                  );
                })}
                </Results.Entities>
                </>
               )}
               </Results>
          ))}
        </>
        }
      </Results.Group>

      <Results.Group>
        {<><Results.LongContainer>
          <Results.Heading>{displayProfile.displayName}'s Test Results</Results.Heading>
          {resultRows[0] !== undefined ? <>
          <Results.TextLink onClick={() => setResultsDisplay('category')}>Order by Category</Results.TextLink>
          <Results.TextLink onClick={() => setResultsDisplay('date')}>Order by Date</Results.TextLink>
          </>:
          null}
        </Results.LongContainer>
        </>}
        { resultRows[0] === undefined ? 
              <Results>
              <Results.Entities>
              <Results.Item gradient = {gradient}>
                <Results.SubTitle>No Results for {displayProfile.displayName}</Results.SubTitle> 
              </Results.Item>
              </Results.Entities>
            </Results> 
          :  
          <>
          {resultRows.results.map((slideItem) => (
            <Results key={`${displayProfile.user_id}-${slideItem.title}`}>
              <Results.Title>{slideItem.title}</Results.Title>
              <Results.Entities>
              {slideItem.data.map((item) => (
                /* <Results.Item key={item.docId} item={item} href={item.test_report_path}> */
                <Results.Item key={item.docId} item={item} onClick={() => cloudFileClick(item.test_report_path, "download")}>
                  <Results.SubTitle>{item.test_name}</Results.SubTitle>
                  <Results.Text> Collection Date: {new Date(item.collection_date.seconds*1000).toDateString()}
                   </Results.Text>
                  <Results.SubTitle>{item.test_name}</Results.SubTitle>
                  <Results.Text>Collection Date: {new Date(item.collection_date.seconds*1000).toDateString()}</Results.Text>
                  <Results.Text>Order Date: {new Date(item.ordered_date.seconds*1000).toDateString()}</Results.Text>
                  <Results.Text>Results Date: {new Date(item.results_expected.seconds*1000).toDateString()}</Results.Text>
                  <Results.Text>Collection Site:  {item.collection_site}</Results.Text>
                  <Results.Text>Phlebotomist:  {item.phlebotomist_id}</Results.Text>
                  {/* todo: only enable download and view buttons if report actually exists in database */}
                  {/* <Results.ButtonDownload><a href={item.test_report_path} target="_blank" download>Download Report</a></Results.ButtonDownload> */}
                  <Results.Button onClick={() => cloudFileClick(item.test_report_path, "download")}>Download Report</Results.Button>
                  
                  {/* <Results.ButtonLink to={ ROUTES.VIEW_REPORT }>View Report</Results.ButtonLink> */}
                  <Results.ButtonDownload onClick={() => cloudFileClick(item.test_report_path, "view") }>View Report</Results.ButtonDownload>
                </Results.Item>
                ))}
              </Results.Entities>
              </Results> 
              ))} 
        </>
        }
      </Results.Group> 
      <FooterContainer />
    </>
    }
    </>
  ) : (
    <SelectProfileContainer editProfile = {editProfile} setEditProfile={setEditProfile} user={user} setProfile={setProfile} editMode={editMode} dependants={dependants} />
  );
}

{/* <SelectProfileContainer setEditProfile={setEditProfileLoc} user={user} setProfile={setProfile} editMode={editMode} dependants={dependants} /> */}