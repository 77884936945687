export const LANDING = '/';
export const PATIENT = '/patient_home';
export const PRACTICE = '/practice_home';
export const LAB = '/lab_home';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const SIGN_IN_HELP = '/signin_help';
export const ORDER_TESTS = '/order_tests';
export const EDIT_TESTS = '/edit_tests';
export const EDIT_SITES = '/edit_sites';
export const HOME = '/home';
export const PATIENT_DETAILS = '/patient_details';
export const LAB_MEMBER_DETAILS = '/lab_member_details';
export const PRACTICE_MEMBER_DETAILS = '/practice_member_details';
export const PATIENT_NEW = '/patient_new';
export const LAB_MEMBER_NEW = '/lab_member_new';
export const PRACTICE_MEMBER_NEW = '/practice_member_new';
export const SELECT_USER = '/select_user';
export const VIEW_REPORT = '/view_report';
export const SELECT_AVATAR = '/select_avatar';
export const CHECKOUT_CANCEL = '/checkout_cancel';
export const CHECKOUT_RETURN = '/checkout_return';
export const CHECKOUT_NOTIFICATION = '/checkout_notification';
export const CHECKOUT_PAY_LATER = '/checkout_pay_later';
export const FOOTER_FAQS = '/footer_faqs';
export const FOOTER_TERMS_OF_USE = '/footer_terms_of_use';
export const FOOTER_CAREERS = '/footer_careers';
export const FOOTER_CONTACT_US = '/footer_contact_us';
export const FOOTER_PRIVACY = '/footer_privacy';
export const FOOTER_PAIA = '/footer_PAIA';
export const FOOTER_COOKIES = '/footer_cookies';
