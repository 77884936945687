import React, { useContext } from 'react';
import { OptForm, AccordionTests, Feature } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';
import { OrderContext } from '../context/OrderContext';


export default function CheckoutCancel({profile}) {

  const history = useHistory();
  const [orderData, setOrderData] = useContext(OrderContext);

  function handleDiscardClick() {
 
    //  console.log("User Profile: ", profile);
    //  console.log("Order Data: ", orderData);

    localStorage.removeItem("selectedTests");

    if (profile.user_id === undefined || profile.user_id === null) {
      history.push(ROUTES.LANDING);
    }
    else {
      history.push(ROUTES.HOME);
    }
    // Clear the order data
    localStorage.removeItem('selectedTests');
    localStorage.removeItem('orderInfo');
    localStorage.removeItem('selectedSite');
  }

  function handleReOrderClick() {
    //  console.log(orderData);
    localStorage.setItem('re-order-attempt',"true");
    history.push(ROUTES.ORDER_TESTS);
  }

  return (
    <>
      <HeaderContainer>
        <AccordionTests>
          <OptForm>
            <Feature.Title>Payment Cancelled</Feature.Title>
            <Feature.SubTitle>What would you like to do next?</Feature.SubTitle>
            <OptForm.Break />
            <OptForm.Break />
            <OptForm.Button onClick={() => handleReOrderClick()}>Try Ordering Again</OptForm.Button>
            
            <OptForm.Text>Modify your order or retry payment</OptForm.Text>
            <OptForm.Break />
            <OptForm.Text>OR</OptForm.Text>
            <OptForm.Break />
            <OptForm.Button onClick={() => handleDiscardClick()}>Discard The Order</OptForm.Button>
            
            <OptForm.Text>Abandon this order attempt</OptForm.Text>
          </OptForm>
          </AccordionTests>
          </HeaderContainer>
      <FooterContainer />
    </>
  );
}