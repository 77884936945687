//in order to load the pdf from firbase we needed to do the following:
//ref: https://www.youtube.com/watch?v=R5zBs28_TVQ
//ref: https://cloud.google.com/storage/docs/configuring-cors#gsutil_1
// configure cross origin resource sharing in Google cloud
// create cors.json to configure the sharing
// install Google cloud services on the local machine
// then run gsutil cors set CORS_CONFIG_FILE gs://bucket_name

import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import {useHistory} from 'react-router-dom';
import {BackButton, ButtonContainer, Container, Link} from './styles/all-pages';

export default function AllPages(props) {
  const [numPages, setNumPages] = useState(null);
  const history = useHistory();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { pdf } = props;
  console.log(pdf);


  return (
    <>
    <Container>
      <BackButton onClick={() => history.goBack()}>Back</BackButton>
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      
      <BackButton onClick={() => history.goBack()}>Back</BackButton>
      <Link href={pdf} target="_blank" download>Download Document</Link>
      
    </Container>
    </>
  );
}