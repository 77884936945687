import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function useDoctors() {
  const [doctors, setDoctors] = useState([]); 
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    const doctorsRef = collection(db, "users");
    const doctorsQuery = query(doctorsRef, where("user_role", "==", "doctor"));

    getDocs(doctorsQuery)
      .then((snapshot) => {
        const allDoctors = snapshot.docs.map((doctorsObj) => ({
          ...doctorsObj.data(),
          docId: doctorsObj.id,
        }));

        // Conditionally add 'empty' if needed
        if (allDoctors.length === 0) { 
          allDoctors.push("empty"); 
        } 

        setDoctors(allDoctors);  
      })
      .catch((error) => {
        console.error(error.message); 
      });
  }, []); 

  return { doctors }; 
}


/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

export default function useDoctors() {
  const [doctors, setDoctors] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
      firebase
      .firestore()
      .collection("users").where("user_role", "==", 'doctor')
      .get()
      .then((snapshot) => {
        const allDoctors = snapshot.docs.map((doctorsObj) => ({
          ...doctorsObj.data(),
          docId: doctorsObj.id,
        }));
        
        if (Object.keys(allDoctors).length === 0) {  //there were no dependants, set the value accordingly
          allDoctors[0] = "empty";
        }
        setDoctors(allDoctors);  //update content state of this component and re-render it, in this case we pass an array we received from the firebase snapshot
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, []);

  return { doctors };

} */