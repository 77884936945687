import React, { useState, useContext, useEffect } from "react";
import {useHistory} from "react-router-dom";
import { FirebaseContext } from "../context/firebase";
import { UserContext } from "../context/UserContext";
import { Form, Profiles } from "../components";
import { HeaderContainer } from "../containers/header";
import { FooterContainer } from "../containers/footer";
import { HeaderBlankContainer } from "../containers/header_blank";
import * as ROUTES from "../constants/routes";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { ChangePasswordContainer } from "../containers/change_password";
import { doc, updateDoc } from 'firebase/firestore';

export default function PracticeMemberDetails({userData}) {
  const history = useHistory();
  const auth = getAuth();
  const [editProfile, setEditProfile] = useContext(UserContext);
  const [error, setError] = useState("");
  const [showPasswordChange, setShowPasswordChange] = useState(false);

  const [isValidNumber, setIsValidNumber] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [mobile_number, setMobile_number] = useState("");
  const [email_address, setEmail_address] = useState("");


  function handlePasswordChange(){
    setShowPasswordChange(!showPasswordChange);
  }


  const editAvatar = (event) => {
    history.push(ROUTES.SELECT_AVATAR);
  }

  // Validate email and mobile
  const isValidMobileNumber = (number) => {
    const testNumber = number.replace(/\s/g, ""); // Remove spaces for the regex test
  
    const regex = /^0\s*[678]\d{1}\s*\d{3}\s*\d{4}$/;
    //return (testNumber === "" || regex.test(testNumber));
    return (regex.test(testNumber));
  };
  
  const isValidEmailAddress = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //return (email === "" || regex.test(email));
    return (regex.test(email));
  };

  const handleMobileChange = (e) => {
    const inputNumber = e.target.value;
    setMobile_number(inputNumber);
  };
  
  useEffect(() => {
    const isValidNumber = isValidMobileNumber(mobile_number);
    setIsValidNumber(isValidNumber);
  }, [mobile_number]);
  
  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail_address(inputEmail);
  };
  
  useEffect(() => {
    const emailValid = isValidEmailAddress(email_address);
    setIsValidEmail(emailValid);
  }, [email_address]);

  useEffect(() => {
    if (editProfile) {
      setMobile_number(editProfile.mobile_number);
      setEmail_address(editProfile.email_address);
    }
  }, [editProfile]);
  // End of validate email and mobile


  useEffect(() => {
   updateDisplay();
  }, []); 

  useEffect(() => {
    if (!showPasswordChange) {
      updateDisplay();
    }
  }, [showPasswordChange]);
  
  function updateDisplay() {
    var radio_box = document.getElementById(editProfile.user_role);
    var body = document.getElementById(editProfile.user_role+"_body");
    
    if (editProfile.user_role == userData.user_role) {
      document.getElementById("doctor_body").style.display = "none";
      document.getElementById("admin_body").style.display = "none";
      document.getElementById("manager_body").style.display = "none";
      document.getElementById("user_body").style.display = "none";
    }

    if (Object.keys(radio_box).length !== 0) { //object exists
      radio_box.style.display = "inline-block";
      radio_box.checked = true;
      if (Object.keys(body).length !== 0) { //object exists
        body.style.display = "block";
      }
    }

    var sms_box = document.getElementById("user-sms");
    var whatsapp_box = document.getElementById("user-whatsapp");
    var email_box = document.getElementById("user-email");
    var prelim_box = document.getElementById("doctor-notify-prelim");
    var abnormal_box = document.getElementById("doctor-notify-abnormal");
    var release_box = document.getElementById("release-results-normal");
    var release_edit = document.getElementById("release-period");


    if (sms_box && editProfile.comms_sms) { //object exists
      sms_box.checked = (editProfile.comms_sms === "true");
    }
    if (whatsapp_box && editProfile.comms_whatsapp) { //object exists
      whatsapp_box.checked = (editProfile.comms_whatsapp === "true");
    }
    if (email_box && editProfile.comms_email) { //object exists
      email_box.checked = (editProfile.comms_email === "true");
    }
    if (prelim_box && editProfile.report_notify_prelim) { //object exists
      prelim_box.checked = (editProfile.report_notify_prelim === "true");
    }
    if (abnormal_box && editProfile.report_notify_abnormal) { //object exists
      abnormal_box.checked = (editProfile.report_notify_abnormal === "true");
    }
    if (release_box && editProfile.report_auto_release) { //object exists
      release_box.checked = (editProfile.report_auto_release === "true");
    }
    if (release_edit && editProfile.report_release_period) { //object exists
      release_edit.value = (editProfile.report_release_period);
    }
  }

  function toSentenceCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function readUserRole() {
    var role = "";

    var is_checked = document.getElementById("admin").checked;
    if (is_checked == true) {role = "admin";}

    var is_checked = document.getElementById("doctor").checked;
    if (is_checked == true) {role = "doctor";}
    
    is_checked = document.getElementById("manager").checked;
    if (is_checked == true) {role = "manager";}

    is_checked = document.getElementById("user").checked;
    if (is_checked == true) {role = "user";}

    return role;
  }

  function readUserComms() {
    var sms = "false";
    var whatsapp = "false";
    var email = "false";

    var is_checked = document.getElementById("user-sms").checked;
    if (is_checked == true) {sms = "true";}

    is_checked = document.getElementById("user-whatsapp").checked;
    if (is_checked == true) {whatsapp = "true";}
    
    is_checked = document.getElementById("user-email").checked;
    if (is_checked == true) {email = "true";}

    return [sms, whatsapp, email];
  }


  function readReportPreferences() {
    var notifyPrelim = "invalid";
    var notifyAbnormal = "invalid";
    var releaseToPatient = "invalid";
    var releasePeriod = "0";

    if (editProfile.user_role === "doctor") {
      var is_checked = document.getElementById("doctor-notify-prelim").checked;
      if (is_checked == true) {notifyPrelim = "true";}

      is_checked = document.getElementById("doctor-notify-abnormal").checked;
      if (is_checked == true) {notifyAbnormal = "true";}

      is_checked = document.getElementById("release-results-normal").checked;
      if (is_checked == true) {releaseToPatient = "true";}

      releasePeriod = document.getElementById("release-period").value;
    }

    return [notifyPrelim, notifyAbnormal, releaseToPatient, releasePeriod];
  }


  //users need to update their email addresses when they are logged in themselves as this is what is allowed for by Firebase Auth
  const handleUpdate = async (event) => {  
    setError("");
    event.preventDefault();
    var role = readUserRole();
    var comms = readUserComms();
    var reportPreferences = readReportPreferences();

    var doc_id = editProfile.docId;
    var name = document.getElementById("name").value;
    var surname = document.getElementById("surname").value;
    var national_id = document.getElementById("national_id").value;
    var mobile_number = document.getElementById("mobile_number").value;
    var email_address = document.getElementById("email_address").value;

    var comms_sms = comms[0];
    var comms_whatsapp = comms[1];
    var comms_email = comms[2];

    var report_notify_prelim = reportPreferences[0];
    var report_notify_abnormal = reportPreferences[1];
    var report_auto_release = reportPreferences[2];
    var report_release_period = reportPreferences[3];

    //const result = updateDisplayNameAndEmail(name,email_address);
    
    //if (result === true) {
    try {  
      const db = getFirestore(); // Initialize Firestore if you haven't already
      const userRef = doc(db, "users", doc_id);

      await updateDoc(userRef, {
        name: name,
        surname: surname,
        national_id: national_id,
        mobile_number: mobile_number,
        //email_address: email_address,
        user_role: role,
        comms_sms: comms_sms,
        comms_whatsapp: comms_whatsapp,
        comms_email: comms_email,
        report_notify_prelim: report_notify_prelim,
        report_notify_abnormal: report_notify_abnormal,
        report_auto_release: report_auto_release,
        report_release_period: report_release_period,
      }) // Data saved successfully!
        console.log("PRACTICE MEMBER DETAIL SAVE: SUCCESFUL");
        editProfile.name = name;
        editProfile.surname = surname;
        editProfile.national_id = national_id;
        editProfile.mobile_number = mobile_number;
        editProfile.email_address = email_address;
        editProfile.comms_sms = comms_sms;
        editProfile.comms_whatsapp = comms_whatsapp;
        editProfile.comms_email = comms_email;
        editProfile.report_notify_prelim = report_notify_prelim;
        editProfile.report_notify_abnormal = report_notify_abnormal;
        editProfile.report_auto_release = report_auto_release;
        editProfile.report_release_period = report_release_period;
          //todo: when user role changes up or down we need to set it in the user object?
          //a person cannot change their own user role, it must be done by a third party to prevent role change GUI issues and to prevent accidental user lockouts
          history.goBack();
          //history.push(ROUTES.HOME);
      }
      catch (error) {
          // The write failed...
          console.log("PRACTICE MEMBER DETAIL SAVE: UNSUCCESFUL");
          console.log(error);
          setError(error.message);
        };
      //}
  };

  // Function to update the display name and email of a specific user identified by UID
  function updateDisplayNameAndEmail(name,email_address) {
   
      auth
        .currentUser
        .updateProfile({
          displayName: name
        })
        .catch((error) => {
          console.log("Error updating user:", error);
          return false;
        });     
        return true;
        //.updateEmail(email_address)
        //.then(() => {
          // Profile updated successfully!
          //editProfile.name = name;
          //editProfile.email_address = email_address;
         // return true;
       // })
       // .catch((error) => {
        //  setError(error);
       //   return false;
       // });
  }

  function handleBackClick() {  
    //history.push(ROUTES.PRACTICE);
    history.goBack();
    console.log("history pushed routes.practice");
    //history.push(ROUTES.SELECT_USER);
  }

  return (
    <>
      { (showPasswordChange === true)?
      <>
      <HeaderBlankContainer>
  <Form>
    <Form.LongLeftContainer>
      <Form.SubButton onClick={handlePasswordChange}>Back</Form.SubButton>
    </Form.LongLeftContainer>
    <ChangePasswordContainer 
      editProfile={editProfile} 
      setEditProfile={setEditProfile} 
    />
  </Form>
</HeaderBlankContainer>
      </>
    :
      <>
      <HeaderBlankContainer>
        <Form>
        <Form.LongLeftContainer>
            <Form.SubButton onClick={() => handleBackClick()} >Back</Form.SubButton>
          {(userData.user_id === editProfile.user_id)?
            <Form.SubButton onClick = {handlePasswordChange}>Change Password</Form.SubButton>
            :<></>}
          </Form.LongLeftContainer>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
        <Form.Title>Edit Practice Member Details</Form.Title> 
        {error && <Form.Error data-testid="error">{error}</Form.Error>}
        <Form.LongLeftContainer>
          <div>
            <Profiles.OverlayPicture src={"edit_overlay"} onClick={() => editAvatar()}></Profiles.OverlayPicture> 
            <Profiles.Picture src={editProfile.photoURL}></Profiles.Picture>
          </div>
          <Form.Text>{editProfile.name} {editProfile.surname}</Form.Text>
        </Form.LongLeftContainer>
        <Form.LongContainer>  
        </Form.LongContainer>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}
          <Form.Base onSubmit={handleUpdate} method="POST">
            <Form.Text>User Role:</Form.Text>
            <Form.Body id = "doctor_body"><Form.RadioBox id = "doctor" name="roleBox"></Form.RadioBox>{toSentenceCase(editProfile.user_profession)}</Form.Body>
            <Form.Body id = "admin_body"><Form.RadioBox id = "admin" name="roleBox"></Form.RadioBox>Administrator</Form.Body>
            <Form.Body id = "manager_body"><Form.RadioBox id = "manager" name="roleBox"></Form.RadioBox>Manager</Form.Body>
            <Form.Body id = "user_body"><Form.RadioBox id = "user" name="roleBox"></Form.RadioBox>User</Form.Body>
            <Form.Input
              placeholder="Name"
              defaultValue={editProfile.name}
              id = "name"
            />
            <Form.Input
              placeholder="Surname"
              defaultValue={editProfile.surname}
              id = "surname"
            />
            <Form.Input
              placeholder="Identity Number"
              defaultValue={editProfile.national_id}
              id = "national_id"
            />
            <Form.Input
              placeholder="Mobile Number in the form 0821231234"
              defaultValue={editProfile.mobile_number}
              id = "mobile_number"
              onChange={handleMobileChange}
            />
            {!isValidNumber && (
              <div>Valid mobile number required in the form 0821231234</div>
            )} 
            { editProfile.user_id == userData.user_id ? 
            <>
{/*             <Form.Input
              placeholder="Email address"
              defaultValue={editProfile.email_address}
              id = "email_address"
              onChange={handleEmailChange}
            /> */}
            <Form.Input disabled type="email" placeholder="Email" 
              style={{background: 'rgba(200,200,200,0.75)'}}
              id = "email_address" 
              value={editProfile.email_address}/>
{/*             {!isValidEmail && (
              <div>Valid email address required</div>
            )}  */}
            </>:
            <>
              <Form.Input
                placeholder="Email address"
                defaultValue={editProfile.email_address}
                id = "email_address"
                disabled
                onChange={handleEmailChange}
              />
              {!isValidEmail && (
              <div>Valid email address required</div>
            )} 
            </>
            }
            <Form.Text>Messaging Preference (Default): </Form.Text>
            <Form.LongLeftContainer>
              <Form.CheckBox id = "user-sms"></Form.CheckBox>
              <Form.Text>SMS</Form.Text>
              <Form.CheckBox id = "user-whatsapp"></Form.CheckBox>
              <Form.Text>WhatsApp</Form.Text>
              <Form.CheckBox id = "user-email"></Form.CheckBox>
              <Form.Text>Email</Form.Text>
            </Form.LongLeftContainer>

            { editProfile.user_role === "doctor"? 
              <>
                <Form.Text>Notify {toSentenceCase(editProfile.user_profession)} of (Default): </Form.Text>
                <Form.LongLeftContainer>
                  <Form.CheckBox id = "doctor-notify-prelim"></Form.CheckBox>
                  <Form.Text>Preliminary Results</Form.Text>
                  <Form.CheckBox id = "doctor-notify-abnormal"></Form.CheckBox>
                  <Form.Text>Abnormal Results</Form.Text>
                </Form.LongLeftContainer>
                <Form.Text>Release Results to Patient (Default): </Form.Text>
                <Form.LongLeftContainer>
                  <Form.CheckBox id = "release-results-normal"></Form.CheckBox>
                  <Form.Text>If all in-range after </Form.Text>
                  <Form.InputNarrow placeholder="0" id = "release-period"></Form.InputNarrow> 
                  <Form.Text> hours</Form.Text>
                </Form.LongLeftContainer>
              </>

              :
              <></>
            }

            {/* <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              id = "password"
            /> */}
            {error && <Form.Error data-testid="error">{error}</Form.Error>}
            { (isValidEmail && isValidNumber)?
              <>
                <Form.Button type="submit">
                  Update Details
                </Form.Button>
              </>:
                <>
                <Form.Button disabled type="submit" >
                  Update Details
                </Form.Button>
              </>
            }
          </Form.Base>
        </Form>
      </HeaderBlankContainer>
    </>
    }
    </>
  );
}