import React, { useState, useEffect } from 'react';
import { Popup } from '../components';
import 'react-datepicker/dist/react-datepicker.css';


export function MessageContainer({trigger, setTrigger, title, caption, line1, line2, confirmButton, rejectButton, messageCode, setMessageCode}) {

    return (
        <Popup trigger = {trigger} fullScreen = {false}>
            <Popup.Title>{title}</Popup.Title>
            <Popup.Caption>{caption}</Popup.Caption>
            <Popup.Text>{line1}</Popup.Text>
            <Popup.Text>{line2}</Popup.Text>
            <Popup.LongContainer>
            <Popup.ConfirmButton setTrigger={setTrigger} setMessageCode={setMessageCode} messageCode={messageCode}>
                {confirmButton}
            </Popup.ConfirmButton>
            {rejectButton && rejectButton !== "" ? (
                <Popup.CloseButton setTrigger={setTrigger}>{rejectButton}</Popup.CloseButton>
            ) : (
                <></>
            )}
            </Popup.LongContainer>
        </Popup>
            )
}
