import React from 'react';
import { PracticeMemberNewContainer } from '../containers/practice_member_new';



export default function PracticeMemberNew({userData}) {

  let props = {
    userData: userData
  }

  return <PracticeMemberNewContainer props={props} />;
}

