export default function selectionFilterPayLaterOrders(payLaterOrders, orderby, condensed) {
  const payLaterOrdersArr = [];
  let index = 0;
  let UniqueOrderedArray = [];
  let UniqueTitleArray = [];
  let filteredData = [];

  if (orderby === 'test_category') {
    // Sorting by test_category, secondary sorting by ordered_date timestamp
    UniqueTitleArray = [...new Set(payLaterOrders.map(data => data.test_category))];
    for (index = 0; index < UniqueTitleArray.length; index++) {
      filteredData = payLaterOrders?.filter((item) => item.test_category === UniqueTitleArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      payLaterOrdersArr.push({ title: UniqueTitleArray[index], data: filteredData });
    }
  }
  else if (orderby === 'status') {
    // Sorting by status, secondary sorting by ordered_date timestamp
    UniqueOrderedArray = ["ordered","dispatched","sampled","received"];
    UniqueTitleArray = ["Ordered - Order submitted by patient or doctor",
      "Dispatched - Phlebotomist made appointment to collect sample",
      "Sampled - Sample collected by phlebotomist and en-route to the lab",
      "Received - sample received at the lab and successful payment made"];
    for (index = 0; index < UniqueOrderedArray.length; index++) {
      filteredData = payLaterOrders?.filter((item) => item.status === UniqueOrderedArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      if (filteredData.length > 0) {
        payLaterOrdersArr.push({ title: UniqueTitleArray[index], data: filteredData });
      }
    }
  }
/*   else if (orderby === 'ordered_date') {
    // Sorting by ordered_date, no secondary sorting needed as it's already the primary sorting parameter
    const UniqueTitleArray = [... new Set(payLaterOrders.map(data => (new Date(data.ordered_date.seconds*1000).toDateString())))];
    for (index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = payLaterOrders?.filter((item) => new Date(item.ordered_date.seconds*1000).toDateString() === UniqueTitleArray[index]);
      filteredData.sort((a, b) => {
        const dateA = a.ordered_date.seconds * 1000 + a.ordered_date.nanoseconds / 1000000;
        const dateB = b.ordered_date.seconds * 1000 + b.ordered_date.nanoseconds / 1000000;
        
        return dateA - dateB; // Sorting in descending order
      });
      payLaterOrdersArr.push({ title: UniqueTitleArray[index], data: filteredData });
    }
  } */
    else if (orderby === 'ordered_date') {

      // Sorting labOrders by ordered_date
      payLaterOrders.sort((a, b) => a.ordered_date.seconds - b.ordered_date.seconds);
      UniqueTitleArray = [... new Set(payLaterOrders.map(data => (new Date(data.ordered_date.seconds*1000).toDateString())))];
      //uniqueOrderedDatesArr.sort((a, b) => a - b); // Sort dates in ascending order
  
      for (index = UniqueTitleArray.length - 1; index >= 0; index--) {
        filteredData = payLaterOrders?.filter((item) => new Date(item.ordered_date.seconds*1000).toDateString() === UniqueTitleArray[index]);
        filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
        payLaterOrdersArr.push({
          title: UniqueTitleArray[index],
          data: filteredData });
      }
    }
  else if (orderby === 'doctor_surname') {
    // Sorting by doctor_surname, secondary sorting by ordered_date timestamp
    UniqueTitleArray = [... new Set(payLaterOrders.map(data => data.doctors_surname || "No Doctor Assigned"))]; // Update here
    const updatedLabOrders = payLaterOrders.map(order => ({
      ...order,
      doctors_surname: order.doctors_surname ? order.doctors_surname : "No Doctor Assigned"
    }));
    for (index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = updatedLabOrders?.filter((item) => item.doctors_surname === UniqueTitleArray[index]);
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      payLaterOrdersArr.push({
        title: UniqueTitleArray[index],
        data: filteredData });
    }
  }
  else if (orderby === 'requisition_number') {
    // Sorting by requisition_number, secondary sorting by ordered_date timestamp
    const UniqueOrderedArray = [...new Set(payLaterOrders.map(data => data.requisition_number))];
    const UniqueTitleArray = UniqueOrderedArray.map(value => (value === "" || value === undefined) ? "No Requisition" : value);
    for (let index = UniqueTitleArray.length - 1; index >= 0; index--) {
      filteredData = payLaterOrders?.filter((item) => {
        // Check for both empty string and undefined
        return item.requisition_number === UniqueTitleArray[index] || (item.requisition_number === undefined && UniqueTitleArray[index] === "No Requisition");
      });
      filteredData.sort((a, b) => b.ordered_date.seconds - a.ordered_date.seconds); // Secondary sorting
      payLaterOrdersArr.push({
        title: UniqueTitleArray[index],
        data: filteredData
      });
    }
  }

  if (condensed === true) {
    for (const group of payLaterOrdersArr) {
      const paymentIdGroups = group.data.reduce((groups, order) => {
        const paymentId = order.m_payment_id;
        groups[paymentId] = groups[paymentId] || [];
        groups[paymentId].push(order);  
        return groups;
      }, {}); 
    
      // Transform the grouped data
      group.data = Object.values(paymentIdGroups);
    }
}

  return { payLaterOrders: payLaterOrdersArr };
}
