import React, { useState, useContext, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import { FirebaseContext } from '../context/firebase';
import { UserContext } from '../context/UserContext';
import { Form, Profiles } from '../components';
import { HeaderBlankContainer } from '../containers/header_blank';
import { FooterContainer } from '../containers/footer';
import * as ROUTES from '../constants/routes';
import { ChangePasswordContainer } from '../containers/change_password';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';

export default function LabMemberDetails({userData}) {
  const history = useHistory();
  // const { firebase } = useContext(FirebaseContext);
  const [editProfile, setEditProfile] = useContext(UserContext);
  const [error, setError] = useState('');
  // const firestore = getFirestore();
  const [showPasswordChange, setShowPasswordChange] = useState(false);

  
  function handlePasswordChange(){
    setShowPasswordChange(!showPasswordChange);
  }

  const editAvatar = (event) => {
    history.push(ROUTES.SELECT_AVATAR);
  }

  

  useEffect(() => {
    if(userData) {
      console.log(userData);
      }
    }, [userData]);


  useEffect(() => {
    var check_box = document.getElementById(editProfile.user_role);
    var body = document.getElementById(editProfile.user_role+'_body');
    
    if (editProfile.user_role == userData.user_role) {
      document.getElementById("admin_body").style.display = 'none';
      document.getElementById("manager_body").style.display = 'none';
      document.getElementById("user_body").style.display = 'none';
      document.getElementById("phlebotomist_body").style.display = 'none';
    }

    if (check_box && Object.keys(check_box).length !== 0) { //object exists
      check_box.style.display = 'inline-block';
      check_box.checked = true;
      if (Object.keys(body).length !== 0) { //object exists
        body.style.display = 'block';
      }
    }

  }, []); 

  function readUserRole() {
    var is_checked = document.getElementById('admin').checked;
    var role = '';

    if (is_checked == true) {role = 'admin';}
    is_checked = document.getElementById('manager').checked;
    if (is_checked == true) {role = 'manager';}
    is_checked = document.getElementById('user').checked;
    if (is_checked == true) {role = 'user';}
    is_checked = document.getElementById('phlebotomist').checked;
    if (is_checked == true) {role = 'phlebotomist';}

    return role;
  }

  const handleUpdate = async (event) => {
    event.preventDefault();
    const role = readUserRole(); // Assuming you have this function defined
  
    const docId = editProfile.docId;
    const name = document.getElementById('name').value;
    const surname = document.getElementById('surname').value;
    const nationalId = document.getElementById('national_id').value;
    const mobileNumber = document.getElementById('mobile_number').value;
    // const emailAddress = document.getElementById('email_address').value;
  
    const db = getFirestore(); // Initialize Firestore
    const userDocRef = doc(db, 'users', docId);
  
    try {
      await updateDoc(userDocRef, {
        name: name,
        surname: surname,
        national_id: nationalId,
        mobile_number: mobileNumber,
        // email_address: emailAddress,
        user_role: role
      });
  
        // Data saved successfully!
        console.log("LAB MEMBER DETAIL SAVE: SUCCESFUL");
        editProfile.name = name;
        editProfile.surname = surname;
        editProfile.national_id = nationalId;
        editProfile.mobile_number = mobileNumber;
        // editProfile.email_address = emailAddress;
        //todo: when user role changes up or down we need to set it in the user object?
        //a person cannot change their own user role, it must be done by a third party to prevent role change GUI issues and to prevent accidental user lockouts
        setEditProfile({});
        history.goBack();
        //history.push(ROUTES.HOME);
    }catch(error) {
      // The write failed...
      console.log("LAB MEMBER DETAIL SAVE: UNSUCCESFUL");
      console.log(error);
    }
  };

  function handleBackClick() {  
  
    //history.push(ROUTES.LAB);
    setEditProfile({});
    history.goBack();
    //console.log("history pushed routes.lab");
    //history.push(ROUTES.SELECT_USER);
  }

  return (
    <>
      { (showPasswordChange === true)?
      <>
      <HeaderBlankContainer>
        <Form>
        <Form.LongLeftContainer>
          <Form.SubButton onClick = {handlePasswordChange}>Back</Form.SubButton>
        </Form.LongLeftContainer>
        <ChangePasswordContainer>
          editProfile={editProfile} 
          setEditProfile={setEditProfile} 
        </ChangePasswordContainer>
        </Form>
        </HeaderBlankContainer>
      </>
    :
    <>
      <HeaderBlankContainer>
        <Form>
        <Form.LongLeftContainer>
            <Form.SubButton onClick={() => handleBackClick()} >Back</Form.SubButton>
          {(userData.user_id === editProfile.user_id)?
            <Form.SubButton onClick = {handlePasswordChange}>Change Password or Email</Form.SubButton>
            :<></>}
          </Form.LongLeftContainer>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
        <Form.Title>Edit Lab Member Details</Form.Title> 
        {error && <Form.Error data-testid="error">{error}</Form.Error>}
        <Form.LongLeftContainer>
          <div>
            <Profiles.OverlayPicture src={"edit_overlay"} onClick={() => editAvatar()}></Profiles.OverlayPicture> 
            <Profiles.Picture src={editProfile.photoURL}></Profiles.Picture>
          </div>
          <Form.Text>{editProfile.name} {editProfile.surname}</Form.Text>
        </Form.LongLeftContainer>
        <Form.LongContainer>  
        </Form.LongContainer>
          {error && <Form.Error data-testid="error">{error}</Form.Error>}
          <Form.Base onSubmit={handleUpdate} method="POST">
            <Form.Text>User Role:</Form.Text>
            <Form.Body id = 'admin_body'><Form.RadioBox id = 'admin' name='roleBox'></Form.RadioBox>Administrator</Form.Body>
            <Form.Body id = 'manager_body'><Form.RadioBox id = 'manager' name='roleBox'></Form.RadioBox>Manager</Form.Body>
            <Form.Body id = 'user_body'><Form.RadioBox id = 'user' name='roleBox'></Form.RadioBox>User</Form.Body>
            <Form.Body id = 'phlebotomist_body'><Form.RadioBox id = 'phlebotomist' name='roleBox'></Form.RadioBox>Phlebotomist</Form.Body>
            <Form.Input
              placeholder="Name"
              defaultValue={editProfile.name}
              id = "name"
            />
            <Form.Input
              placeholder="Surname"
              defaultValue={editProfile.surname}
              id = "surname"
            />
            <Form.Input
              placeholder="Identity Number"
              defaultValue={editProfile.national_id}
              id = "national_id"
            />
            <Form.Input
              placeholder="Mobile Number"
              defaultValue={editProfile.mobile_number}
              id = "mobile_number"
            />
{/*             <Form.Input
              placeholder="Email address"
              defaultValue={editProfile.email_address}
              id = "email_address"
            /> */}
            <Form.Input disabled type="email" placeholder="Email" 
              style={{background: 'rgba(200,200,200,0.75)'}} 
              value={editProfile.email_address}/>
            {/* <Form.Input
              type="password"
              value={password}
              autoComplete="off"
              placeholder="Password"
              id = "password"
            /> */}
            <Form.Button type="submit" > {/*onclick={handleUpdate}*/}
              Update Details
            </Form.Button>
          </Form.Base>
        </Form>
        </HeaderBlankContainer>
    </>
    }
    </>
  );
}