import React, { useContext, useEffect } from 'react';
import { Feature, OptForm, AccordionTests } from '../components';
import { HeaderContainer } from '../containers/header';
import { FooterContainer } from '../containers/footer';
import { useHistory } from 'react-router-dom';
import * as ROUTES from '../constants/routes';


export default function CheckoutPayLater({profile}) {

  const history = useHistory();

  function handleReturnClick() {

    if (profile.user_id === undefined || profile.user_id === null) {
      history.push(ROUTES.LANDING);
    }
    else {
      history.push(ROUTES.HOME);
    }
  }

//clear existing orders in the system
useEffect(() => {
  window.scrollTo(0,0);
  // Clear the order data
  localStorage.removeItem('selectedTests');
  localStorage.removeItem('orderInfo');
  localStorage.removeItem('selectedSite');
}, []);


  return (
    <>
      <HeaderContainer>
          <OptForm>
          <AccordionTests>
            <Feature.Title>Order Success</Feature.Title>
            <OptForm.LeftText>You can expect a call from EZPath shortly to arrange sample collection.</OptForm.LeftText>
            <OptForm.LeftText>You will receive an invoice and further instructions by email or WhatsApp</OptForm.LeftText>
            <OptForm.Break />
            <OptForm.LeftText>Payment can occur via card when you meet the phlebotomist</OptForm.LeftText>
            <OptForm.Break />
            <OptForm.Break />
            <OptForm.Button onClick={() => handleReturnClick()}>Return</OptForm.Button>
            <OptForm.Break />
            
            <OptForm.Text>Having trouble or have questions? Let us help you:</OptForm.Text>
            <OptForm.Text>Mobile: 073 178 2327</OptForm.Text>
            <OptForm.Text>WhatsApp: 067 231 7355</OptForm.Text>
            <OptForm.Text>Email: customercare@ezpath.co.za</OptForm.Text>
          </AccordionTests>
          </OptForm>
      </HeaderContainer>
      <FooterContainer />
    </>
  );
}