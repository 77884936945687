import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { collection, query, where, orderBy, onSnapshot, getFirestore } from 'firebase/firestore';

export default function useResults(profile) {
  const [results, setResults] = useState([]);  
  const { firebase } = useContext(FirebaseContext);

// ... (Rest of your imports) 

useEffect(() => {
    let unsubscribe = () => {}; 

    if (Object.keys(profile).length !== 0) {
        const db = getFirestore(); // Get Firestore instance

        const testResultsRef = collection(db, "test_results");
        const resultsQuery = query(
            testResultsRef, 
            where("patient_id", "==", profile.user_id),
            where('linked_patient_id', '==', profile.user_id),
            // orderBy("request_date")
            orderBy("ordered_date") 
        ); 

        unsubscribe = onSnapshot(resultsQuery, (snapshot) => {
            const allResults = snapshot.docs.map((resultsObj) => ({
                ...resultsObj.data(),
                docId: resultsObj.id,
            }));
            setResults(allResults);
        }, (error) => {
            console.log(error.message);
        });
    }

    return unsubscribe; // Cleanup function is the return 
}, [profile]); 

/*   useEffect(() => {
    let unsubscribe = () => {}; // Initialize an unsubscribe function

    if (Object.keys(profile).length !== 0) {
      const query = firebase
        .firestore()
        .collection("test_results")
        .where("patient_id", "==", profile.user_id)
        .where('linked_patient_id', '==', profile.user_id)
        .orderBy("request_date");

      unsubscribe = query.onSnapshot((snapshot) => {
        const allResults = snapshot.docs.map((resultsObj) => ({
          ...resultsObj.data(),
          docId: resultsObj.id,
        }));
        setResults(allResults);
      }, (error) => {
        console.log(error.message);
      });
    }

    return () => {
      // Clean up the listener when the component unmounts
      unsubscribe();
    }; 

  }, [profile]);*/

  return { results };
}
