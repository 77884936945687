import styled from 'styled-components/macro';


export const Background = styled.div`
 display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh; 
  // overflow: auto; 
  background: rgba(255,255,255,1.0);
  z-index: 105;
  overflow-y: auto; /* Enable vertical scrollbar only if content overflows */
  overflow-x: hidden;
`;

export const Container = styled.div`
  margin: 1% 10% 5% 10%; 
  flex-direction: column;
  align-items: top;
  width: 80%;

  // min-height: auto;  
  height: auto;
  // overflow: scroll; 
  // max-height: 95%; // Limit to 95% of viewport to prevent overflow
  border-radius: 12px;
  background: rgba(255,255,255,1.0);
  z-index: 105;
`;

export const FittedContainer = styled.div`
  margin: 5% 5% 5% 5%; /* Center horizontally */
  display: flex;
  padding: 15px 15px;
  flex-direction: column;
  // overflow: scroll; 
  height: auto;

  /* Remove fixed positioning, allow flexbox to center */
  position: relative;  
  align-items: center;
  justify-content: center; 
  border-radius: 15px;
  background: rgba(255,255,255,1.0);
  z-index: 105;
  margin-bottom: 20px;
`;

export const Space = styled.div`
  margin-top: 400px;
`;

export const LeftText = styled.div`
margin-right: auto;
font-size: 15px;
margin-left: 15px;
`;

export const Heading = styled.div`
margin-right: auto;
font-size: 20px;
text-decoration: bold;
margin-left: 5x;
margin-top: 25px;
margin-bottom: 10px;
`;

export const CloseButton = styled.button`
  display: block;
  background-color: rgba(200,100,20,1.0);
  height: fit-content;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  color: rgba(255,255,255,1.0);
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  @media print {
    display:none;
  }
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const Button = styled.button`
  display: block;
  background-color: rgba(200,100,20,1.0);
  height: fit-content;
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: 10px;
  color: white;
  border: 0;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 17px;
  cursor: pointer;
  text-decoration: none;
  @media print {
    display:none;
  }
  &:hover {
    background: rgba(220,140,30,0.90);
  }
`;

export const LongContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: left;
margin-bottom: 15px;
margin-top: 15px;
height: fit-content;
`;


