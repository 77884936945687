// Called when demonstrator clicks "Process Demo".
// Adds document firebase trigger_results_demo collection.
// The cloud function triggerResultsDemo which sends results for a demonstration test.
// The default Requisiton Number in the cloud function is 500024396
// This Requisition Number is not removed from the release queue, so the queue runs until this number is reached.
import { addDoc, serverTimestamp, collection } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';

export default async function writeTriggerReleaseReports() {
  try {
    const db = getFirestore();
    const collectionRef = collection(db, 'trigger_release_reports'); // Reference to the collection

    const newDocData = {
      timestamp: serverTimestamp(),
      // Add any other fields you want to include in the new document
    };

    const docRef = await addDoc(collectionRef, newDocData); 
    console.log("Document written with ID: ", docRef.id);
    alert("Report Release Successfully Triggered");
  } catch (error) {
    console.error("writeTriggerReleaseReports: Error creating document:", error);
    alert("Error in Report Release Trigger");
  }
}