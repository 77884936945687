import React from "react";

export const OrderContext = React.createContext();

/* export const User2Provider = ({ children }) => {
  const [name, setName] = React.useState('Steven');
  return (
    <User2Context.Provider value={{name,setName}}>
      {children}
    </User2Context.Provider>
  );
}; */