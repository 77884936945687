import React, { useState, useContext, useEffect } from 'react';
import { Form, Loading} from '../components';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential, updatePassword, sendPasswordResetEmail, updateEmail} from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ClipLoader } from 'react-spinners';

export function ChangePasswordContainer({ editProfile, setEditProfile }) {
  const auth = getAuth();
  const user = auth.currentUser;
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [emailPassword, setEmailPassword] = useState("");
  const [email, setEmail] = useState(user.email);
  const [newEmail, setNewEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [newEmailRepeat, setNewEmailRepeat] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

// Simple email validation using a regular expression
const validateEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email.toLowerCase());
};

useEffect(() => {

  const emailValid = validateEmail(newEmail);
  setIsEmailValid(emailValid && newEmail === newEmailRepeat);

}, [newEmail, newEmailRepeat]);

  function handleChangePassword() {
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
  
    reauthenticateWithCredential(user, credential)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => setMessage('Password updated successfully'))
          .catch((error) => setMessage('Error updating password'));
      })
      .catch((error) => setMessage('Error reauthenticating user'));
  };

  const handleSendResetLink = () => {

    setIsLoading(true);
    const auth = getAuth();

    sendPasswordResetEmail(auth, email)
      .then(() => {
          console.log("PASSWORD RESET EMAIL SENT");
          setMessage("An email has been sent with instructions to reset your password.")
      })
      .catch((error) => {
          console.log(error.message);
          setMessage(error.message);
      });
      setIsLoading(false);
};


const handleChangeEmail  = async () => {

  setIsLoading(true);
  const credential = EmailAuthProvider.credential(user.email, emailPassword);

  try {
        await reauthenticateWithCredential(user, credential);
        await updateEmail(user, newEmail);
        
        // 2. If successful, find the Firestore document and update it
        const db = getFirestore();        
        const usersCollection = collection(db, "users");
        const q = query(usersCollection, where("user_id", "==", auth.currentUser.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0]; // Assuming there's only one matching document
          await updateDoc(userDoc.ref, { email_address: newEmail });
          setEditProfile({
            ...editProfile, // Copy all existing properties
            email_address: newEmail // Update the email_address
          });
          console.log("Email updated successfully in both Auth and Firestore!");
          setMessage("Email updated successfully!");
        } else {
          console.error("Email update error, please contact your administrator");
          setMessage("Email update error, please contact your administrator");
        }

        setEmail(newEmail);
      } catch(error) {
      // An error occurred
      console.error("Error updating email:", error);
      setMessage(error.message);
      // ... Handle UI updates or display an error message
      }
      setIsLoading(false);
  }

  return (
        <>
       {/*  {isLoading  ? <Loading src={`/images/users/1.png`} /> : <Loading.ReleaseBody />} */}
      {/* {isLoading ? ( 
        <ClipLoader 
          color="#CC00DD50" // Set the color (hex, rgb, etc.)
          loading={isLoading} 
          size={150} // Adjust the size as needed
          aria-label="Loading Spinner"
          data-testid="loader" 
        />
      ) : (  */}
        <> 
      <Form.Space></Form.Space>
      <Form.Space></Form.Space>
      {message && <Form.Error data-testid="error">{message}</Form.Error>}
      <Form.Heading1>Change Password</Form.Heading1>
      <Form.Input type="password" placeholder="Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} autoComplete="off"/>
      <Form.Input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} autoComplete="off"/>
      <Form.Button onClick={handleChangePassword}>Change Password</Form.Button>
      <Form.DividingLine></Form.DividingLine>

      <Form.Heading1>Forgot Password?</Form.Heading1>
      <Form.Input disabled type="email" placeholder="Email" 
      style={{background: 'rgba(200,200,200,0.75)'}} 
      value={email}/>
      <Form.Button onClick={handleSendResetLink}>Send Reset Link</Form.Button> 
      <Form.DividingLine></Form.DividingLine>

      <Form.Heading1>Change Email Address</Form.Heading1>
      <Form.Input type="password" placeholder="Password" value={emailPassword} onChange={(e) => setEmailPassword(e.target.value)} autoComplete="off"/>
      <Form.Input type="email" placeholder="New Email (and user name)" onChange={(e) => { setNewEmail(e.target.value); }} autoComplete="off" />
      <Form.Input type="email" placeholder="Repeat New Email" onChange={(e) => { setNewEmailRepeat(e.target.value); }} autoComplete="off" />
      <Form.Button onClick={handleChangeEmail} disabled={!isEmailValid}> Change Email Address </Form.Button>
      </>   
      </>
  );
}