import React, { useEffect } from 'react';
import { Form, OptForm } from '../components';
import termsData from '../fixtures/footer_terms_of_use';
import {useHistory} from 'react-router-dom'; 

export default function FooterCookies({}) {

    const history = useHistory();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);
    
    return (
        
          <Form.PaddedContainer>
          <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
          <Form.Title>Use of Cookies</Form.Title>
          <Form.TextLeft>
            This web site uses cookies to enhance the user experience and to function correctly. 
            No data is shared with third parties except for participating labs and doctors as selected by the user.
            By using this website and the services it offers, users agree to the use of these cookies and the sharing of 
            data in order to offer an effective and meaningful service.
          </Form.TextLeft>  
          <Form.Space></Form.Space>
          <Form.Space></Form.Space>
          <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
          </Form.PaddedContainer>
        
      );
}