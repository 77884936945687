import React from 'react';
import { Footer } from '../components';
import * as ROUTES from '../constants/routes';

export function FooterContainer() {
  return (
    <Footer>
      <Footer.Title>Questions? Call us on 073 178 2327, WhatsApp us on 067 231 7355 or email us at customercare@ezpath.co.za</Footer.Title>
      
      <Footer.Break />
      <Footer.Row>
        
        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_FAQS}>FAQs</Footer.Link>
        </Footer.Column>

        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_CONTACT_US}>Contact Us</Footer.Link>
        </Footer.Column>

        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_TERMS_OF_USE}>Terms of Use</Footer.Link>
        </Footer.Column>

        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_COOKIES}>Cookies</Footer.Link>
        </Footer.Column>

        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_PRIVACY}>Privacy and POPIA</Footer.Link>
          {/* <Footer.Link to={ROUTES.FOOTER_CAREERS}>Careers at EZPath</Footer.Link> */}
        </Footer.Column>

        <Footer.Column>
          <Footer.Link to={ROUTES.FOOTER_PAIA}>PAIA</Footer.Link>
        </Footer.Column>

      {/*   <Footer.Column>

        </Footer.Column> */}
      </Footer.Row>
      <Footer.Break />
      <Footer.Text> &copy; 2024 EZPath South Africa</Footer.Text>
    </Footer>
  );
}