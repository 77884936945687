import { getFirestore, collection, where, query, getDocs } from 'firebase/firestore';

// Assuming you've initialized your Firebase app elsewhere
const db = getFirestore();

export default async function getUser(user_id) {
  try {
    const usersRef = collection(db, "users");
    const userQuery = query(usersRef, where("user_id", "==", user_id));
    const snapshot = await getDocs(userQuery);

   const userResult = snapshot.docs.map((resultsObj) => ({
      ...resultsObj.data(),
      docId: resultsObj.id
    }));

    return userResult;
  } catch (error) {
    throw new Error(error.message);  
  }
}