import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default async function getIncompleteOrder(practiceId, testId) {
  try {
    const db = getFirestore();
    const docRef = doc(db, 'incomplete_orders', practiceId);

    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const incompleteTests = docSnap.data().incompleteTests;

      const matchingTest = incompleteTests.find(test => test.id === testId);
      if (matchingTest) {
        return matchingTest;
      } else {
        console.warn('Incomplete test not found with ID:', testId);
        return null; 
      }
    } else {
      console.warn('No incomplete orders found for practice ID:', practiceId);
      return null;
    }
  } catch (error) {
    console.error('Error fetching incomplete test:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
}