import { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';

export default function useOrders(context, id) {
  const [orders, setOrders] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const ordersRef = collection(db, 'order_items');
    let q;
    //let unsubscribe = () => {}; // Initialize an unsubscribe function

    //phlebotomists in the lab setting need access to all tests, look at other ways to limit the view if possible
    if (context === 'phlebotomist') {
    /*  const q = query(ordersRef, 
        where('phlebotomist_id', 'in', ['', '4321']),  // Placeholder: Replace '4321' 
        where('phlebotomist_id', 'in', ['', id]),
         orderBy('ordered_date', 'desc') 
      ); */
    } else if (context === 'practice') {
      q = query(ordersRef, where('linked_practice_id', '==', id), 
        orderBy('ordered_date', 'desc')
      );
    } else {
      q = query(ordersRef, orderBy('ordered_date', 'desc')); 
    }

     // Single subscription point
     const unsubscribe = onSnapshot(q || ordersRef, (snapshot) => {
      const allOrders = snapshot.docs.map((ordersObj) => ({
        ...ordersObj.data(),
        docId: ordersObj.id,
      }));
      setOrders(allOrders);
    });

    return () => unsubscribe(); // Always clean up
  }, [context, id]); 

  return { orders };
}
