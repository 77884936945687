import React, {useEffect, useContext} from 'react';
//import { Feature, OptForm } from '../components';
import { HeaderContainer } from '../containers/header';
import { JumbotronContainer } from '../containers/jumbotron';
import { FaqsContainer } from '../containers/faqs';
import { FooterContainer } from '../containers/footer';
import { UserContext } from '../context/UserContext';

//import SinglePagePDFViewer from "./components/pdf/single-page";
//import AllPagesPDFViewer from "./components/pdf/all-pages";
import { SinglePage, AllPages } from '../components';
/* This is required only if the project file is located 
inside the app. Otherwise you can use the external link of the pdf file*/
import samplePDF from "../sample.pdf";
//import "./styles.css";



export default function ViewReport({}) {

  //const fileRef = ref(storage, `users/${userId}/invoices/invoice123.pdf`); 
  //const fileRef = ref(storage, `users/${userId}/invoices/invoice123.pdf`); 

  const [reportPath, setReportPath] = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    console.log("Report Path: ", reportPath);
  }, [reportPath]);




  return (
    <>
        <div className="ViewReport">
            {/*<h4>Single Page</h4>*/}
            {/* <AllPages pdf={samplePDF} /> */}
            <AllPages pdf={reportPath} />
            <hr />
            {/*<h4>All Pages</h4>
                <div className="all-page-container">
                <AllPages pdf={samplePDF} />
                </div>
            <hr />*/}
        </div>
    </>
  );
}