import { useEffect, useState } from 'react';
import { getFirestore, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';


export default function usePayLaterOrders(context, id) {
  const [payLaterOrders, setPayLaterOrders] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const ordersRef = collection(db, 'order_pay_later_items');
    let q;

    if (context === 'phlebotomist') {
      // Commented out section for phlebotomists
    } else if (context === 'practice') {
      q = query(ordersRef, where('linked_practice_id', '==', id), orderBy('ordered_date', 'desc'));
    } else {
      // No filtering, just order by date
      q = query(ordersRef, orderBy('ordered_date', 'desc')); 
    }

    const unsubscribe = onSnapshot(q || ordersRef, (snapshot) => {
      const allOrders = snapshot.docs.map((ordersObj) => ({
        ...ordersObj.data(),
        docId: ordersObj.id,
        // ... (your currency formatting logic if needed)
      }));
      setPayLaterOrders(allOrders);
    });

    return () => unsubscribe();
  }, [context, id]);

  return { payLaterOrders };
}