import { getFirestore } from "firebase/firestore";
import { collection, getDocs, deleteDoc, doc, setDoc, writeBatch } from 'firebase/firestore'; 

// Assuming you've initialized your Firebase app elsewhere
const firestore = getFirestore();

export default async function writeEditedTests(editedData) {
  try {
    // Delete existing documents
    const querySnapshot = await getDocs(collection(firestore, "tests"));
    querySnapshot.forEach(snapshot => {
      deleteDoc(snapshot.ref);
    });

    // Write editedData to the database
    const batch = writeBatch(firestore);
    editedData.forEach(item => {
      const docRef = doc(firestore, "tests", item.id);
      batch.set(docRef, item, { merge: true });
    });
    await batch.commit();

    alert('Data successfully written to the database.');
  } catch (error) {
    alert('Error writing data to the database: ' + error);
  }
}