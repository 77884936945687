import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Landing, Patient, Practice, Lab, SignIn, SignUp, OrderTests, PatientDetails, LabMemberDetails, PracticeMemberDetails,
        PatientNew, LabMemberNew, PracticeMemberNew, ViewReport, SignInHelp, SelectAvatar,CheckoutNotification, CheckoutCancel, 
        CheckoutReturn, CheckoutPayLater, FooterFAQs, FooterTermsOfUse, FooterPrivacy, FooterPAIA, FooterContactUs,
        EditTests, EditSites, FooterCareers, FooterCookies } from './pages';
import * as ROUTES from './constants/routes';
import { Popup, ProtectedRoute } from './helpers/routes';
import { useAuthListener } from './hooks';
import { UserContext } from "./context/UserContext.js";
import { OrderContext } from "./context/OrderContext.js";
// import { FirebaseContext } from './context/firebase';
import { getFirestore, collection, where, query, getDocs } from 'firebase/firestore';


export function App() {

  // swop this around when deploying live version!!!!
  // this allows us to run test funcitons on the server by setting the database flag testMode
  // our node.JS functions can check for this flag and route to the appropriate function (test/live)
  const developmentMode = false;
  // const developmentMode = false;

  const { user } = useAuthListener();
  // const { firebase }  = useContext(FirebaseContext);
  const db = getFirestore();

  const [userData, setUserData] = useState({});
  const [contextPracticeMembers, setContextPracticeMembers] = useState({});
  const [orderData, setOrderData] = useState({});
  const [reportPath, setReportPath] = useState('');
  const [editProfile, setEditProfile] = useState({});
  const [displayProfile, setDisplayProfile] = useState({});
  const [signupEmail, setSignupEmail] = useState({});
  const [incompleteOrderId, setIncompleteOrderId] = useState({});

  // this is required to reset user data after a successful logout from firebase auth
  useEffect(() => {
    if ((user === null) || (Object.keys(user).length === 0)) {
      setEditProfile({});
      setUserData({});
      setDisplayProfile({});
      setOrderData({});
      setIncompleteOrderId({});
    }
    else try {
      // const db = getFirestore();
      const usersRef = collection(db, "users");
      const userQuery = query(usersRef, where("user_id", "==", user.uid)); 
      
      getDocs(userQuery)
        .then((snapshot) => {
            const userDetails = snapshot.docs.map((resultsObj) => ({
                ...resultsObj.data(),
                docId: resultsObj.id
            }));
            setUserData(userDetails[0]);
        })
        .catch((error) => {
          console.log("useauthlistener error: ", error.message);
        }); 
      }
    catch(error) {
      console.log("user change Useffect error:" ,error.message);
    }
  }, [user]);
 

  return (
    <Router>
      <Switch>
        <Popup path={ROUTES.CHECKOUT_CANCEL}>
          <OrderContext.Provider value={[orderData, setOrderData]}>
            <CheckoutCancel profile = {displayProfile}/>
          </OrderContext.Provider>
        </Popup>
        <Popup path={ROUTES.CHECKOUT_PAY_LATER}>
            <CheckoutPayLater profile = {displayProfile}/>
        </Popup>
        <Popup path={ROUTES.CHECKOUT_RETURN}>
          <OrderContext.Provider value={[orderData, setOrderData]}>
            <CheckoutReturn profile = {displayProfile}/>
          </OrderContext.Provider>
        </Popup>
        <Popup path={ROUTES.CHECKOUT_NOTIFICATION}>
          <CheckoutNotification />
        </Popup>
        <Popup path={ROUTES.SIGN_IN} >
          <UserContext.Provider value={[userData, setUserData, signupEmail, developmentMode]}>
            <SignIn />
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.SIGN_UP}>
          <UserContext.Provider value={[setUserData, signupEmail, developmentMode]}>
            <SignUp />
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.SELECT_AVATAR}>
          <UserContext.Provider value={[editProfile, setEditProfile, developmentMode]}>
            <SelectAvatar profile = {editProfile} />
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.SIGN_IN_HELP}>
          <SignInHelp />
        </Popup>
        <Popup path={ROUTES.FOOTER_FAQS}>
          <FooterFAQs/>
        </Popup>
        <Popup path={ROUTES.FOOTER_PRIVACY}>
          <FooterPrivacy/>
        </Popup>
          <Popup path={ROUTES.FOOTER_PAIA}>
          <FooterPAIA/>
        </Popup>
        <Popup path={ROUTES.FOOTER_CAREERS}>
          <FooterCareers/>
        </Popup>
        <Popup path={ROUTES.FOOTER_CONTACT_US}>
          <FooterContactUs/>
        </Popup>
        <Popup path={ROUTES.FOOTER_TERMS_OF_USE}>
          <FooterTermsOfUse/>
        </Popup>
        <Popup path={ROUTES.FOOTER_COOKIES}>
          <FooterCookies/>
        </Popup>
        <Popup  path={ROUTES.ORDER_TESTS}> 
          <UserContext.Provider value={[contextPracticeMembers, setContextPracticeMembers, incompleteOrderId]}>
            <OrderTests profile = {displayProfile} orderId = {incompleteOrderId}/>
          </UserContext.Provider>
        </Popup>
        <Popup  path={ROUTES.EDIT_TESTS}>
          <UserContext.Provider value={[contextPracticeMembers, setContextPracticeMembers, developmentMode]}>
            <EditTests profile = {displayProfile}/>
          </UserContext.Provider>
        </Popup>
        <Popup  path={ROUTES.EDIT_SITES}>
          <UserContext.Provider value={[contextPracticeMembers, setContextPracticeMembers, developmentMode]}>
            <EditSites profile = {displayProfile}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.PATIENT_DETAILS}>
          <UserContext.Provider value={[editProfile, setEditProfile, developmentMode]}>
            <PatientDetails user = {user}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.LAB_MEMBER_DETAILS}>
          <UserContext.Provider value={[editProfile, setEditProfile, developmentMode]}>
            <LabMemberDetails userData = {userData}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.PRACTICE_MEMBER_DETAILS}>
          <UserContext.Provider value={[editProfile, setEditProfile]}>
            <PracticeMemberDetails userData = {userData}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.PATIENT_NEW}>
          <UserContext.Provider value={[userData, setUserData, editProfile, setEditProfile, developmentMode]}>
            <PatientNew user = {user}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.LAB_MEMBER_NEW}>
          <UserContext.Provider value={[userData, setUserData, editProfile, setEditProfile, developmentMode]}>
            <LabMemberNew user = {user}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.LAB_MEMBER_NEW}>
          <UserContext.Provider value={[userData, setUserData, editProfile, setEditProfile, developmentMode]}>
            <LabMemberNew user = {user}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.PRACTICE_MEMBER_NEW}>
        <UserContext.Provider value={[userData, setUserData, editProfile, setEditProfile, developmentMode]}>
            <PracticeMemberNew user = {user}/>
          </UserContext.Provider>
        </Popup>
        <Popup path={ROUTES.VIEW_REPORT}>
          <UserContext.Provider value={[reportPath, setReportPath, developmentMode]}>
            <ViewReport />
          </UserContext.Provider>
        </Popup>


         <ProtectedRoute user={user} userData={userData} path={ROUTES.HOME}>
          <Landing />
        </ProtectedRoute> 
        <ProtectedRoute user={user} userData={userData} path={ROUTES.PATIENT}>
          <UserContext.Provider value={[reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, setUserData, developmentMode]}>
            <Patient userData = {userData}/>
          </UserContext.Provider>
        </ProtectedRoute> 
        <ProtectedRoute user={user} userData={userData} path={ROUTES.PRACTICE}>
          <UserContext.Provider value={{reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, contextPracticeMembers, setContextPracticeMembers, incompleteOrderId, setIncompleteOrderId}}>
            <Practice userData={userData} />
          </UserContext.Provider>
        </ProtectedRoute>
        <ProtectedRoute user = {user} userData={userData} path={ROUTES.LAB}>
          <UserContext.Provider value={[reportPath, setReportPath, editProfile, setEditProfile, displayProfile, setDisplayProfile, developmentMode]}>
            <Lab userData = {userData}/>
          </UserContext.Provider>
        </ProtectedRoute>
        <ProtectedRoute user = {user} userData = {userData} path={ROUTES.LANDING}>
          <UserContext.Provider value={[signupEmail, setSignupEmail, developmentMode]}>
            <Landing />
          </UserContext.Provider>
        </ProtectedRoute>
      </Switch> 
    </Router>
    );
}