import styled from 'styled-components/macro';
import { Link as ReachRouterLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  display: inline-block;
  accent-color: #cc6600;
  width: 40px;
  height: 20px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const LongContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 10px;
  }
`;

export const Input = styled.input`
  max-width: 450px;
  width: 100%;
  border: 0;
  padding: 10px;
  height: 70px;
  box-sizing: border-box;
`;

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background: #cc6600;
  color: white;
  text-transform: uppercase;
  padding: 0 32px;
  font-size: 26px;
  border: 0;
  cursor: pointer;
  z-index: 500;
  img {
    margin-left: 10px;
    filter: brightness(0) invert(1);
    width: 24px;
    @media (max-width: 1000px) {
      width: 16px;
    }
  }
  &:hover {
    background: #ff9900;
  }
  @media (max-width: 1000px) {
    height: 50px;
    font-size: 16px;
    margin-top: 20px;
    font-weight: bold;
  }
`;

export const Text = styled.p`
  font-size: 19.2px;
  color: white;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const Link = styled(ReachRouterLink)`
font-size: 19.2px;
color: white;
text-align: center;
margin-top: 5px;
margin-bottom: 5px;
text-decoration: none;
&:hover {
  text-decoration: underline;
}
`;

export const LeftText = styled.p`
  font-size: 19.2px;
  color: white;
  text-align: left;
  align: left;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

export const BackButton = styled.button`
  background: #cc6600;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 10px 20px 10px;
  padding: 5px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;

export const ModestButton = styled.button`
  background: #202020;
  border-radius: 4px;
  width: 100px;
  font-size: 16px;
  margin: 10px 1px 1px 1px;
  padding: 2px;
  border: 0;
  color: white;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`;