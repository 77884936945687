import styled from 'styled-components/macro';

export const ConsentContainer = styled.div`
display: flex;
justify-content: center;
height: 100%;
margin-top: 20px;
@media (max-width: 1000px) {
  flex-direction: column;
  align-items: center;
}
`;

export const ConsentPane = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 5%;
    z-index: 1000;
    
    @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    }   
`;
  
export const ConsentButton = styled.button`
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgba(200,100,20,0.50);
    height: fit-content;
    text-align: center;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    color: white;
    border: 0;
    font-size: 15px;
    border-radius: 4px;
    padding: 8px 17px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: rgba(220,140,30,0.90);
    }
    `;

export const ConsentText = styled.p`
  display: flex;
  font-size: 16px;
  color: white;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;