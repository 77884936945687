import { useEffect, useState, useContext } from 'react';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';

export default function useEditSites(target, refresh) {
  const [sites, setSites] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const sitesRef = collection(db, target);
  
    // Create a query that orders by 'site_id' in ascending order
    const orderedQuery = query(sitesRef, orderBy('site_id', 'asc'));
  
    getDocs(orderedQuery) 
      .then((snapshot) => {
        const allSites = snapshot.docs.map((sitesObj) => ({
          ...sitesObj.data(),
          docId: sitesObj.id
        }));
        setSites(allSites);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [refresh]);
  return { [target]: sites }; 
}