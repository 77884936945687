import { firebase } from '../lib/firebase.prod';
import { getFirestore } from "firebase/firestore";

function generateRandomString(length, type) {
  let result = '';
  const characters = {
    letters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    numbers: '0123456789'
  };

  const charactersSet = characters[type];
  for (let i = 0; i < length; i++) {
    result += charactersSet.charAt(Math.floor(Math.random() * charactersSet.length));
  }
  return result;
}

export default function getInvoiceNumber() {
  let invoiceNumber;
  let unique = false;

  invoiceNumber = generateRandomString(3, "letters") + "-" + generateRandomString(3, "numbers") + "-" + generateRandomString(3, "letters");
  /* while (!unique) {
    invoiceNumber = generateRandomString(3, "letters") + "-" + generateRandomString(3, "numbers") + "-" + generateRandomString(3, "letters");

    var doc = firebase.firestore()
      .collection('invoice_number_reference')
      .doc(invoiceNumber)
      .get()

      .then((doc) => {
            if (!doc.exists) {
                firebase.firestore()
                    .collection('invoice_number_reference')
                    .doc(invoiceNumber)
                    .set({ timestamp: new Date() });
                unique = true;
            }
        })
        .catch((error) => {
            console.log("Error getting document:", error);
        }) 
    }*/
    return invoiceNumber;
}
