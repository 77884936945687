import React, { useEffect } from 'react';
import { Accordion, OptForm } from '../components';
import faqsData from '../fixtures/footer_faqs';
import {useHistory} from 'react-router-dom'; 

export default function FooterFAQs({}) {

    const history = useHistory();

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
      }, []);

    return (
        <Accordion>
          <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
          <Accordion.Title>FAQs</Accordion.Title>
          <Accordion.Frame>
            {faqsData.map((item) => (
              <Accordion.Item key={item.id}>
                <Accordion.Header>{item.header}</Accordion.Header>
                <Accordion.Body>{item.body}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion.Frame>
    
          <OptForm.BackButton onClick={() => history.goBack()} >&lt; Back</OptForm.BackButton>
        </Accordion>
      );
}