export default function selectionFilterEditTests({ tests }, orderby) {
  const testsArr = [];
  
  // Create an array of unique values for the orderby field
  const uniqueValuesArr = [...new Set(tests.map(data => data[orderby]))];

  // Sort unique values alphabetically
  uniqueValuesArr.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));

  // Loop through unique values and push matching items to testsArr
  uniqueValuesArr.forEach(value => {
    const filteredItems = tests.filter(item => item[orderby] === value);
    filteredItems.sort((a, b) => a.long_name.localeCompare(b.long_name, 'en', { sensitivity: 'base' }));
    testsArr.push(...filteredItems);
  });

  return testsArr;
}