import React from 'react';
import { PracticeContainer } from '../containers/practice_home';

export default function Practice({userData}) {

  let props = {
    userData: userData
  }

  return <PracticeContainer  props={props}/>;
}
