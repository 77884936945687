import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default function usePracticeDoctors(practice_id) {
  const [practiceDoctors, setPracticeDoctors] = useState([]); 
  const { firebase } = useContext(FirebaseContext);
  const db = getFirestore();

  useEffect(() => {
    if (!practice_id) return; // Early return if practice_id is not valid

    const doctorsRef = collection(db, "users");
    const doctorsQuery = query(
        doctorsRef, 
        where("practice_id", "==", practice_id),
        where("user_role", "==", "doctor")
    );

    getDocs(doctorsQuery)
      .then((snapshot) => {
        const allPracticeDoctors = snapshot.docs.map((practiceDoctorsObj) => ({
          ...practiceDoctorsObj.data(),
          docId: practiceDoctorsObj.id,
        }));

        // Conditionally add 'empty' if needed
        if (allPracticeDoctors.length === 0) { 
          allPracticeDoctors.push("empty"); 
        } 

        setPracticeDoctors(allPracticeDoctors);  
      })
      .catch((error) => {
        console.error(error.message); 
      });
  }, [practice_id]);

  return { practiceDoctors }; 
}

/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';


export default function usePracticeDoctors(practice_id) {
  const [practiceDoctors, setPracticeDoctors] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);

    useEffect(() => {

      if (practice_id === null || practice_id === "" || practice_id === undefined) {return};

      firebase
      .firestore()
      .collection("users")
      .where("practice_id", "==", practice_id)
      .where("user_role", "==", "doctor")
      .get()
      .then((snapshot) => {
        const allPracticeDoctors = snapshot.docs.map((practiceDoctorsObj) => ({
          ...practiceDoctorsObj.data(),
          docId: practiceDoctorsObj.id,
        }));
        
        if (Object.keys(allPracticeDoctors).length === 0) {  //there were no practice members, set the value accordingly
          allPracticeDoctors[0] = "empty";
        }
        setPracticeDoctors(allPracticeDoctors);  
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, [practice_id]);

    return { practiceDoctors };
  //return{};

} */