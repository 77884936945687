import React from 'react';
import { PatientNewContainer } from '../containers/patient_new';



export default function PatientNew({user}) {

  let props = {
    user: user
  }

  return <PatientNewContainer props={props} />;
}

