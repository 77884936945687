import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';
import { getFirestore } from "firebase/firestore";
import { collection, query, where, onSnapshot } from "firebase/firestore";

export default function useDependants(id, change) {
  const [dependants, setDependants] = useState([]);
  const { firebase } = useContext(FirebaseContext);
  const firestore = getFirestore();

  useEffect(() => {
    const usersRef = collection(firestore, "users");
    const dependantQuery = query(usersRef, where("principal_id_1", "==", id));

    //const unsubscribe = onSnapshot(dependantQuery, (snapshot) => {
      const unsubscribe = onSnapshot(dependantQuery, (snapshot) => { 
      const allDependants = snapshot.docs.map(dependantDoc => ({
          ...dependantDoc.data(),
          docId: dependantDoc.id
      }));
      setDependants(allDependants);
    }, (error) => { 
      console.log(error.message);
    });

    return unsubscribe; // Cleanup function is the return 
  }, [firestore, id]); 
  
  /* useEffect(() => {
    // Set up the Firestore query
    const query = firebase.firestore().collection("users").where("principal_id_1", "==", id);

    // Subscribe to real-time changes
    const unsubscribe = query.onSnapshot(snapshot => {
      const allDependants = snapshot.docs.map(dependantDoc => ({
        ...dependantDoc.data(),
        docId: dependantDoc.id,
      }));
      setDependants(allDependants);
    }, error => {
      console.log(error.message);
    });

    // Clean up subscription when component unmounts
    return () => {
      unsubscribe();
    };
  }, [firebase, id]); // Include firebase and id as dependencies
 */
  return { dependants };
}

/* import { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../context/firebase';

export default function useDependants(id) {
  const [dependants, setDependants] = useState([]);  //data gets stored in content variable array, setContent is a function to update the data
  const { firebase } = useContext(FirebaseContext);

    useEffect(() => {
      firebase
      .firestore()
      .collection("users").where("principal_id_1", "==", id)
      .get()
      .then((snapshot) => {
        const allDependants = snapshot.docs.map((dependantsObj) => ({
          ...dependantsObj.data(),
          docId: dependantsObj.id,
        }));
        
        if (Object.keys(allDependants).length === 0) {  //there were no dependants, set the value accordingly
          allDependants[0] = "empty";
        }
        setDependants(allDependants);  //update content state of this component and re-render it, in this case we pass an array we received from the firebase snapshot
      })
      .catch((error) => {
        console.log(error.message);
      });
    }, []);


  return { dependants };

} */